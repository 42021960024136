import { qModel } from './qModels';
import * as moment from 'moment';

export class FourA extends qModel{
    dateOfBirth : moment.Moment = null;
    timeOfBirth : object = {hh: null,mm: null,};
    bornAlive : string = null;
    hospitalId : string = null;
    sex : string = null;
    apgar1 : number = null;
    apgar5 : number = null;
    apgar10 : number = null;
    weight : number = null;
    length : number = null;
    headC : number = null;
    nintervention : string = null;
    resuscitation : boolean[] = [];
    resuscitationMapped : string[] = [];
    s2scontact : string = null;
    delayedCC : string = null;
    breastfed : string = null;
    eyecare : string = null;
    vitK : string = null;
    other : string = null;
    death : string = null;
    transferred : string = null;
    otherNotes : string = null;
    interventions : string = null;
    referral : string = null;
    designation : string = null;
    completedBy : string = null;

    getClassName() : string {
        return "Section4a";
    }

    mapforDB() : any {
        this.resuscitationMapped = this.mapChecksToList(this.list, this.resuscitation).join(',');
        return this.removeNullsAndReturnAnswers(this.propertyMapping);
    }
    
    mapforForm(data : any){
        this.dataToForm(this.propertyMapping, data);
        let resuscitation = this.mapListToChecks(
            this.list,
            (typeof data['answer4a13'] === 'undefined' ? null :data['answer4a13'].split(',')),
        );
        this.resuscitation = resuscitation.checks;
    }

    get list() : any {
        return [
            "None",
            "Stimulation",
            "Oxygen by mask",
            "Suctioning",
            "PPV",
            "Chest compression",
            "Intubation",
            "Drugs",
        ];
    }

    set nni(answer : string) {
        this.nintervention = answer === 'Yes' ? 'Dried' : null;
    }

    get nni(){
        return this.nintervention === 'Dried' ? 'Yes' : 'No';
    }

    get propertyMapping(){
        return {
            "dateOfBirth" : {type: "Date", apply: true, name: "answer4a1"},
            "timeOfBirth" : {type: "Time", apply: false, name: "answer4a2", mapTo:['hh', 'mm']},
            "bornAlive" : {type: "String", apply: true, name: "answer4a3"},
            "hospitalId" : {type: "String", apply: true, name: "answer4a4"},
            "sex" : {type: "String", apply: true, name: "answer4a5"},
            "apgar1" : {type: "String", apply: true, name: "answer4a6"},
            "apgar5" : {type: "String", apply: true, name: "answer4a7"},
            "apgar10" : {type: "String", apply: true, name: "answer4a8"},
            "weight" : {type: "String", apply: true, name: "answer4a9"},
            "length" : {type: "String", apply: true, name: "answer4a10"},
            "headC" : {type: "String", apply: true, name: "answer4a11"},
            "nintervention" : {type: "String", apply: true, name: "answer4a12"},
            "resuscitationMapped" : {type: "Array", apply: false, name: "answer4a13"},
            "s2scontact" : {type: "String", apply: true, name: "answer4a14"},
            "delayedCC" : {type: "String", apply: true, name: "answer4a15"},
            "breastfed" : {type: "String", apply: true, name: "answer4a16"},
            "eyecare" : {type: "String", apply: true, name: "answer4a17"},
            "vitK" : {type: "String", apply: true, name: "answer4a18"},
            "other" : {type: "String", apply: true, name: "answer4a19"},
            "death" : {type: "String", apply: true, name: "answer4a20"},
            "transferred" : {type: "String", apply: true, name: "answer4a21"},
            "otherNotes" : {type: "String", apply: true, name: "answer4a22"},
            "interventions" : {type: "String", apply: true, name: "answer4a23"},
            "referral" : {type: "String", apply: true, name: "answer4a24"},
            "designation" : {type: "String", apply: true, name: "answer4a25"},
            "completedBy" : {type: "String", apply: true, name: "answer4a26"},
        };
    }
}