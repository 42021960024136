import { qModel } from './qModels';

export class FiveA extends qModel{
    complications : boolean[] = [];
    complicationsMapped : string[] = [];
    other : string = null;
    deliver : string = null;
    completedby : string = null;

    getClassName() : string {
        return "Section5a";
    }

    mapforDB() : any {
        this.complicationsMapped = this.mapChecksToList(this.list, this.complications, this.other).join(',');
        return this.removeNullsAndReturnAnswers(this.propertyMapping);
    }
    
    mapforForm(data : any){
        this.dataToForm(this.propertyMapping, data);
        let complications = this.mapListToChecks(
            this.list, 
            (typeof data['answer5a1'] === 'undefined' ? null :data['answer5a1'].split(',')),
            'other'
        );
        this.complications = complications.checks;
        for(let i in complications){
            if(i !== "checks"){
                this[i] = complications[i];
            }
        }
    }

    get list() : any {
        return [
            //"No delivery",
            "No complications",
            "Heavy vaginal bleeding",
            "Uterine tenderness",
            "Hypertension",
            "Difficulty in breathing",
            "Calf swelling/tenderness",
            "Perineal wound/episiotomy infection/breakdown",
            "Breast problems",
            "Offensive vaginal discharge",
            "Fever >38C",
            "Convulsions",
            "Cough",
            "CS wound infection/breakdown",
            "Other",
        ];
    }

    get propertyMapping(){
        return {
            "complicationsMapped" : {type: "Array", apply: false, name: "answer5a1"},
            "deliver" : {type: "String", apply: true, name: "answer5a2"},
            "completedby": {type: "String", apply: true, name: "answer5a3"},
        };
    }
}