import * as moment from "moment";

export class MotherPregnancy{
    mother : number;
    fname : string;
    fcode : string;
    muuid : string;
    hospitalid : string;
    name : string;
    visitdateM : moment.Moment = moment();
    visitdate : string;
    status : boolean;
    uuid : string;
    pregnancy : number;
    enteredby: string;
}