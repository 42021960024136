import { Component, OnInit } from '@angular/core';
import { Globals, BASE_URL } from 'app/globals';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Router } from '@angular/router';

@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    motherList : any = [];
    facilityDetails : any;
    searchBy : string = "name";
    enableSearch : boolean = false;
    searchResults : any = [];
    searchQuery : string = null;
    userName : string;
    notifications : any[];

    private promise_1;
    private promise_2;

    constructor(
        private http: Http, 
        private global : Globals,
        private router : Router
    ) { 
        this.userName = this.global.currentUserName;
        this.global.resetmotherdetails();
    }

    ngOnInit() {
        Notification.requestPermission(approved =>{});
        if(!this.global.isLoggedIn()){
            this.router.navigate(['/login']);
            return;
        }
        this.facilityDetails = this.global.getFacilityDetails();
        this.http.get(BASE_URL+"mother/list").subscribe( response => {
            this.motherList = response;
        }, error => {});
        this.http.get(BASE_URL+"search/"+this.searchBy).subscribe( response => {
            this.searchResults = response;
        });
        this.promise_1 = setInterval(func => {
            this.http.get(BASE_URL+"mother/list").subscribe( response => {
                this.motherList = response;
            }, error => {});
        }, 10000);
        this.http.post(BASE_URL+"notifications", {uid: this.global.getCurrentUser()}).subscribe( response => {
            this.notifications = <any[]>response;
            for(let id in this.notifications){
                try{
                    this.global.showNotifiction(this.notifications[id]);
                }catch(e){
                    console.log(e);
                }
            }
        });
        this.http.post(BASE_URL+"notifications", {uid: this.global.getCurrentUser()}).subscribe( response => {
            this.notifications = <any[]>response;
            for(let id in this.notifications){
                try{
                    this.global.showNotifiction(this.notifications[id]);
                }catch(e){
                    console.log(e);
                }
            }
        });
    }

    changeSearchBy(){
        this.http.get(BASE_URL+"search/"+this.searchBy).subscribe( response => {
            this.searchResults = response;
        });
    }

    search(){
        if(this.searchQuery.length < 1){
            this.http.get(BASE_URL+"search/"+this.searchBy).subscribe( response => {
                this.searchResults = response;
            });
        }
        let url = BASE_URL+"search/"+this.searchBy+"/"+this.searchQuery;
        this.http.get(url).subscribe(response => {
            this.searchResults = response;
        });
    }

    ngOnDestroy() {
        if (this.promise_1) {
            clearInterval(this.promise_1);
        }
    }

    toggleNotification(){
        this.global.toggleNotification();
    }

    get notification_icon() : string {
        return this.global.notificationsAllowed ? 'notifications_active' : 'notifications_off';
    }

    get nAllowed() : boolean{
        return this.global.notificationsAllowed;
    }
}
