import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { ThreeA } from '../models/questions/3a';
import { Validation } from '../validation';

@Component({
  selector: 'app-visit-forms-questions-3a',
  templateUrl: './visit-forms-questions-3a.component.html',
  styleUrls: ['./visit-forms-questions-3a.component.css']
})
export class VisitFormsQuestions3aComponent extends Validation implements OnInit {
  @ViewChild('formThreeA', { read: ElementRef, static: false }) Form;
  threea: ThreeA;
  uids: any;

  constructor (
    private http: Http,
    private global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.threea = new ThreeA();
  }

  ngOnInit() {
    try {
      this.uids = this.global.uidsfq;
      sessionStorage.setItem("threea_uids_mid", JSON.stringify(this.uids.mid));
      sessionStorage.setItem("threea_uids_pid", JSON.stringify(this.uids.pid));
      sessionStorage.setItem("threea_uids_vid", JSON.stringify(this.uids.vid));
      if (!this.global.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
      if (JSON.parse(sessionStorage.getItem("threea_completedby"))==null){
        for (let i = 0; i < this.threea.list.indications.length; i++) {
          this.threea.indications.push(false);
        }
        for (let i = 0; i < this.threea.list.indicationscs.length; i++) {
          this.threea.indicationsCS.push(false);
        }
        for (let i = 0; i < this.threea.list.utreotonics.length; i++) {
          this.threea.utreotonics.push(false);
        }
        for (let i = 0; i < this.threea.list.labour.length; i++) {
          this.threea.labourAnalgesia.push(false);
        }
        for (let i = 0; i < this.threea.list.modeofdelivery.length; i++) {
          this.threea.mod.push(false);
        }
        this.http
          .get(BASE_URL + "mother/pregnancies/visit/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.threea.getClassName())
          .subscribe(response => {
            if (Object.keys(response).length > 0) {
              this.threea.mapforForm(response['answers']);
            } else {
              this.threea.completedby = this.global.currentUserName;
            }
          });
        this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
          .subscribe(response => {
            if (response['concluded']) this.Form.form.disable();
          });
        sessionStorage.setItem("threea_uids_mid", JSON.stringify(this.uids.mid));
        sessionStorage.setItem("threea_uids_pid", JSON.stringify(this.uids.pid));
        sessionStorage.setItem("threea_uids_vid", JSON.stringify(this.uids.vid));
        } else {
          this.uids.mid = JSON.parse(sessionStorage.getItem("threea_uids_mid"))
          this.uids.pid = JSON.parse(sessionStorage.getItem("threea_uids_pid"))
          this.uids.vid = JSON.parse(sessionStorage.getItem("threea_uids_vid"))

          this.threea.motherDeliver1 = JSON.parse(sessionStorage.getItem("threea_motherDeliver1"));
          this.threea.onsetLabour = JSON.parse(sessionStorage.getItem("threea_onsetLabour"));
          this.threea.induction = JSON.parse(sessionStorage.getItem("threea_induction"));
          this.threea.indications = JSON.parse(sessionStorage.getItem("threea_indications"));
          this.threea.labourAnalgesia = JSON.parse(sessionStorage.getItem("threea_labourAnalgesia"));
          this.threea.mod = JSON.parse(sessionStorage.getItem("threea_mod"));
          this.threea.indicationsCS = JSON.parse(sessionStorage.getItem("threea_indicationsCS"));
          this.threea.others_i.omi.value = JSON.parse(sessionStorage.getItem("threea_others_i_omi_value"));
          this.threea.others_i.ofi.value = JSON.parse(sessionStorage.getItem("threea_others_i_ofi_value"));
          this.threea.others_c.pmtct.value = JSON.parse(sessionStorage.getItem("threea_others_c_pmtct_value"));
          this.threea.others_c.omi.value = JSON.parse(sessionStorage.getItem("threea_others_c_omi_value"));
          this.threea.others_c.ofi.value = JSON.parse(sessionStorage.getItem("threea_others_c_ofi_value"));
          this.threea.utreotonics = JSON.parse(sessionStorage.getItem("threea_utreotonics"));
          this.threea.dTime["hh"] = JSON.parse(sessionStorage.getItem("threea_dTime_hh"));
          this.threea.dTime["mm"] = JSON.parse(sessionStorage.getItem("threea_dTime_mm"));
          this.threea.cTime["hh"] = JSON.parse(sessionStorage.getItem("threea_cTime_hh"));
          this.threea.cTime["mm"] = JSON.parse(sessionStorage.getItem("threea_cTime_mm"));
          this.threea.others_d = JSON.parse(sessionStorage.getItem("threea_others_d"));
          this.threea.bishopScore = JSON.parse(sessionStorage.getItem("threea_bishopScore"));
          this.threea.plannedVBAC = JSON.parse(sessionStorage.getItem("threea_plannedVBAC"));
          this.threea.augOxy = JSON.parse(sessionStorage.getItem("threea_augOxy"));
          this.threea.armDone = JSON.parse(sessionStorage.getItem("threea_armDone"));
          this.threea.aFHR1 = JSON.parse(sessionStorage.getItem("threea_aFHR1"));
          this.threea.aCTG = JSON.parse(sessionStorage.getItem("threea_aCTG"));
          this.threea.motherDeliver2 = JSON.parse(sessionStorage.getItem("threea_motherDeliver2"));
          this.threea.aFHR2 = JSON.parse(sessionStorage.getItem("threea_aFHR2"));
          this.threea.meconium = JSON.parse(sessionStorage.getItem("threea_meconium"));
          this.threea.offensiveLiquor = JSON.parse(sessionStorage.getItem("threea_offensiveLiquor"));
          this.threea.duration2Stage = JSON.parse(sessionStorage.getItem("threea_duration2Stage"));
          this.threea.notes = JSON.parse(sessionStorage.getItem("threea_notes"));
          this.threea.bloodLoss = JSON.parse(sessionStorage.getItem("threea_bloodLoss"));
          this.threea.others_u = JSON.parse(sessionStorage.getItem("threea_others_u"));
          this.threea.placentaComplete = JSON.parse(sessionStorage.getItem("threea_placentaComplete"));
          this.threea.placentaRemoval = JSON.parse(sessionStorage.getItem("threea_placentaRemoval"));
          this.threea.duration3Stage = JSON.parse(sessionStorage.getItem("threea_duration3Stage"));
          this.threea.episiotomy = JSON.parse(sessionStorage.getItem("threea_episiotomy"));
          this.threea.perinealTear = JSON.parse(sessionStorage.getItem("threea_perinealTear"));
          this.threea.grade = JSON.parse(sessionStorage.getItem("threea_grade"));
          this.threea.repaired = JSON.parse(sessionStorage.getItem("threea_repaired"));
          this.threea.siteOfRepair = JSON.parse(sessionStorage.getItem("threea_siteOfRepair"));
          this.threea.repairedBy = JSON.parse(sessionStorage.getItem("threea_repairedBy"));
          this.threea.completedby = JSON.parse(sessionStorage.getItem("threea_completedby"));
        }
      } catch (ex) {
        console.log(ex);
      }


    }

  clickCB(id: number, type: string) {
      this.threea[type][id] = !this.threea[type][id];
    }

    onSubmit() {
      let fd = this.threea.mapforDB();
      let data = {
        mid: this.uids.mid,
        pid: this.uids.pid,
        vid: this.uids.vid,
        question: this.threea.getClassName(),
        enteredby: this.global.getCurrentUser(),
        answers: fd,
      };
      let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
      this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe(response => {
        this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
        this.global.toastToSave();
      });
    }

    iterator(end: number, start: number = 0, increment = 1): number[] {
      return this.global.iterateNumbers(end, start, increment);
    }

    // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
    //   if(model === null || model.length < 1){
    //     options.form.controls[options.name].setErrors(null);
    //     return;
    //   }
    //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
    //   if(validation === true){
    //     options.form.controls[options.name].setErrors(null);
    //   }else if(validation === false){
    //     options.form.controls[options.name].setErrors({'incorrect' : true});
    //   }
    // }

    ngDoCheck() {
      try {

        sessionStorage.setItem("threea_motherDeliver1", JSON.stringify(this.threea.motherDeliver1));
        sessionStorage.setItem("threea_onsetLabour", JSON.stringify(this.threea.onsetLabour));
        sessionStorage.setItem("threea_induction", JSON.stringify(this.threea.induction));
        sessionStorage.setItem("threea_indications", JSON.stringify(this.threea.indications));
        sessionStorage.setItem("threea_labourAnalgesia", JSON.stringify(this.threea.labourAnalgesia));
        sessionStorage.setItem("threea_mod", JSON.stringify(this.threea.mod));
        sessionStorage.setItem("threea_indicationsCS", JSON.stringify(this.threea.indicationsCS));
        sessionStorage.setItem("threea_others_i_omi_value", JSON.stringify(this.threea.others_i.omi.value));
        sessionStorage.setItem("threea_others_i_ofi_value", JSON.stringify(this.threea.others_i.ofi.value));
        sessionStorage.setItem("threea_others_c_pmtct_value", JSON.stringify(this.threea.others_c.pmtct.value));
        sessionStorage.setItem("threea_others_c_omi_value", JSON.stringify(this.threea.others_c.omi.value));
        sessionStorage.setItem("threea_others_c_ofi_value", JSON.stringify(this.threea.others_c.ofi.value));
        sessionStorage.setItem("threea_utreotonics", JSON.stringify(this.threea.utreotonics));
        sessionStorage.setItem("threea_dTime_hh", JSON.stringify(this.threea.dTime["hh"]));
        sessionStorage.setItem("threea_dTime_mm", JSON.stringify(this.threea.dTime["mm"]));
        sessionStorage.setItem("threea_cTime_hh", JSON.stringify(this.threea.cTime["hh"]));
        sessionStorage.setItem("threea_cTime_mm", JSON.stringify(this.threea.cTime["mm"]));
        sessionStorage.setItem("threea_others_d", JSON.stringify(this.threea.others_d));
        sessionStorage.setItem("threea_bishopScore", JSON.stringify(this.threea.bishopScore));
        sessionStorage.setItem("threea_plannedVBAC", JSON.stringify(this.threea.plannedVBAC));
        sessionStorage.setItem("threea_augOxy", JSON.stringify(this.threea.augOxy));
        sessionStorage.setItem("threea_armDone", JSON.stringify(this.threea.armDone));
        sessionStorage.setItem("threea_aFHR1", JSON.stringify(this.threea.aFHR1));
        sessionStorage.setItem("threea_aCTG", JSON.stringify(this.threea.aCTG));
        sessionStorage.setItem("threea_motherDeliver2", JSON.stringify(this.threea.motherDeliver2));
        sessionStorage.setItem("threea_aFHR2", JSON.stringify(this.threea.aFHR2));
        sessionStorage.setItem("threea_meconium", JSON.stringify(this.threea.meconium));
        sessionStorage.setItem("threea_offensiveLiquor", JSON.stringify(this.threea.offensiveLiquor));
        sessionStorage.setItem("threea_duration2Stage", JSON.stringify(this.threea.duration2Stage));
        sessionStorage.setItem("threea_notes", JSON.stringify(this.threea.notes));
        sessionStorage.setItem("threea_bloodLoss", JSON.stringify(this.threea.bloodLoss));
        sessionStorage.setItem("threea_others_u", JSON.stringify(this.threea.others_u));
        sessionStorage.setItem("threea_placentaComplete", JSON.stringify(this.threea.placentaComplete));
        sessionStorage.setItem("threea_placentaRemoval", JSON.stringify(this.threea.placentaRemoval));
        sessionStorage.setItem("threea_duration3Stage", JSON.stringify(this.threea.duration3Stage));
        sessionStorage.setItem("threea_episiotomy", JSON.stringify(this.threea.episiotomy));
        sessionStorage.setItem("threea_perinealTear", JSON.stringify(this.threea.perinealTear));
        sessionStorage.setItem("threea_grade", JSON.stringify(this.threea.grade));
        sessionStorage.setItem("threea_repaired", JSON.stringify(this.threea.repaired));
        sessionStorage.setItem("threea_siteOfRepair", JSON.stringify(this.threea.siteOfRepair));
        sessionStorage.setItem("threea_repairedBy", JSON.stringify(this.threea.repairedBy));
        sessionStorage.setItem("threea_completedby", JSON.stringify(this.threea.completedby));
      } catch (ex) {
        console.log(ex);
      }
    }

    clearDependents(answer: string) {
      switch (answer) {
        case "3a21":
          if (this.threea.episiotomy === "No" && this.threea.perinealTear === "No") {
            this.threea.grade = null;
            this.threea.repaired = null;
            this.threea.siteOfRepair = null;
            this.threea.repairedBy = null;
          }
          break;
        case "3a22":
          if (this.threea.episiotomy === "No" && this.threea.perinealTear === "No") {
            this.threea.grade = null;
            this.threea.repaired = null;
          }
          break;
        case "3a24":
          if (this.threea.repaired === "No") {
            this.threea.siteOfRepair = null;
            this.threea.repairedBy = null;
          }
          break;
      }
    }
  }
