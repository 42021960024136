import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ReportDialogComponent } from './report-dialog/report-dialog.component';
import { Injectable } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';

export const baseUrl = environment.production ? '' : "//cemoncbemonc.local";

@Injectable()
export class GlobalFunctions{
  constructor(
    private matDialog : MatDialog,
    private http : Http,
    private router : Router,
    public snackBar: MatSnackBar,
  ){}

  chartNavigate( type : string, graph : string, portion : string | object, params : any){
    let url = [
      baseUrl,
      "report-v2",
      "individual",
      type,
    ].join("/");
    this.http.post(url, {
      graph: graph,
      portion: portion,
      parameters : params,
    }).subscribe( response => {
      let dialog = this.matDialog.open(ReportDialogComponent,{
        data: response,
        height: '95vh',
      });
    }, error => {
      if(error.status === 403){
        this.router.navigateByUrl('/report-login').then( () => {
          this.snackBar.open("Logged out due to timeout.", "", {duration: 5000});
        });
      }
    });
  }

  sum(x, y){
    let z = 0;
    if((typeof x).toLowerCase() === "object"){
      z += x.value;
    }else{
      z += x;
    }
    if((typeof y).toLowerCase() === "object"){
      z += y.value;
    }else{
      z += y;
    }
    return z;
  }

  tableCellClick(type, graph, data, row, col, params){
    if(col == 0) return;
    let colN : string = data.columns[col-1];
    let colType : string = data.data[row][0];
    colN = colN.replace(/[^\w]/ig, '_').toLowerCase();
    colType = colType.replace(/[^\w]/ig, '_').toLowerCase();
    let portion = {
      col: colN,
      row: colType,
    };
    this.chartNavigate(type, graph, portion, params);
  }

  public httpError(){
    this.router.navigateByUrl('/report-login').then( () => {
      this.snackBar.open("Logged out due to timeout.", "", {duration: 5000});
    });
  }
}