import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Globals, BASE_URL } from 'app/globals';
import { HttpHeadered as Http } from '../services/httpHeadered';

@Component({
  selector: 'app-baby-mr-dialog',
  templateUrl: './baby-mr-dialog.component.html',
  styleUrls: ['./baby-mr-dialog.component.css']
})
export class BabyMrDialogComponent implements OnInit {
  @ViewChild('mrId', {read: ElementRef, static: false}) Form;
  public quering = false;
  public mr = null;
  private mid = null;
  private pid = null;
  private vid = null;
  private bid = null;

  constructor(
    public dialogRef: MatDialogRef<BabyMrDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private global : Globals,
    private http : Http,
  ) {
    this.mid = data.mid;
    this.pid = data.pid;
    this.vid = data.vid;
    this.bid = data.bid;
  }

  ngOnInit() {
  }

  submit(){
    this.quering = true;
    let postData = {
      mid: this.mid,
      pid: this.pid,
      vid: this.vid,
      bid: this.bid,
      mr: this.mr,
    };
    this.http.post(BASE_URL+"baby/mrid", postData).subscribe(resp => {
      let data = resp;
      if(data['success']){
        this.dialogRef.close({
          saved: true,
        });
      }else{
        this.dialogRef.close({
          saved: false,
        });
      }
    });
  }

}
