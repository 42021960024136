import { Component, OnInit } from '@angular/core';
import { CreateAccountLoginComponent } from 'app/create-account-login/create-account-login.component';
import { HttpClient as Http, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { Globals, BASE_URL } from 'app/globals';
import { VERSION as APPVERSION } from 'environments/environment';
import { VERSION as NGVERSION } from "@angular/common"

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  progressHide = true;
  email : string;
  password : string;
  hasConnection: boolean;
  NgVersion = NGVERSION.full;
  AppVersion = APPVERSION;
  
  constructor(
    private http: Http, 
    private dialog: MatDialog, 
    private global : Globals,
    private router : Router,
    private snackBar: MatSnackBar
  ) {
    this.global.setAdminLogin(false);
  }
  ngOnInit() {
    if(this.global.isLoggedIn()){
      this.router.navigate(['/dashboard']);
    }
  }

  onSubmit(): void {
    this.progressHide = false;
    this.http.post(BASE_URL + "login", {
      email: this.email,
      password: this.password
    }).subscribe(response => {
      let resp = response;
      if(resp['success']){
        this.global.privilage = <string>resp['privilage'];
        this.global.token = resp['key'];
        this.http.get(BASE_URL+'get-details', {
          headers: new HttpHeaders({
            'authorization': resp['key'],
          })
        }).subscribe(data => {
          this.progressHide = true;
          this.global.setCurrentUser(data['uuid'], data['name']);
          this.global.setFacilityDetails(data['facility_details']);
          this.router.navigateByUrl('/dashboard').then(()=> {
            this.snackBar.open('Logged In.', '',{
              duration: 1000,
            });
          });
        }, error => {
          this.progressHide = true;
          console.log(error);
          this.snackBar.open("Error", '', {duration: 2000});
        });
      }else{
        this.snackBar.open('Login Failed. '+resp['message'], '',{
          duration: 1000,
        });
      }
    }, error => {
      this.progressHide = true;
      console.log(error);
      this.snackBar.open("Error", '', {duration: 2000});
    });
  }

  createAccount(): void {
    let dialogRef = this.dialog.open(CreateAccountLoginComponent, {
      data: {success: false}
    });
    dialogRef.afterClosed().subscribe(data => {
      if(typeof data === "undefined") return;
      if(data.success){
        this.global.setAdminLogin(true);
        this.router.navigate(['/create-account/main']);
      }else{
        this.snackBar.open("Invalid Admin Credentials", "",{
          duration: 3000
        });
      }
    })
  }
}