import { Component, OnInit } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './visit-forms.component.html',
  styleUrls: ['./visit-forms.component.css']
})
export class VisitFormsComponent implements OnInit {

  uids : any = {mid : null, pid: null, vid: null};

  constructor(
    private http: Http,
    public global : Globals,
    private router : Router,
    private aRoute : ActivatedRoute,
  ) { }

  ngOnInit() {
    this.aRoute.params.subscribe(params => {
      let uid = params.id;
      let ids = uid.split(":");
      this.uids.mid = ids[0];
      this.uids.pid = ids[1];
      this.uids.vid = ids[2];
      this.global.setUIDsForQuestions(this.uids);
    });
    if(!this.global.isLoggedIn()){
      this.router.navigate(['/login']);
    }
  }

}
