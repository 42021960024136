import { Component, OnInit } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { BabyMrDialogComponent } from '../baby-mr-dialog/baby-mr-dialog.component';

@Component({
  selector: 'app-visit-forms-questions-6b-intermediate',
  templateUrl: './visit-forms-questions-6b-intermediate.component.html',
  styleUrls: ['./visit-forms-questions-6b-intermediate.component.css']
})
export class VisitFormsQuestions6bIntermediateComponent implements OnInit {
  
  uids : any;
  babies : any;

  constructor(
    private http : Http,
    private global : Globals,
    private router : Router,
    private dialog : MatDialog,
    private snackbar : MatSnackBar
  ) { 
    this.uids = global.uidsfq;
  }

  ngOnInit() {
    if(!this.global.isLoggedIn()){
      this.router.navigate(['/login']);
    }
    let url = BASE_URL + 'baby/fetch/'+ this.uids.mid + "/" + this.uids.pid + '/' + this.uids.vid;
    this.http.get(url).subscribe( response => {
      this.babies = response;
    })
  }

  babyMrIdCheck(bid : number, hasMr : boolean){
    if(hasMr){
      this.router.navigate(['/mother/pregnancy/visit/outcome/baby', bid])
    }else{
      let dialog = this.dialog.open(BabyMrDialogComponent, {
        data : {
          bid: bid,
          mid: this.uids.mid,
          pid: this.uids.pid,
          vid: this.uids.vid,
        },
      });
      dialog.afterClosed().subscribe(result => {
        if(typeof result === "undefined") return;
        if(result.saved){
          this.snackbar.open("MR Number saved.", "", {
            duration: 3000,
          });
          this.router.navigate(['/mother/pregnancy/visit/outcome/baby', bid]);
        }else{
          this.snackbar.open("MR Number not saved. Contact admin.", "", {
            duration: 3000,
          });
        }
      });
    }
  }
}
