import { qModel } from './qModels';
import * as moment from "moment";

export class TwoA extends qModel{
    dateOfAdmission : moment.Moment = null;
    timeOfAdmission : any = {hh : null, mm: null};
    timeToTravel : any = {hh : null, mm: null};
    firstAdmission : string = null;
    reffered : boolean = null;
    LMPUnknown : boolean = null;
    LMP : moment.Moment = null;
    EDDbyDate : string = null;
    EDDbyUS : Date = null;
    EDDUnknown : boolean = null;
    gestation : any = {weeks : null, days: null};
    presentingComplaints : boolean[] = [];
    mappedPresentingComplaints : String[] = [];
    notes : string = null;
    others : string = null;
    completedby : string = null;
    admittingdoc : string = null;

    getClassName() : string {
        return "Section2a";
    }

    mapforDB() : any {
        this.mappedPresentingComplaints = this.mapChecksToList(this.list, this.presentingComplaints, this.others).join(",");
        return this.removeNullsAndReturnAnswers(this.propertyMapping);
    }
    
    mapforForm(data : any){
        this.dataToForm(this.propertyMapping, data);
        let returns = this.mapListToChecks(this.list, data['answer2a10'].split(','), 'others');
        if(data['answer2a6'] === 'Unknown'){
            this.LMPUnknown = true; 
        }
        if(data['answer2a8'] === 'Unknown'){
            this.EDDUnknown = true;
        }
        this.presentingComplaints = returns.checks;
        for(let i in returns){
            if(i !== "checks"){
                this[i] = returns[i];
            }
        }
    }

    get list() : any {
        return [
            'None',
            'Abdominal pain labour',
            'Drainage of Liquor',
            'Headache',
            'Convulsions',
            'Nausea and/or vomiting',
            'Fever',
            'Decreased/Absent fetal movements',
            'Difficulty in breathing',
            'Abdominal pain other',
            'Vaginal bleeding',
            'Visual disturbances',
            'Loss of consciousness',
            'Offensive vaginal discharge',
            'Dysuria',
            'Trauma/ fall / violence',
            'Cough',
            'Admitted for elective C-section',
            'Admitted for elective induction of labour',
            'Other specify',
        ];
        
    }

    get propertyMapping(){
        return {
            "dateOfAdmission": {type: "Date", apply: true, name: "answer2a1"},
            "timeOfAdmission": {type: "Time", apply: false, name: "answer2a2", mapTo:["hh", "mm"]},
            "timeToTravel": {type: "Time", apply: false, name: "answer2a3", mapTo:["hh", "mm"]},
            "firstAdmission": {type: "String", apply: true, name: "answer2a4"},
            "reffered": {type: "String", apply: true, name: "answer2a5"},
            "LMP": {type: "Date", apply: true, name: "answer2a6"},
            "LMPUnknown": {type: "Mapped", apply: true, name: null, value:'Unknown', mapTo :["LMP"]},
            "EDDbyDate": {type: "String", apply: true, name: "answer2a7"},
            "EDDbyUS": {type: "Date", apply: false, name: "answer2a8"},
            "EDDUnknown": {type: "Mapped", apply: true, name: null, value:'Unknown', mapTo :["EDDbyUS"]},
            "gestation": {type: "Object", apply: false, name: "answer2a9", mapTo:["weeks", "days"]},
            "mappedPresentingComplaints": {type: "Array", apply: false, name: "answer2a10"},
            "notes": {type: "String", apply: true, name: "answer2a11"},
            "completedby": {type: "String", apply: true, name: "answer2a12"},
            "admittingdoc": {type: "String", apply: true, name: "answer2a13"},
        };
    }
}