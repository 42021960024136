import { Component, OnInit } from '@angular/core';
import { HttpClient as Http } from '@angular/common/http';
import { Router } from '@angular/router';
import { Globals, BASE_URL } from 'app/globals';

@Component({
  selector: 'app-report-main',
  templateUrl: './report-main.component.html',
  styleUrls: ['./report-main.component.css']
})
export class ReportMainComponent implements OnInit {

  public from = { m: null, y: null};
  public to = { m: null, y: null};
  public type : string = "Bar";
  public flist : string[] = [];
  public facility = null;

  constructor(
    private http : Http,
    public global : Globals,
  ) {}

  ngOnInit() {
    this.http.get(BASE_URL+"report/list/facilities").subscribe( resp => {
      this.flist = <any>resp;
    });
  }

  get fcode(){
    for(let x of this.flist){
      if(x['name'] === this.facility){
        return x['code'];
      }
    }
  }

  iterator(end : number, start : number = 0, increment = 1 ) : number[]{
    return this.global.iterateNumbers(end, start, increment);
  }
}
