import { qModel } from './qModels';

export class FourC extends qModel{
    birthId : string = null;
    skinBroken : string = null;
    anyAnomalies : string = null;
    anomalies : boolean[] = [];
    anomaliesMapped : string[] = [];
    notes : string = null;

    getClassName() : string {
        return "Section4c";
    }

    mapforDB() : any {
        this.anomaliesMapped = this.mapChecksToList(this.list, this.anomalies).join(',');
        return this.removeNullsAndReturnAnswers(this.propertyMapping);
    }
    
    mapforForm(data : any){
        this.dataToForm(this.propertyMapping, data);
        let anomalies = this.mapListToChecks(
            this.list, 
            (typeof data['answer4c4'] === 'undefined' ? null :data['answer4c4'].split(',')),
        );
        this.anomalies = anomalies.checks;
    }

    get list() : any {
        return [
            "Head",
            "Eyes",
            "Chest",
            "Abdomen",
            "Genitalia",
            "Spine",
            "Limbs",
            "Hands",
            "Feet",
        ];
    }

    get propertyMapping(){
        return {
            "birthId" : {type: "String", apply: true, name:"answer4c1"},
            "skinBroken" : {type: "String", apply: true, name:"answer4c2"},
            "anyAnomalies" : {type: "String", apply: true, name:"answer4c3"},
            "anomaliesMapped" : {type: "Array", apply: false, name:"answer4c4"},
            "notes" : {type: "String", apply: true, name:"answer4c5"},
        };
    }
}