import { Component, OnInit } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Validation } from '../validation';
import { Router } from '@angular/router';

@Component({
  selector: 'app-visit-forms-question4a-intermediate',
  templateUrl: './visit-forms-question4a-intermediate.component.html',
  styleUrls: ['./visit-forms-question4a-intermediate.component.css']
})
export class VisitFormsQuestion4aIntermediateComponent extends Validation implements OnInit {

  uids : any;
  babies : any = [];

  constructor(
    private http : Http,
    private global : Globals,
    private router : Router,
  ) { 
    super();
    this.uids = global.uidsfq;
  }

  ngOnInit() {
    if(!this.global.isLoggedIn()){
      this.router.navigate(['/login']);
    }
    let url = BASE_URL + 'baby/fetch/'+ this.uids.mid + "/" + this.uids.pid + '/' + this.uids.vid;
    this.http.get(url).subscribe( response => {
      this.babies = response;
    })
  }

}
