import { Injectable } from "@angular/core";
import { HttpClient as Http, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material';
import { environment } from "environments/environment";
import { ActivatedRoute } from "@angular/router";

export const BASE_URL = "http://127.0.0.1:8082/";
// export const BASE_URL = environment.production ?  "/" : "//cemoncbemonc.local/";
@Injectable()
export class Globals {
    private adminLogin : boolean;
    public moment = moment;
    public notificationPromise;
    private _allowNotifcation : boolean = true;
    private ss = window.sessionStorage;
    private ls = window.localStorage;

  constructor(
    private http: Http,
    private dialogBox : MatDialog,
    private snackbar : MatSnackBar,
    private router : Router,
    private aroute : ActivatedRoute
  ) {
    this.adminLogin = false;
  }

  setAdminLogin( answer : boolean ){
    this.adminLogin = answer;
  }

  getAdminLogin() : boolean{
    return this.adminLogin;
  }

  setCurrentUser(uuid : string, name: string){
    this.ss.setItem("currentuser", uuid);
    this.ss.setItem("currentusername", name);
  }

  getCurrentUser() : string{
    return this.ss.getItem('currentuser');
  }

  get currentUserName() : string{
    return this.ss.getItem('currentusername');
  }

  isLoggedIn() : boolean{
    return typeof this.ss.getItem('currentuser') !== "undefined" && this.ss.getItem('currentuser') !== null;
  }

  setFacilityDetails(data : any){
    let facilityDetails = {
      countryname  : data.countryname,
      countrycode  : data.countrycode,
      facilityname : data.facilityname,
      facilitycode : data.facilitycode,
    };
    this.ss.setItem("facilityDetails", JSON.stringify(facilityDetails));
  }

  getFacilityDetails() : any{
    return JSON.parse(this.ss.getItem("facilityDetails"));
  }

  setUIDsForQuestions(uids : {mid : String, pid: String, vid: String}){
    this.ss.setItem("uidsfq", JSON.stringify(uids));
  }

  set motherdetails (details : object){
    this.ss.setItem('fqmname', details['name']);
    this.ss.setItem('fqmhid', details['hid']);
  }

  get motherdetails (){
    return {
      name: this.ss.getItem('fqmname'),
      hid: this.ss.getItem('fqmhid'),
    };
  }

  resetmotherdetails(){
    this.ss.removeItem('fqmname');
    this.ss.removeItem('fqmhid');
  }

  get uidsfq() : any {
    let x : string = this.ss.getItem("uidsfq");
    return JSON.parse(x);
  }

  clearUIDsForQuestion(){
    this.ss.removeItem("uidsfq");
  }

  clearLogin(){
    this.ss.removeItem('currentuser');
    this.ss.removeItem('currentusername');
    this.ss.removeItem("facilityDetails");
    clearInterval(this.notificationPromise);
    this._allowNotifcation = true;
  }

  iterateNumbers(end : number, start : number = 0, iterator = 1) : number[]{
    let num = [];
    for(let i = start; i < end; i = i + iterator){
      num.push(i);
    }
    return num;
  }

  toastToSave(){
    this.snackbar.open('Data Saved. Moving to next form.', '', {
      duration: 2000,
    })
  }

  public visitConcluded(mid : number, pid: number, vid : number) : any{
    return this.http.get(BASE_URL+"mother/pregnancies/visit/concluded/" + [mid, pid, vid].join('/'), {
      headers: new HttpHeaders({
        'authorization': this.token,
      })
    });
  }

  keys(param : any){
    if(param === null) return [];
    return Object.keys(param);
  }

  getNextForm(router : any){
    let seg = router.url.split('/');
    let last = seg[seg.length-1];
    let list : string[] = [
      "two-a",
      "two-b",
      "two-c",
      "two-d",
      "two-e",
      "two-f",
      "two-g",
      "two-h",
      "three-a",
      "three-b",
      "four-a",
      "five-a",
      "five-b",
      "six-a",
      "six-b"
    ];
    let loc : number = list.indexOf(last);
    if(loc < 0){
      return "";
    }else{
      loc++;
      if(loc >= list.length){
        return "";
      }else{
        return list[loc];
      }
    }
    //return list[page];
  }

  set token(data : string){
    this.ss.setItem('apiToken', data);
  }

  get token(){
    return this.ss.getItem('apiToken');
  }

  set privilage(data : string){
    this.ss.setItem('report-privilage', data);
  }

  get privilage(){
    return this.ss.getItem('report-privilage');
  }

  get AuthHeaders() : HttpHeaders{
    let h = new HttpHeaders();
    h.append('Authorization', this.token);
    return h;
  }

  showNotifiction(notify){
    if(!this.isLoggedIn()) return;
    if(!this._allowNotifcation) return;
    let n = new Notification('Missing Details', {
        body: 'You have missed a field an a form. Go to your task list or click this notification to know more.',
        icon: '/favicon.ico',
    });
    n.onclick = x => {
        let z = notify.message.id;
        let nid = notify.id;
        this.http.post(BASE_URL+"notifications-read", {uid: this.getCurrentUser(), nid: nid}, {headers: this.AuthHeaders}).subscribe();
        this.router.navigateByUrl('/task-list');
    }
    setTimeout(x => {
        n.close();
    }, 7000);
  }

  get notificationsAllowed() : boolean {
    return this._allowNotifcation;
  }

  toggleNotification(){
    this._allowNotifcation = !this._allowNotifcation;
  }

  resetStorage(){
    let currentuser = sessionStorage.getItem("currentuser");
    let currentusername = sessionStorage.getItem("currentusername");
    let facilityDetails = sessionStorage.getItem("facilityDetails");
    let apiToken = sessionStorage.getItem("apiToken");
    let report_privilage = sessionStorage.getItem("report-privilage");
    sessionStorage.clear();
    sessionStorage.setItem("currentuser", currentuser);
    sessionStorage.setItem("currentusername", currentusername);
    sessionStorage.setItem("facilityDetails", facilityDetails);
    sessionStorage.setItem("apiToken", apiToken);
    sessionStorage.setItem("report-privilage", report_privilage);
  }
}