import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Globals, BASE_URL } from 'app/globals';
import { HttpHeadered as Http, HttpHeadered } from 'app/services/httpHeadered';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.css']
})
export class ReportDialogComponent implements OnInit {
  columns : string[];
  rows : any[];
  privilage = null;
  list : any = {};
  section = '';
  portion = '';
  leftfield = '';

  constructor(
    public dialogRef: MatDialogRef<ReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private global : Globals,
    private snackBar : MatSnackBar,
    private http : HttpHeadered,
  ) {
    this.privilage = global.privilage;
  }

  ngOnInit() {
    this.columns = this.data.columns;
    this.rows = this.data.rows;
    this.section = this.data.section;
    this.portion = this.data.portion;
    this.leftfield = this.data.leftfield;
    for(let x of this.rows){
      if(typeof x['span'] !== 'undefined'){
        continue;
      }
      if(typeof this.list[x.enteredby] === 'undefined'){
        this.list[x.enteredby] = [];
      }
      if(x.enteredby === null){
        this.list['uiu'] = [];
      }
      this.list[x.enteredby === null ? 'uiu' : x.enteredby].push({mid: x.id, pid: x.pregnancy, vid: x.visit, section: this.section});
    }
  }

  close(){
    this.dialogRef.close();
  }

  hasKey(needle, haystack){
    return ((Object.keys(haystack)).indexOf(needle)) > -1;
  }

  getKeyCount(item){
    return (Object.keys(item)).length;
  }

  notify(user = null, facility = null, id : null | {mid: null, pid: null, vid: null, section: null} = null){
    let sendable = {
      sendto : {
        user: null,
        facility: null,
      },
      id : null,
      leftfield: this.leftfield,
    };
    if(user === null && facility === null){
      this.snackBar.open('Problem with data. Cannot notify.', '', {duration: 2000});
      return;
    }/* else if(user === null && facility !== null){
      this.snackBar.open('Legacy Data. Sending notification(s) to all users.', '', {duration: 2000});
    }else if(user !== null){
      this.snackBar.open('Sending notification(s) to the user.', '', {duration: 2000});
    } */
    sendable.sendto.user = user;
    sendable.sendto.facility = facility;
    if(id === null){
      if(user === null){
        user = 'uiu';
      }
      sendable.id = this.list[user];
    }else{
      sendable.id = [id];
    }
    this.http.post(BASE_URL+'notify', sendable).subscribe( response =>{
      if(response['success']){
        this.snackBar.open('Notifcations sent.', '', {duration: 5000});
      }
    });
  }
}
