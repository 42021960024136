import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { TwoH } from '../models/questions/2h';
import { Validation } from '../validation';

@Component({
  selector: 'app-visit-forms-questions-2h',
  templateUrl: './visit-forms-questions-2h.component.html',
  styleUrls: ['./visit-forms-questions-2h.component.css']
})
export class VisitFormsQuestions2hComponent extends Validation implements OnInit {
  @ViewChild('formTwoH', { read: ElementRef, static: false }) Form;
  twoh: TwoH;
  uids: any;
  disabler: boolean;
  math = Math;

  constructor (
    private http: Http,
    private global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.twoh = new TwoH();
    this.uids = this.global.uidsfq;
  }

  ngOnInit() {
    try {
      if (!this.global.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
      if ((JSON.parse(sessionStorage.getItem("twoh_noInvestigations")) === null
        && JSON.parse(sessionStorage.getItem("twoh_urineDipstick")) == null
        && JSON.parse(sessionStorage.getItem("twoh_bsND")) == null
        && JSON.parse(sessionStorage.getItem("twoh_hbND")) == null) || (JSON.parse(sessionStorage.getItem("twoh_noInvestigations")) === false
          && JSON.parse(sessionStorage.getItem("twoh_urineDipstick")) == true
          && JSON.parse(sessionStorage.getItem("twoh_bsND")) == true
          && JSON.parse(sessionStorage.getItem("twoh_hbND")) == true)) {

        this.http
          .get(BASE_URL + "mother/pregnancies/visit/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.twoh.getClassName())
          .subscribe(response => {
            if (Object.keys(response).length > 0) {
              this.twoh.mapforForm(response['answers']);
            } else {
              this.twoh.completedBy = this.global.currentUserName;
            }
          });
        this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
          .subscribe(response => {
            if (response['concluded']) this.Form.form.disable();
          });
        sessionStorage.setItem("twoh_uids_mid", JSON.stringify(this.uids.mid));
        sessionStorage.setItem("twoh_uids_pid", JSON.stringify(this.uids.pid));
        sessionStorage.setItem("twoh_uids_vid", JSON.stringify(this.uids.vid));
      } else {
        this.uids.mid = JSON.parse(sessionStorage.getItem("twoh_uids_mid"));
        this.uids.pid = JSON.parse(sessionStorage.getItem("twoh_uids_pid"));
        this.uids.vid = JSON.parse(sessionStorage.getItem("twoh_uids_vid"));


        this.twoh.noInvestigations = JSON.parse(sessionStorage.getItem("twoh_noInvestigations"));
        this.twoh.urineDipstick = JSON.parse(sessionStorage.getItem("twoh_urineDipstick"));
        this.twoh.udProtein = JSON.parse(sessionStorage.getItem("twoh_udProtein"));
        this.twoh.udGlucose = JSON.parse(sessionStorage.getItem("twoh_udGlucose"));
        this.twoh.udLeukocytes = JSON.parse(sessionStorage.getItem("twoh_udLeukocytes"));
        this.twoh.udKetones = JSON.parse(sessionStorage.getItem("twoh_udKetones"));
        this.twoh.udNitrites = JSON.parse(sessionStorage.getItem("twoh_udNitrites"));
        this.twoh.bs = JSON.parse(sessionStorage.getItem("twoh_bs"));
        this.twoh.bs2 = JSON.parse(sessionStorage.getItem("twoh_bs2"));
        this.twoh.bsND = JSON.parse(sessionStorage.getItem("twoh_bsND"));
        this.twoh.hb = JSON.parse(sessionStorage.getItem("twoh_hb"));
        this.twoh.hbND = JSON.parse(sessionStorage.getItem("twoh_hbND"));
        this.twoh.others = JSON.parse(sessionStorage.getItem("twoh_others"));
        this.twoh.completedBy = JSON.parse(sessionStorage.getItem("twoh_completedBy"));
      }
    }
    catch (ex) {
      console.log(ex);
    }
  }

  onSubmit() {
    let fd = this.twoh.mapforDB();
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      question: this.twoh.getClassName(),
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe(response => {
      this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
      this.global.toastToSave();
    });
  }

  iterator(end: number, start: number = 0, increment = 1): number[] {
    return this.global.iterateNumbers(end, start, increment);
  }


  ngDoCheck() {
    try {

      sessionStorage.setItem("twoh_noInvestigations", JSON.stringify(this.twoh.noInvestigations));
      sessionStorage.setItem("twoh_urineDipstick", JSON.stringify(this.twoh.urineDipstick));
      sessionStorage.setItem("twoh_udProtein", JSON.stringify(this.twoh.udProtein));
      sessionStorage.setItem("twoh_udGlucose", JSON.stringify(this.twoh.udGlucose));
      sessionStorage.setItem("twoh_udLeukocytes", JSON.stringify(this.twoh.udLeukocytes));
      sessionStorage.setItem("twoh_udKetones", JSON.stringify(this.twoh.udKetones));
      sessionStorage.setItem("twoh_udNitrites", JSON.stringify(this.twoh.udNitrites));
      sessionStorage.setItem("twoh_bs", JSON.stringify(this.twoh.bs));
      sessionStorage.setItem("twoh_bs2", JSON.stringify(this.twoh.bs2));
      sessionStorage.setItem("twoh_bsND", JSON.stringify(this.twoh.bsND));
      sessionStorage.setItem("twoh_hb", JSON.stringify(this.twoh.hb));
      sessionStorage.setItem("twoh_hbND", JSON.stringify(this.twoh.hbND));
      sessionStorage.setItem("twoh_others", JSON.stringify(this.twoh.others));
      sessionStorage.setItem("twoh_completedBy", JSON.stringify(this.twoh.completedBy));
    } catch (ex) {
      console.log(ex);
    }
  }
  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation === true){
  //     options.form.controls[options.name].setErrors(null);
  //   }else if(validation === false){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //   }
  // }
}
