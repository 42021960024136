import {Injectable} from '@angular/core';
import { HttpClient as Http, HttpHeaders } from '@angular/common/http';
import { Globals, BASE_URL } from 'app/globals';

@Injectable()
export class HttpHeadered {

    constructor(
      private http: Http,
      private globals : Globals
    ) {}

    get(url) {
        return this.http.get(url, {
            headers: new HttpHeaders({
                'authorization': this.globals.token,
            })
        });
    }

    post(url, data) {
        return this.http.post(url, data, {
            headers: new HttpHeaders({
                'authorization': this.globals.token,
            })
        });
    }

    put(url, data) {
        return this.http.put(url, data, {
            headers: new HttpHeaders({
                'authorization': this.globals.token,
            })
        });
    }

    delete(url, data) {
        return this.http.delete(url, {
            headers: new HttpHeaders({
                'authorization': this.globals.token,
            })
        });
    }
}