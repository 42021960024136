import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatMomentDateModule, MomentDateAdapter } from "@angular/material-moment-adapter";
import { 
  MatGridListModule, 
  MatInputModule, 
  MatButtonModule, 
  MatDatepickerModule, 
  MatSidenavModule, 
  MatRadioModule, 
  MatSelectModule, 
  MatCheckboxModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatListModule,
  MatCardModule,
  MAT_DATE_FORMATS,
  MAT_LABEL_GLOBAL_OPTIONS,
  MatProgressBarModule,
  MatIconModule,
  MatToolbarModule,
  MatExpansionModule,
  MatTooltipModule,
  MAT_DATE_LOCALE,
  DateAdapter
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from "@angular/common/http";
import { Globals } from 'app/globals';

import { AppComponent } from 'app/app.component';
import { DashboardComponent } from 'app/dashboard/dashboard.component';
import { AddMotherComponent } from 'app/add-mother/add-mother.component';
import { MotherProfileComponent } from 'app/mother-profile/mother-profile.component';
import { NewPregnancyComponent } from 'app/new-pregnancy/new-pregnancy.component';
import { VisitFormsComponent } from 'app/visit-forms/visit-forms.component';
import { VisitFormsQuestionsComponent } from 'app/visit-forms-questions/visit-forms-questions.component';
import { VisitFormsQuestions2aComponent } from 'app/visit-forms-questions-2a/visit-forms-questions-2a.component';
import { VisitFormsQuestions2bComponent } from 'app/visit-forms-questions-2b/visit-forms-questions-2b.component';
import { VisitFormsQuestions2cComponent } from 'app/visit-forms-questions-2c/visit-forms-questions-2c.component';
import { VisitFormsQuestions2dComponent } from 'app/visit-forms-questions-2d/visit-forms-questions-2d.component';
import { VisitFormsQuestions3aComponent } from 'app/visit-forms-questions-3a/visit-forms-questions-3a.component';
import { VisitFormsQuestions3bComponent } from 'app/visit-forms-questions-3b/visit-forms-questions-3b.component';
import { VisitFormsQuestions2eComponent } from 'app/visit-forms-questions-2e/visit-forms-questions-2e.component';
import { VisitFormsQuestions2fComponent } from 'app/visit-forms-questions-2f/visit-forms-questions-2f.component';
import { VisitFormsQuestions2gComponent } from 'app/visit-forms-questions-2g/visit-forms-questions-2g.component';
import { VisitFormsQuestions2hComponent } from 'app/visit-forms-questions-2h/visit-forms-questions-2h.component';
import { VisitFormsQuestions4aComponent } from 'app/visit-forms-questions-4a/visit-forms-questions-4a.component';
import { LoginComponent } from 'app/login/login.component';
import { CreateAccountLoginComponent } from 'app/create-account-login/create-account-login.component';
import { CreateAccountMainComponent } from 'app/create-account-main/create-account-main.component';
import { TopbarComponent } from 'app/topbar/topbar.component';
import { LogoutComponent } from 'app/logout/logout.component';
import { MotherProfileEditComponent } from 'app/mother-profile-edit/mother-profile-edit.component';
import { VisitFormsQuestion4aIntermediateComponent } from 'app/visit-forms-questions-4a-intermediate/visit-forms-question4a-intermediate.component';
import { VisitFormsQuestion4cComponent } from 'app/visit-forms-questions-4c/visit-forms-question4c.component';
import { VisitFormsQuestions5aComponent } from 'app/visit-forms-questions-5a/visit-forms-questions5a.component';
import { VisitFormsQuestions5bComponent } from 'app/visit-forms-questions-5b/visit-forms-questions-5b.component';
import { VisitFormsQuestions6aComponent } from 'app/visit-forms-questions-6a/visit-forms-questions-6a.component';
import { VisitFormsQuestions6bIntermediateComponent } from 'app/visit-forms-questions-6b-intermediate/visit-forms-questions-6b-intermediate.component';
import { VisitFormsQuestions6bComponent } from 'app/visit-forms-questions-6b/visit-forms-questions-6b.component';
import { ReportMainComponent } from 'app/report-main/report-main.component';
import { ReportFromComponent } from 'app/report-from/report-from.component';
import { ReportViewComponent } from 'app/report-view/report-view.component';
import { DialogConfirmationComponent } from 'app/dialog-confirmation/dialog-confirmation.component';
import { BabyMrDialogComponent } from 'app/baby-mr-dialog/baby-mr-dialog.component';
//REPORTS
import { MainComponent } from 'app/report/main/main.component';
import { AdmissionsReportComponent } from 'app/report/admissions-report/admissions-report.component';
import { SociodemographicsComponent } from 'app/report/sociodemographics/sociodemographics.component';
import { MaternalBiologicCharacteristicsComponent } from 'app/report/maternal-biologic-characteristics/maternal-biologic-characteristics.component';
import { ObstetricCharacteristicsComponent } from 'app/report/obstetric-characteristics/obstetric-characteristics.component';
import { AntenatalCareComponent } from 'app/report/antenatal-care/antenatal-care.component';
import { DeliveryDataComponent } from 'app/report/delivery-data/delivery-data.component';
import { DeliveryOutcomeComponent } from 'app/report/delivery-outcome/delivery-outcome.component';
import { DataCompletenessComponent } from 'app/report/data-completeness/data-completeness.component';
import { GlobalFunctions } from 'app/report/global';
import { ReportDialogComponent } from 'app/report/report-dialog/report-dialog.component';
import { LoginReportComponent } from 'app/report/login/login.component';
import { HttpHeadered } from 'app/services/httpHeadered';
import { MyTaskListComponent } from 'app/my-task-list/my-task-list.component';

export const MY_DATE_FORMATS = {
    parse: {
        dateInput: ['YYYY-MM-DD']
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'create-account/main', component: CreateAccountMainComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'my-task-list', component: MyTaskListComponent },
    { path: 'add-mother', component: AddMotherComponent },  
    { path: 'mother/:id', component: MotherProfileComponent },
    { path: 'mother/edit/:id', component: MotherProfileEditComponent },
    { path: 'mother/pregnancy/add/:id', component: NewPregnancyComponent },
    { 
        path: 'mother/pregnancy/visit/:id', component: VisitFormsComponent,
        children: [
            { path: '', component: VisitFormsQuestionsComponent },
            //{ path: '**', component: VisitFormsQuestionsComponent },
            { path: 'two-a', component: VisitFormsQuestions2aComponent },
            { path: 'two-b', component: VisitFormsQuestions2bComponent },
            { path: 'two-c', component: VisitFormsQuestions2cComponent },
            { path: 'two-d', component: VisitFormsQuestions2dComponent },
            { path: 'two-e', component: VisitFormsQuestions2eComponent },
            { path: 'two-f', component: VisitFormsQuestions2fComponent },
            { path: 'two-g', component: VisitFormsQuestions2gComponent },
            { path: 'two-h', component: VisitFormsQuestions2hComponent },
            { path: 'three-a', component: VisitFormsQuestions3aComponent },
            { path: 'three-b', component: VisitFormsQuestions3bComponent },
            { path: 'four-a', component: VisitFormsQuestion4aIntermediateComponent },
            { path: 'four-c', component: VisitFormsQuestion4cComponent },
            { path: 'five-a', component: VisitFormsQuestions5aComponent },
            { path: 'five-b', component: VisitFormsQuestions5bComponent },
            { path: 'six-a', component: VisitFormsQuestions6aComponent },
            { path: 'six-b', component: VisitFormsQuestions6bIntermediateComponent },
        ]
    },
    { path: 'mother/pregnancy/visit/add/baby', component: VisitFormsQuestions4aComponent },
    { path: 'mother/pregnancy/visit/add/baby/:id', component: VisitFormsQuestions4aComponent },
    { path: 'mother/pregnancy/visit/outcome/baby/:id', component: VisitFormsQuestions6bComponent },
    { path: 'report-login', component: LoginReportComponent },
    {
        path: 'report',
        component: MainComponent,
        children: [
            { path: 'admissions/:facility/:starty/:startm/:endy/:endm', component: AdmissionsReportComponent },
            { path: 'sociodemographics/:facility/:starty/:startm/:endy/:endm', component: SociodemographicsComponent },
            { path: 'maternalbiologic/:facility/:starty/:startm/:endy/:endm', component: MaternalBiologicCharacteristicsComponent },
            { path: 'obstetric/:facility/:starty/:startm/:endy/:endm', component: ObstetricCharacteristicsComponent },
            { path: 'anc/:facility/:starty/:startm/:endy/:endm', component: AntenatalCareComponent },
            { path: 'delivery/:facility/:starty/:startm/:endy/:endm', component: DeliveryDataComponent },
            { path: 'deliveryoutcome/:facility/:starty/:startm/:endy/:endm', component: DeliveryOutcomeComponent },
            { path: 'datacompleteness/:facility/:starty/:startm/:endy/:endm', component: DataCompletenessComponent },
        ]
    },
    { path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
];

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        AddMotherComponent,
        MotherProfileComponent,
        NewPregnancyComponent,
        VisitFormsComponent,
        VisitFormsQuestionsComponent,
        VisitFormsQuestions2aComponent,
        VisitFormsQuestions2bComponent,
        VisitFormsQuestions2cComponent,
        VisitFormsQuestions2dComponent,
        VisitFormsQuestions3aComponent,
        VisitFormsQuestions3bComponent,
        VisitFormsQuestions2eComponent,
        VisitFormsQuestions2fComponent,
        VisitFormsQuestions2gComponent,
        VisitFormsQuestions2hComponent,
        VisitFormsQuestions4aComponent,
        LoginComponent,
        CreateAccountMainComponent,
        CreateAccountLoginComponent,
        TopbarComponent,
        LogoutComponent,
        MotherProfileEditComponent,
        VisitFormsQuestion4aIntermediateComponent,
        VisitFormsQuestion4cComponent,
        VisitFormsQuestions5aComponent,
        VisitFormsQuestions5bComponent,
        VisitFormsQuestions6aComponent,
        VisitFormsQuestions6bIntermediateComponent,
        VisitFormsQuestions6bComponent,
        ReportMainComponent,
        ReportFromComponent,
        ReportViewComponent,
        DialogConfirmationComponent,
        BabyMrDialogComponent,
        //REPORTS
        LoginReportComponent,
        MainComponent,
        AdmissionsReportComponent,
        SociodemographicsComponent,
        MaternalBiologicCharacteristicsComponent,
        ObstetricCharacteristicsComponent,
        AntenatalCareComponent,
        DeliveryDataComponent,
        DeliveryOutcomeComponent,
        DataCompletenessComponent,
        ReportDialogComponent,
        MyTaskListComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CommonModule,
        MatGridListModule,
        MatInputModule,
        MatButtonModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatRadioModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatListModule,
        MatCardModule,
        MatIconModule,
        MatProgressBarModule,
        RouterModule.forRoot(
            appRoutes,
            { useHash: true }
        ),
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatExpansionModule,
        MatTooltipModule,
        MatSidenavModule,
        MatDialogModule,
        FlexLayoutModule,
    ],
    providers: [
        Globals,
        GlobalFunctions,
        HttpHeadered,
        {provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: {float: 'never'}},
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
    ],
    entryComponents: [
        ReportDialogComponent,
        CreateAccountLoginComponent,
        DialogConfirmationComponent,
        BabyMrDialogComponent
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }