import { Component, OnInit } from '@angular/core';
import { HttpClient as Http } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Globals, BASE_URL } from 'app/globals';
import { HttpHeadered } from 'app/services/httpHeadered';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginReportComponent implements OnInit {
  email : string;
  password : string;
  urlBase : string;
  progressHide : boolean = true;
  
  constructor(
    private http: Http, 
    private httpH : HttpHeadered,
    private global : Globals,
    private router : Router,
    public snackBar: MatSnackBar
  ) {
    this.urlBase = BASE_URL;
  }

  ngOnInit() {
    if(this.global.isLoggedIn()){
      this.router.navigateByUrl('/report');
    }
  }

  onSubmit(): void {
    let url = BASE_URL+[
      'report-v2',
      'login'
    ].join('/');
    this.http.post(url, {
      email: this.email,
      password: this.password
    }).subscribe(response => {
      let resp = response;
      if(resp['success']){
        this.global.privilage = <string>resp['privilage'];
        this.global.token = resp['key'];
        this.httpH.get(BASE_URL+'get-details').subscribe(data => {
          this.global.setCurrentUser(data['uuid'], data['name']);
          this.global.setFacilityDetails(data['facility_details']);
          this.router.navigateByUrl('/report').then(()=> {
            this.snackBar.open('Logged In.', '',{
              duration: 1000,
            });
          });
        });
      }else{
        this.snackBar.open('Login Failed. '+resp['message'], '',{
          duration: 1000,
        });
      }
    });
  }
}