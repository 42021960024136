import { Component, OnInit } from '@angular/core';
import * as Chartist from "chartist";
import * as CLegend from "assets/chartist/chartist.legend"
import * as CPoints from "assets/chartist/chartist.pointlabels";
import * as CFDoughnut from 'assets/chartist/chartist.fill-donut';
import * as CTooltip from 'assets/chartist/chartist-plugin-tooltip';
import * as CAxisTitle from 'assets/chartist/chartist-plugin-axistitle';
import * as moment from 'moment';
import * as jQ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { HttpHeadered as Http } from 'app/services/httpHeadered';
import { baseUrl, GlobalFunctions } from '../global';

@Component({
  selector: 'app-antenatal-care',
  templateUrl: './antenatal-care.component.html',
  styleUrls: ['./antenatal-care.component.css']
})
export class AntenatalCareComponent implements OnInit {
  anc = {us : null};
  conditions = {columns: [], data: []};
  private params = {};

  constructor(
    private aRoute : ActivatedRoute,
    private http : Http,
    private gFunc : GlobalFunctions,
  ) { }

  ngOnInit() {
    this.aRoute.params.subscribe(params => {
      let smonth = moment(params.startm, "MMMM").format('MM');
      let emonth = moment(params.endm, "MMMM").format('MM');
      this.params = {
        facility: params.facility,
        start: {
          year: params.starty,
          month: smonth,
        },
        end: {
          year: params.endy,
          month: emonth,
        }
      };
      let url = [
        baseUrl,
        "report-v2",
        "anccharacteristics",
        params.facility,
        params.starty,
        smonth,
        params.endy,
        emonth,
      ].join('/');
      this.http.get(url).subscribe(response => {
        let data = response;
        this.anc.us = data['ultrasound'];
        let v1 = data['anc_visits'];
        let numancvisits = new Chartist.Bar("#numancvisits", 
          {labels:v1.labels, series: [v1.series]},
          {
            height: "350px",
            axisY: {
              onlyInteger: true
            },
            plugins: [
              CPoints({
                labelOffset: {
                  x: 0,
                  y: -10
                },
              }),
              CAxisTitle({
                axisY:{
                  axisTitle: "Percent (%)",
                  textAnchor: 'middle',
                  flipTitle: true,
                  offset: {
                    x: 0,
                    y: 20,
                  }
                },
              })
            ],
          }
        );
        numancvisits.on('draw', (data) => {
          if(data.type == "bar"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("anc", "visits", item, this.params);
            });
          };
        });
        let v2 = data['ga_first_anc'];
        let gestat1anc = new Chartist.Bar("#gestat1anc", 
          {labels:v2.labels, series: [v2.series]},
          {
            height: "350px",
            axisY: {
              onlyInteger: true
            },
            /* showPoint: true, */
            plugins: [CPoints({
              labelOffset: {
                x: 0,
                y: -10
              },
            }), CAxisTitle({
              axisY:{
                axisTitle: "Percent (%)",
                textAnchor: 'middle',
                flipTitle: true,
                offset: {
                  x: 0,
                  y: 20,
                }
              },
            })],
          }
        );
        gestat1anc.on('draw', (data) => {
          if(data.type == "bar"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("anc", "ga", item, this.params);
            });
          };
        });
        let v3 = data['not_done'];
        let anctestsnd = new Chartist.Bar("#anctestsnd", 
          {labels:v3.labels, series: [v3.series]},
          {
            height: "350px",
            axisY: {
              onlyInteger: true
            },
            /* plugins: [CLegend({position: 'bottom'})], */
            plugins: [CPoints({
              labelOffset: {
                x: 0,
                y: -10
              },
            }), CAxisTitle({
              axisY:{
                axisTitle: "Percent (%)",
                textAnchor: 'middle',
                flipTitle: true,
                offset: {
                  x: 0,
                  y: 20,
                }
              },
            })],
          }
        );
        anctestsnd.on('draw', (data) => {
          if(data.type == "bar"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("anc", "testsnd", item, this.params);
            });
          };
        });
        let tot_anaemic = (data['anaemia']["series"]).reduce(this.gFunc.sum);
        let v4 = data['anaemia'];
        let anaemic = new Chartist.Pie("#anaemic", 
          v4,
          {
            height: "350px",
            plugins: [CLegend({position: 'bottom'})],
            labelInterpolationFnc: function(name, key) {
              let value = v4['series'][key];
              if(value['value'] == 0) return "";
              return value['value']+"   (" + Math.round(value['value'] / tot_anaemic * 100) + "%)";
            }
          }
        );
        anaemic.on('draw', (data) => {
          if(data.type == "slice"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("anc", "anaemic", item, this.params);
            });
          };
        });
        let conditions = [];
        let conditioncolumns = Object.keys(data['conditions'].VDRL);
        for(let x in data['conditions']){
          let d = [];
          d.push(x);
          for(let y in data['conditions'][x]){
            d.push(data['conditions'][x][y]);
          }
          conditions.push(d);
        }
        this.conditions.columns = conditioncolumns;
        this.conditions.data = conditions;
      });
    });
  }
  
  openDialog(item){
    this.gFunc.chartNavigate("anc", 'numeric', item, this.params);
  }

  tableCellClick(type, data, row, col){
    this.gFunc.tableCellClick('anc', type, data, row, col, this.params);
  }
}
