import { Component, OnInit } from '@angular/core';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material';
import { DialogConfirmationComponent } from "../dialog-confirmation/dialog-confirmation.component"
import { HttpHeadered as Http } from '../services/httpHeadered';

@Component({
  selector: 'app-mother-profile',
  templateUrl: './mother-profile.component.html',
  styleUrls: ['./mother-profile.component.css']
})
export class MotherProfileComponent implements OnInit {
  public mother: any;
  public mid: Number;
  public pregnancies: any;

  public disableAdd: boolean = false;

  constructor (
    private http: Http,
    public global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private matDialog: MatDialog,
  ) {
    this.mother = {};
  }

  ngOnInit() {
    this.aRoute.params.subscribe(params => {
      this.mid = params.id;
    });
    if (!this.global.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
    this.http.get(BASE_URL + "mother/details/" + this.mid)
      .subscribe(response => {
        this.mother = response;
        this.global.motherdetails = {
          name: this.mother.name,
          hid: this.mother.hospitalid,
        };
      });
    this.http.get(BASE_URL + "mother/pregnancies/list/" + this.mid)
      .subscribe(response => {
        this.pregnancies = response;
        for (let visits of this.pregnancies['active']['visits']) {
          if (visits.concluded === false) {
            this.disableAdd = true;
            break;
          }
        }
      });
  }

  closePregnancy(mid: number, pid: number) {
    let allow = false;
    let dialog = this.matDialog.open(DialogConfirmationComponent, {
      width: "450px",
      height: "auto",
      hasBackdrop: true,
      position: { top: "20vh" },
      data: { allowSet: allow }
    });
    dialog.afterClosed().subscribe(data => {
      if (typeof data.allowSet === "undefined" || !data.allowSet) return;
      this.http.delete(BASE_URL + "mother/pregnancies/conclude/" + ([mid, pid].join("/")), {})
        .subscribe(response => {
          if (response['success']) {
            window.location.reload();
          }
        });
    });
  }

  closeVisit(mid: number, pid: number, vid: number) {
    let allow = false;
    let dialog = this.matDialog.open(DialogConfirmationComponent, {
      width: "450px",
      height: "160px",
      hasBackdrop: true,
      position: { top: "20vh" },
      data: { allowSet: allow }
    });
    dialog.afterClosed().subscribe(data => {
      if (!data.allowSet) return;
      this.http.delete(BASE_URL + "mother/pregnancies/visit/conclude/" + ([mid, pid, vid].join("/")), {})
        .subscribe(response => {
          if (response['success']) {
            window.location.reload();
            this.global.resetStorage();
          }
        });
    });
  }

  /* get disableAdd() : boolean{
    if(typeof this.pregnancies === 'undefined') return false;
    for(let visits of this.pregnancies['active']['visits']){
      if(visits.concluded === false) {
        return true;
      }
    }
    return false;
  } */
}