import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { SixA } from '../models/questions/6a';
import { Validation } from '../validation';
import * as moment from 'moment';

@Component({
  selector: 'app-visit-forms-questions-6a',
  templateUrl: './visit-forms-questions-6a.component.html',
  styleUrls: ['./visit-forms-questions-6a.component.css']
})
export class VisitFormsQuestions6aComponent extends Validation implements OnInit {
  @ViewChild('formSixA', { read: ElementRef, static: false }) Form;
  sixa: SixA;
  uids: any;
  year = new Date().getFullYear();
  maxDate = moment().local();

  constructor (
    private http: Http,
    public global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.sixa = new SixA();
    this.uids = this.global.uidsfq;
  }

  ngOnInit() {
    try {
      sessionStorage.setItem("sixa_uids_mid", JSON.stringify(this.uids.mid));
      sessionStorage.setItem("sixa_uids_pid", JSON.stringify(this.uids.pid));
      sessionStorage.setItem("sixa_uids_vid", JSON.stringify(this.uids.vid));
      if (!this.global.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
      if (
        JSON.parse(sessionStorage.getItem("sixa_completedby")) == null
      ) {
        this.http
          .get(BASE_URL + "mother/pregnancies/visit/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.sixa.getClassName())
          .subscribe(response => {
            if (Object.keys(response).length > 0) {
              this.sixa.mapforForm(response['answers']);
            } else {
              this.sixa.completedby = this.global.currentUserName;
            }
          });
        this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
          .subscribe(response => {
            if (response['concluded']) this.Form.form.disable();
          });
      } else {
        this.uids.mid = JSON.parse(sessionStorage.getItem("sixa_uids_mid"))
        this.uids.pid = JSON.parse(sessionStorage.getItem("sixa_uids_pid"))
        this.uids.vid = JSON.parse(sessionStorage.getItem("sixa_uids_vid"))


        this.sixa.dateOfDischarge = JSON.parse(sessionStorage.getItem("sixa_dateOfDischarge"))
        this.sixa.delivery = JSON.parse(sessionStorage.getItem("sixa_delivery"))
        this.sixa.maternalDiag = JSON.parse(sessionStorage.getItem("sixa_maternalDiag"))
        this.sixa.finalOutcome = JSON.parse(sessionStorage.getItem("sixa_finalOutcome"))
        this.sixa.facility = JSON.parse(sessionStorage.getItem("sixa_facility"))
        this.sixa.reason = JSON.parse(sessionStorage.getItem("sixa_reason"))
        this.sixa.completedby = JSON.parse(sessionStorage.getItem("sixa_completedby"))
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  ngDoCheck() {
    try {
      sessionStorage.setItem("sixa_dateOfDischarge", JSON.stringify(this.sixa.dateOfDischarge))
      sessionStorage.setItem("sixa_delivery", JSON.stringify(this.sixa.delivery))
      sessionStorage.setItem("sixa_maternalDiag", JSON.stringify(this.sixa.maternalDiag))
      sessionStorage.setItem("sixa_finalOutcome", JSON.stringify(this.sixa.finalOutcome))
      sessionStorage.setItem("sixa_facility", JSON.stringify(this.sixa.facility))
      sessionStorage.setItem("sixa_reason", JSON.stringify(this.sixa.reason))
      sessionStorage.setItem("sixa_completedby", JSON.stringify(this.sixa.completedby))
    } catch (ex) {
      console.log(ex);
    }
  }

  onSubmit() {
    let fd = this.sixa.mapforDB();
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      question: this.sixa.getClassName(),
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe(response => {
      this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
      this.global.toastToSave();
      sessionStorage.removeItem("sixa_dateOfDischarge")
      sessionStorage.removeItem("sixa_delivery")
      sessionStorage.removeItem("sixa_maternalDiag")
      sessionStorage.removeItem("sixa_finalOutcome")
      sessionStorage.removeItem("sixa_facility")
      sessionStorage.removeItem("sixa_reason")
      sessionStorage.removeItem("sixa_completedby")
    });
  }

  iterator(end: number, start: number = 0, increment = 1): number[] {
    return this.global.iterateNumbers(end, start, increment);
  }

  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation === true){
  //     options.form.controls[options.name].setErrors(null);
  //   }else if(validation === false){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //   }
  // }
}
