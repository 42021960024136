import { Component, OnInit } from '@angular/core';
import * as Chartist from "chartist";
import * as CLegend from "assets/chartist/chartist.legend"
import * as CPoints from "assets/chartist/chartist.pointlabels";
import * as CFDoughnut from 'assets/chartist/chartist.fill-donut';
import * as CTooltip from 'assets/chartist/chartist-plugin-tooltip';
import * as CAxisTitle from 'assets/chartist/chartist-plugin-axistitle';
import * as moment from 'moment';
import * as jQ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { HttpHeadered as Http } from 'app/services/httpHeadered';
import { baseUrl, GlobalFunctions } from '../global';

@Component({
  selector: 'app-delivery-data',
  templateUrl: './delivery-data.component.html',
  styleUrls: ['./delivery-data.component.css']
})
export class DeliveryDataComponent implements OnInit {

  pptable : any;
  private params = {};

  constructor(
    private aRoute : ActivatedRoute,
    private http : Http,
    private gFunc : GlobalFunctions,
  ) { }

  ngOnInit() {
    this.aRoute.params.subscribe(params => {
      let smonth = moment(params.startm, "MMMM").format('MM');
      let emonth = moment(params.endm, "MMMM").format('MM');
      this.params = {
        facility: params.facility,
        start: {
          year: params.starty,
          month: smonth,
        },
        end: {
          year: params.endy,
          month: emonth,
        }
      };
      let url = [
        baseUrl,
        "report-v2",
        "deliverydata",
        params.facility,
        params.starty,
        smonth,
        params.endy,
        emonth,
      ].join('/');
      this.http.get(url).subscribe(response => {
        let data = response;
        let v1 = data['mod_g1'];
        let modg1 = new Chartist.Bar("#modg1", 
          v1,
          {
            height: "500px",
            axisY: {
              onlyInteger: true
            },
            stackBars: true,
            plugins: [
              CLegend({position: 'bottom'}),
              CPoints({
                labelOffset: {
                  x: 0,
                  y: -10
                },
              }),
              CAxisTitle({
                axisY:{
                  axisTitle: "Number Count",
                  textAnchor: 'middle',
                  flipTitle: true,
                  offset: {
                    x: 0,
                    y: 15,
                  }
                },
              })
            ],
          }
        );
        modg1.on('draw', (data) => {
          if(data.type == "bar"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("deliverydata", "modg1", item, this.params);
            });
          };
        });
        let v2 = data['mod_g2'];
        let tot_modg2 = (v2['series']).reduce(this.gFunc.sum);
        let modg2 = new Chartist.Pie("#modg2", 
          v2,
          {
            height: "350px",
            donut: true,
            donutWidth: 100,
            donutSolid: true,
            startAngle: 0,
            plugins: [CLegend({position: 'bottom'})],
            labelInterpolationFnc: function(name, key) {
              let value = v2['series'][key];
              if(value['value'] == 0) return "";
              return value['value']+"   (" + Math.round(value['value'] / tot_modg2 * 100) + "%)";
            }
          }
        );
        modg2.on('draw', (data) => {
          if(data.type == "slice"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("deliverydata", "modg2", item, this.params);
            });
          };
        });
        let v3 = data['onset'];
        let onset = new Chartist.Bar("#onset", 
          v3,
          {
            height: "500px",
            axisY: {
              onlyInteger: true
            },
            stackBars: true,
            plugins: [
              CLegend({position: 'bottom'}),
              CPoints({
                labelOffset: {
                  x: 0,
                  y: -10
                },
              }),
              CAxisTitle({
                axisY:{
                  axisTitle: "Number Count",
                  textAnchor: 'middle',
                  flipTitle: true,
                  offset: {
                    x: 0,
                    y: 15,
                  }
                },
              })
            ],
          }
        );
        onset.on('draw', (data) => {
          if(data.type == "bar"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("deliverydata", "onset", item, this.params);
            });
          };
        });
        let v4 = data['postpartum_g1'];
        let postpartumg1 = new Chartist.Bar("#postpartumg1", 
          v4,
          {
            height: "350px",
            axisY: {
              onlyInteger: true
            },
            seriesBarDistance: 40,
            plugins: [
              CLegend({position: 'bottom'}),
              CPoints({
                labelOffset: {
                  x: 0,
                  y: -10
                },
              }),
              CAxisTitle({
                axisY:{
                  axisTitle: "Number Count",
                  textAnchor: 'middle',
                  flipTitle: true,
                  offset: {
                    x: 0,
                    y: 15,
                  }
                },
              })
            ],
          }
        );
        postpartumg1.on('draw', (data) => {
          if(data.type == "bar"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("deliverydata", "postpartum", item, this.params);
            });
          };
        });
        let tot_birthcomapnion = (data['companion']["series"]).reduce(this.gFunc.sum);
        let v7 = data['companion'];
        let birthcomapnion = new Chartist.Pie("#birthcompanion", 
          v7,
          {
            height: "350px",
            donut: true,
            donutWidth: 100,
            donutSolid: true,
            startAngle: 0,
            plugins: [CLegend({position: 'bottom'})],
            labelInterpolationFnc: function(name, key) {
              let value = v7['series'][key];
              if(value['value'] == 0) return "";
              return value['value']+"   (" + Math.round(value['value'] / tot_birthcomapnion * 100) + "%)";
            }
          }
        );
        birthcomapnion.on('draw', (data) => {
          if(data.type == "slice"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("deliverydata", "birthcompanion", item, this.params);
            });
          };
        });
        let tot_multipledeliv = (data['multiple']["series"]).reduce(this.gFunc.sum);
        let v8 = data['multiple'];
        let hiv = new Chartist.Pie("#multipledeliv", 
          v8,
          {
            height: "350px",
            donut: true,
            donutWidth: 100,
            donutSolid: true,
            startAngle: 0,
            plugins: [CLegend({position: 'bottom'})],
            labelInterpolationFnc: function(name, key) {
              let value = v8['series'][key];
              if(value['value'] == 0) return "";
              return value['value']+"   (" + Math.round(value['value'] / tot_multipledeliv * 100) + "%)";
            }
          }
        );
        hiv.on('draw', (data) => {
          if(data.type == "slice"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("deliverydata", "multipledeliveries", item, this.params);
            });
          };
        });
        this.pptable = data['postpartum_g2'];
      });
    });
  }
  
  tableCellClick(data, row, col){
    this.gFunc.tableCellClick('deliverydata', "postpartumg2", data, row, col, this.params);
  }
}
