import { Component, OnInit } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { NewMother } from '../models/new-mother';
import { MatSnackBar } from '@angular/material';
import { Validation } from '../validation';
import * as moment from 'moment';

@Component({
  selector: 'app-mother-profile-edit',
  templateUrl: './mother-profile-edit.component.html',
  styleUrls: ['./mother-profile-edit.component.css']
})
export class MotherProfileEditComponent extends Validation implements OnInit {

  mid : number;
  motherDetails : NewMother;
  maxDate : moment.Moment = moment();

  constructor(
    private http: Http,
    private global : Globals,
    private router : Router,
    private aRoute : ActivatedRoute,
    public snackBar: MatSnackBar,
  ) {
    super();
    this.motherDetails = new NewMother();
    this.aRoute.params.subscribe(params => {
      this.mid = params.id;
    });
  }

  ngOnInit() {
    if(!this.global.isLoggedIn()){
      this.router.navigate(['/login']);
    }
    this.http.get(BASE_URL+"mother/details/"+ this.mid).subscribe( (response : NewMother) => {
      this.motherDetails = response;
      this.motherDetails.dobM = moment(this.motherDetails.dob, "YYYY-MM-DD");
      this.motherDetails.id = this.mid;
    });
  }

  onSubmit(){
    this.motherDetails.dob = this.motherDetails.dobM.local().format("YYYY-MM-DD");
    let data = JSON.parse(JSON.stringify(this.motherDetails));
    delete data['dobM'];
    this.http.post(BASE_URL+"mother/update", data)
    .subscribe(response => {
      if(response['success']){
        this.router.navigate(['/mother', this.mid]);
      }else{
        this.snackBar.open("Mother Not Edited. ERROR: "+response['message'], "", {
          duration: 2000,
        });
      }
    });
  }

  calculateDOB(){
    if(this.motherDetails.age !== null){
      let dt = moment();
      dt.subtract(this.motherDetails.age, 'year');
      this.motherDetails.dobM = dt;
    }
  }

  calculateAge(){
    if(this.motherDetails.dob !== null){
      let dt = this.motherDetails.dob;
      let nw = moment();
      let diff = nw.diff(dt, 'years');
      this.motherDetails.age = diff;
    }
  }
}
