import { Component, OnInit } from '@angular/core';
import { Globals, BASE_URL } from 'app/globals';
import { NewMother } from '../models/new-mother';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Router } from '@angular/router';
import { EmailValidator } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Validation } from '../validation';
import * as moment from 'moment';

@Component({
  templateUrl: './add-mother.component.html',
  styleUrls: ['./add-mother.component.css']
})
export class AddMotherComponent extends Validation implements OnInit {
  facilityDetails : any;
  motherDetails : NewMother;
  maxDate : moment.Moment = moment();

  constructor(
    private http: Http, 
    private global : Globals,
    private router : Router,
    public snackBar: MatSnackBar,
  ) { 
    super();
    this.motherDetails = new NewMother();
    this.facilityDetails = this.global.getFacilityDetails();
  }

  ngOnInit() {
    if(!this.global.isLoggedIn()){
      this.router.navigate(['/login']);
    }
    this.motherDetails.fname = this.facilityDetails.facilityname;
    this.motherDetails.fcode = this.facilityDetails.facilitycode;
    this.motherDetails.filledby = this.global.getCurrentUser();
  }

  onSubmit(){
    this.motherDetails.dob = this.motherDetails.dobM.local().format("YYYY-MM-DD");
    let data = JSON.parse(JSON.stringify(this.motherDetails));
    delete data['dobM'];
    this.http.post(BASE_URL+"mother/add", data)
    .subscribe(response => {
      if(response['success']){
        this.snackBar.open("Mother Registered.", "", {
          duration: 2000,
        });
        let mid = response['mid'];
        this.router.navigate(['/mother/'+mid]);
      }else{
        this.snackBar.open("Mother Not Saved. ERROR: "+response['message'], "", {
          duration: 2000,
        });
      }
    });
  }

  calculateDOB(){
    if(this.motherDetails.age !== null){
      let dt = moment();
      dt.subtract(this.motherDetails.age, 'year');
      this.motherDetails.dobM = dt;
    }
  }

  calculateAge(){
    if(this.motherDetails.dobM !== null){
      let dt = this.motherDetails.dobM;
      let nw = moment();
      let diff = nw.diff(dt, 'years');
      this.motherDetails.age = diff;
    }
  }

  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation === true){
  //     options.form.controls[options.name].setErrors(null);
  //   }else if(validation === false){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //   }
  // }
}