import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { TwoA } from '../models/questions/2a';
import * as moment from 'moment';
import { Validation } from '../validation';

@Component({
  selector: 'app-visit-forms-questions-2a',
  templateUrl: './visit-forms-questions-2a.component.html',
  styleUrls: ['./visit-forms-questions-2a.component.css']
})
export class VisitFormsQuestions2aComponent extends Validation implements OnInit {
  @ViewChild('formTwoA', { read: ElementRef, static: false }) Form;
  public twoa: TwoA;
  public checks: string[];
  public pc: boolean[];
  private uids: any;
  public eddus: moment.Moment = null;
  public errors;
  public year = new Date().getFullYear();
  public maxDate = moment().local();

  constructor (
    private http: Http,
    public global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.twoa = new TwoA();
    this.twoa.presentingComplaints = [];
    this.uids = this.global.uidsfq;
  }

  ngOnInit() {
    try {
      this.checks = this.twoa.list;
      if (!this.global.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
      if (JSON.parse(sessionStorage.getItem("twoa_dateOfAdmission")) === null) {
        for (let i = 0; i < this.checks.length; i++) {
          this.twoa.presentingComplaints.push(false);
        }
        this.http
          .get(BASE_URL + "mother/pregnancies/visit/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.twoa.getClassName())
          .subscribe(response => {
            if (Object.keys(response).length > 0) {
              this.twoa.mapforForm(response['answers']);
              this.twoa.dateOfAdmission = moment(this.twoa.dateOfAdmission, "YYYY-MM-DD");
              this.twoa.LMP = moment(this.twoa.LMP, "YYYY-MM-DD");
            } else {
              this.twoa.completedby = this.global.currentUserName;
            }
          });
        this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
          .subscribe(response => {
            if (response['concluded']) this.Form.form.disable();
          });
      }
      else {
        this.lmpUnknown = JSON.parse(sessionStorage.getItem("lmpUnknown"))
        this.lmp = JSON.parse(sessionStorage.getItem("lmp"))
        this.twoa.dateOfAdmission = JSON.parse(sessionStorage.getItem("twoa_dateOfAdmission"))
        this.twoa.timeOfAdmission.hh = JSON.parse(sessionStorage.getItem("twoa_timeOfAdmission_hh"))
        this.twoa.timeOfAdmission.mm = JSON.parse(sessionStorage.getItem("twoa_timeOfAdmission_mm"))
        this.twoa.timeToTravel.hh = JSON.parse(sessionStorage.getItem("twoa_timeToTravel_hh"))
        this.twoa.timeToTravel.mm = JSON.parse(sessionStorage.getItem("twoa_timeToTravel_mm"))
        this.twoa.firstAdmission = JSON.parse(sessionStorage.getItem("twoa_firstAdmission"))
        this.twoa.reffered = JSON.parse(sessionStorage.getItem("twoa_reffered"))
        this.twoa.EDDbyDate = JSON.parse(sessionStorage.getItem("twoa_EDDbyDate"))
        this.twoa.gestation.weeks = JSON.parse(sessionStorage.getItem("twoa_gestation_weeks"))
        this.twoa.gestation.days = JSON.parse(sessionStorage.getItem("twoa_gestation_days"))
        this.twoa.presentingComplaints = JSON.parse(sessionStorage.getItem("twoa_presentingComplaints"))
        this.twoa.others = JSON.parse(sessionStorage.getItem("twoa_others"))
        this.twoa.notes = JSON.parse(sessionStorage.getItem("twoa_notes"))
        this.twoa.completedby = JSON.parse(sessionStorage.getItem("twoa_completedby"))
        this.twoa.admittingdoc = JSON.parse(sessionStorage.getItem("twoa_admittingdoc"))
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  onSubmit() {
    let fd;
    let question;
    if (sessionStorage.getItem("twoa_mapforDB") !== null) {
      fd = sessionStorage.getItem("twoa_mapforDB")
      question = sessionStorage.getItem("twoa_getClassName")
    }
    else {
      fd = this.twoa.mapforDB()
      question = this.twoa.getClassName()
      console.log("question-c", question, "fd-c", fd);
    }
    // let fd = this.twoa.mapforDB();
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      question: question,
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe(response => {
      this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
      this.global.toastToSave();
      sessionStorage.removeItem("lmpUnknown");
      sessionStorage.removeItem("lmp");
      sessionStorage.removeItem("twoa_dateOfAdmission");
      sessionStorage.removeItem("twoa_timeOfAdmission_hh");
      sessionStorage.removeItem("twoa_timeOfAdmission_mm");
      sessionStorage.removeItem("twoa_timeToTravel_hh");
      sessionStorage.removeItem("twoa_timeToTravel_mm");
      sessionStorage.removeItem("twoa_firstAdmission");
      sessionStorage.removeItem("twoa_reffered");
      sessionStorage.removeItem("twoa_EDDbyDate");
      sessionStorage.removeItem("twoa_gestation_weeks");
      sessionStorage.removeItem("twoa_gestation_days");
      sessionStorage.removeItem("twoa_presentingComplaints");
      sessionStorage.removeItem("twoa_others");
      sessionStorage.removeItem("twoa_notes");
      sessionStorage.removeItem("twoa_completedby");
      sessionStorage.removeItem("twoa_admittingdoc");
    });
  }


  ngDoCheck() {
    try {
      sessionStorage.setItem("lmpUnknown", JSON.stringify(this.lmpUnknown))
      sessionStorage.setItem("lmp", JSON.stringify(this.lmp))
      sessionStorage.setItem("twoa_dateOfAdmission", JSON.stringify(this.twoa.dateOfAdmission))
      sessionStorage.setItem("twoa_timeOfAdmission_hh", JSON.stringify(this.twoa.timeOfAdmission.hh))
      sessionStorage.setItem("twoa_timeOfAdmission_mm", JSON.stringify(this.twoa.timeOfAdmission.mm))
      sessionStorage.setItem("twoa_timeToTravel_hh", JSON.stringify(this.twoa.timeToTravel.hh))
      sessionStorage.setItem("twoa_timeToTravel_mm", JSON.stringify(this.twoa.timeToTravel.mm))
      sessionStorage.setItem("twoa_firstAdmission", JSON.stringify(this.twoa.firstAdmission))
      sessionStorage.setItem("twoa_reffered", JSON.stringify(this.twoa.reffered))
      sessionStorage.setItem("twoa_EDDbyDate", JSON.stringify(this.twoa.EDDbyDate))
      sessionStorage.setItem("twoa_gestation_weeks", JSON.stringify(this.twoa.gestation.weeks))
      sessionStorage.setItem("twoa_gestation_days", JSON.stringify(this.twoa.gestation.days))
      sessionStorage.setItem("twoa_presentingComplaints", JSON.stringify(this.twoa.presentingComplaints))
      sessionStorage.setItem("twoa_others", JSON.stringify(this.twoa.others))
      sessionStorage.setItem("twoa_notes", JSON.stringify(this.twoa.notes))
      sessionStorage.setItem("twoa_completedby", JSON.stringify(this.twoa.completedby))
      sessionStorage.setItem("twoa_admittingdoc", JSON.stringify(this.twoa.admittingdoc))
    } catch (ex) {
      console.log(ex);
    }
  }

  clickCB(id: number) {
    this.twoa.presentingComplaints[id] = !this.twoa.presentingComplaints[id];
  }

  iterator(end: number, start: number = 0, increment = 1): number[] {
    return this.global.iterateNumbers(end, start, increment);
  }

  set lmp(value: moment.Moment) {
    this.twoa.LMP = value;
    if (this.twoa.LMP === null) {
      this.twoa.EDDbyDate = null;
    } else {
      let lmp = moment(this.twoa.LMP);
      this.twoa.EDDbyDate = lmp.add(280, 'days').local().format("YYYY-MM-DD");
      let doa = this.twoa.dateOfAdmission;
      if (doa !== null) {
        let diff = doa.diff(this.twoa.LMP);
        let duration = moment.duration(diff);
        this.twoa.gestation.weeks = Math.floor(duration.asWeeks());
        this.twoa.gestation.days = Math.floor((duration.asWeeks() - Math.floor(duration.asWeeks())) * 7);
      }
    }
  }

  get lmp(): moment.Moment {
    return this.twoa.LMP;
  }

  set lmpUnknown(value: boolean) {
    this.twoa.LMPUnknown = value;
    if (value) {
      this.twoa.EDDbyDate = null;
      this.twoa.gestation.weeks = null;
      this.twoa.gestation.days = null;
      this.twoa.LMP = null;
    }
  }

  get lmpUnknown(): boolean {
    return this.twoa.LMPUnknown;
  }
}
