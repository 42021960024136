import { Component, OnInit } from '@angular/core';
import * as Chartist from "chartist";
import * as CLegend from "assets/chartist/chartist.legend"
import * as CPoints from "assets/chartist/chartist.pointlabels";
import * as CFDoughnut from 'assets/chartist/chartist.fill-donut';
import * as CTooltip from 'assets/chartist/chartist-plugin-tooltip';
import * as CAxisTitle from 'assets/chartist/chartist-plugin-axistitle';
import * as moment from 'moment';
import * as jQ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { HttpHeadered as Http } from 'app/services/httpHeadered';
import { baseUrl, GlobalFunctions } from '../global';

@Component({
  selector: 'app-data-completeness',
  templateUrl: './data-completeness.component.html',
  styleUrls: ['./data-completeness.component.css']
})
export class DataCompletenessComponent implements OnInit {
  
  private params = {};

  constructor(
    private aRoute : ActivatedRoute,
    private http : Http,
    private gFunc : GlobalFunctions,
  ) { }

  ngOnInit() {
    this.aRoute.params.subscribe(params => {
      let smonth = moment(params.startm, "MMMM").format('MM');
      let emonth = moment(params.endm, "MMMM").format('MM');
      this.params = {
        facility: params.facility,
        start: {
          year: params.starty,
          month: smonth,
        },
        end: {
          year: params.endy,
          month: emonth,
        }
      };
      let url = [
        baseUrl,
        "report-v2",
        "datacompletness",
        params.facility,
        params.starty,
        smonth,
        params.endy,
        emonth,
      ].join('/');
      this.http.get(url).subscribe(response => {
        let data = response;
        let v1 = data['completeness'];
        let temp = new Chartist.Bar("#temp", 
          {
            labels: v1.labels,
            series: [v1.series]
          },
          {
            height: "350px",
            plugins: [
              CPoints({
                labelOffset: {
                  x: 0,
                  y: -10
                },
              }),
              CAxisTitle({
                axisY:{
                  axisTitle: "Percentage (%)",
                  textAnchor: 'middle',
                  flipTitle: true,
                  offset: {
                    x: 0,
                    y: 15,
                  }
                },
              })
            ],
            axisY: {
              onlyInteger: true
            },
          }
        );
        temp.on('draw', (data) => {
          if(data.type == "bar"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("datacompleteness", "graph", item, this.params);
            });
          };
        });
      });
    });
  }

  sum(x, y){
    return x+y;
  }
}