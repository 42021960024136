import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  /* styleUrls: ['./dialog-confirmation.component.css'] */
})
export class DialogConfirmationComponent implements OnInit {
  constructor(
    public dialogRef : MatDialogRef<DialogConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  btnClick(data : boolean){
    this.dialogRef.close({allowSet: data});
  }

}
