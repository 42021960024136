import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { FiveB } from '../models/questions/5b';
import { Validation } from '../validation';

@Component({
  selector: 'app-visit-forms-questions-5b',
  templateUrl: './visit-forms-questions-5b.component.html',
  styleUrls: ['./visit-forms-questions-5b.component.css']
})
export class VisitFormsQuestions5bComponent extends Validation implements OnInit {
  @ViewChild('formFiveB', { read: ElementRef, static: false }) Form;
  fiveb: FiveB;
  uids: any;

  constructor (
    private http: Http,
    private global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.fiveb = new FiveB();
    this.uids = global.uidsfq
  }

  ngOnInit() {
    if(!this.global.isLoggedIn()){
      this.router.navigate(['/login']);
    }
    for(let i = 0; i < this.fiveb.list.length; i++){
      this.fiveb.maternalInterventions.push(false);
    }
    for(let i = 0; i < this.fiveb.list.length; i++){
      this.fiveb.maternalInterventionsAnswer.push({before : null, after: null});
    }
    this.http
    .get(BASE_URL+"mother/pregnancies/visit/form/"+this.uids.mid+"/"+this.uids.pid+"/"+this.uids.vid+"/"+this.fiveb.getClassName())
    .subscribe( response => {
      if(Object.keys(response).length > 0){
        this.fiveb.mapforForm(response['answers']);
      }else{
        this.fiveb.completedby = this.global.currentUserName;
      }
    });
    this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
    .subscribe(response => {
      if(response['concluded']) this.Form.form.disable();
    });
  }



  clickCB(id: number) {
    this.fiveb.maternalInterventions[id] = !this.fiveb.maternalInterventions[id];
  }

  onSubmit() {
    let fd;
    let question;
      fd = this.fiveb.mapforDB()
      question = this.fiveb.getClassName()
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      question: question,
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    console.log(data);
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe( response => {
      this.router.navigate(['/mother/pregnancy/visit/'+id+"/"+this.global.getNextForm(this.router)]);
      this.global.toastToSave();
    });
  }

  iterator(end : number, start : number = 0, increment = 1 ) : number[]{
    return this.global.iterateNumbers(end, start, increment);
  }

  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation === true){
  //     options.form.controls[options.name].setErrors(null);
  //   }else if(validation === false){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //   }
  // }
}