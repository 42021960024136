import { Component, OnInit } from '@angular/core';
import * as Chartist from "chartist";
import * as CLegend from "assets/chartist/chartist.legend"
import * as CPoints from "assets/chartist/chartist.pointlabels";
import * as CFDoughnut from 'assets/chartist/chartist.fill-donut';
import * as CTooltip from 'assets/chartist/chartist-plugin-tooltip';
import * as CAxisTitle from 'assets/chartist/chartist-plugin-axistitle';
import * as moment from 'moment';
import * as jQ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { HttpHeadered as Http } from 'app/services/httpHeadered';
import { baseUrl, GlobalFunctions } from '../global';

@Component({
  selector: 'app-maternal-biologic-characteristics',
  templateUrl: './maternal-biologic-characteristics.component.html',
  styleUrls: ['./maternal-biologic-characteristics.component.css']
})
export class MaternalBiologicCharacteristicsComponent implements OnInit {
  
  public maternalbio = { alc: null, cig: null, com: null };
  private params = {};
  constructor(
    private aRoute : ActivatedRoute,
    private http : Http,
    private gFunc : GlobalFunctions,
  ) { }

  ngOnInit() {
    this.aRoute.params.subscribe(params => {
      let smonth = moment(params.startm, "MMMM").format('MM');
      let emonth = moment(params.endm, "MMMM").format('MM');
      this.params = {
        facility: params.facility,
        start: {
          year: params.starty,
          month: smonth,
        },
        end: {
          year: params.endy,
          month: emonth,
        }
      };
      let url = [
        baseUrl,
        "report-v2",
        "maternalbiologics",
        params.facility,
        params.starty,
        smonth,
        params.endy,
        emonth,
      ].join('/');
      this.http.get(url).subscribe(response => {
        var data = response;
        this.maternalbio.alc = data['count_alcohol'];
        this.maternalbio.com = data['count_comorbodities'];
        this.maternalbio.cig = data['count_cigarette'];
        let v1 = data['bmi'];
        let maternalbmi = new Chartist.Bar("#maternalbmi", 
          {labels: v1.labels, series: [v1.series]},
          {
            height: "350px",
            axisY: {
              onlyInteger: true
            },
            seriesBarDistance: 20,
            plugins: [CPoints({
              labelOffset: {
                x: 0,
                y: -10
              },
            }), CAxisTitle({
              axisY:{
                axisTitle: "Percent (%)",
                textAnchor: 'middle',
                flipTitle: true,
                offset: {
                  x: 0,
                  y: 20,
                }
              },
            })],
          }
        );
        maternalbmi.on('draw', (data) => {
          if(data.type == "slice" || data.type == "bar"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("maternalbiologics", "bmi", item, this.params);
            });
          };
        });
      });
    });
  }

  openDialog(item){
    this.gFunc.chartNavigate("maternalbiologics", 'numeric', item, this.params);
  }

}
