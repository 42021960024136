import * as moment from 'moment';

export class Validation{
    public errors : any = {};

    validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
      if(model === null || model.length < 1){
        options.form.controls[options.name].setErrors(null);
        this.errors[options.name] = null;
        return;
      }
      let validation = this.validationRule(model, options, type, event, allowDecimal);
      if(validation.incorrect === false){
        options.form.controls[options.name].setErrors(null);
        this.errors[options.name] = null;
      }else if(validation.incorrect === true){
        options.form.controls[options.name].setErrors({'incorrect' : true});
        this.errors[options.name] = validation.message;
      }
    }
    
    private validationRule (model : any, options : any = {}, type : string, event : any, allowDecimal = false, dp : number = 1){
      let state : any = {incorrect : null};
      let keysAllowed = [8, 9, 46, 37, 38, 39, 40];
      if(allowDecimal){
        keysAllowed.push(190);
        keysAllowed.push(110);
      }
      switch(type){
        case 'number':
          // if(isNaN(event.key) && (keysAllowed.indexOf(event.keyCode) < 0)){
          //   event.preventDefault();
          // }
          // if(model !== null && ((model.split('')).indexOf('.') >= 0) && event.key === '.'){
          //   event.preventDefault();
          // }
          if(options.max === null && options.min === null){
            return state;
          }
          let newD = model+(isNaN(event.key) ? "" : event.key);
          let test = '[^(\\d'+(allowDecimal ? '\\.' : '')+')]';
          let reg = new RegExp(test);
          if(reg.test(newD)){
            return {incorrect:true, message: "Incorrect value! Range is "+options.min+" to "+options.max};
          }
          if(newD > options.max || newD < options.min){
            state = {incorrect:true, message: "Incorrect value! Range is "+options.min+" to "+options.max};
          }else{
            if(allowDecimal){
              let adp = newD.split('.');
              if(adp.length > 1){
                let dp_have = (adp[adp.length-1].split(''));
                if(dp_have.length > dp){
                  state = {incorrect:true, message: "Incorrect value! Only "+dp+" decimal place(s) allowed."};
                }else{
                  state = {incorrect:false, message: null};
                }
              }else{
                state = {incorrect:false, message: null};
              }
            }else{
              state = {incorrect: false, message: null};
            }
          }
        break;
      }
      return state;
    }

    public dateObject(d : any, m : any, y : any) : Date{
      let dt = moment();
      dt.year(y);
      dt.month(m);
      dt.date(d);
      return dt.toDate();
    }

    public dateString(date : Date) : any{
      let dt = moment(date);
      return {
        d: dt.date().toString(),
        m: dt.format('MMMM'),
        y: dt.year().toString(),
      };
    }
}