import { Component, OnInit } from '@angular/core';
import * as Chartist from "chartist";
import * as CLegend from "assets/chartist/chartist.legend";
import * as CPoints from "assets/chartist/chartist.pointlabels";
import * as CFDoughnut from 'assets/chartist/chartist.fill-donut';
import * as CTooltip from 'assets/chartist/chartist-plugin-tooltip';
import * as moment from 'moment';
import * as jQ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { HttpHeadered as Http } from 'app/services/httpHeadered';
import { baseUrl, GlobalFunctions } from '../global';

@Component({
  selector: 'app-sociodemographics',
  templateUrl: './sociodemographics.component.html',
  styleUrls: ['./sociodemographics.component.css']
})
export class SociodemographicsComponent implements OnInit {

  sociodemo: any = {};
  params = {};
  constructor(
    private aRoute: ActivatedRoute,
    private http: Http,
    private gFunc : GlobalFunctions,
  ) { }

  ngOnInit() {
    this.aRoute.params.subscribe(params => {
      let smonth = moment(params.startm, "MMMM").format('MM');
      let emonth = moment(params.endm, "MMMM").format('MM');
      this.params = {
        facility: params.facility,
        start: {
          year: params.starty,
          month: smonth,
        },
        end: {
          year: params.endy,
          month: emonth,
        }
      };
      let url = [
        baseUrl,
        "report-v2",
        "sociodemographics",
        params.facility,
        params.starty,
        smonth,
        params.endy,
        emonth,
      ].join('/');
      this.http.get(url).subscribe(response => {
        let data = response;
        let tot_maternalage = (data['maternal_age']["series"]).reduce(this.gFunc.sum);
        let v1 = data['maternal_age'];
        let maternalage = new Chartist.Pie("#maternalage",
          v1,
          {
            height: "350px",
            startAngle: 270,
            donutWidth: 100,
            donutSolid: true,
            donut: true,
            total: (tot_maternalage * 2),
            plugins: [CLegend({ position: 'bottom' })],
            labelInterpolationFnc: function (name, key) {
              let value = v1['series'][key];
              if (value['value'] == 0) return "";
              return value['value'] + "   (" + Math.round(value['value'] / tot_maternalage * 100) + "%)";
            }
          }
        );
        maternalage.on('draw', (data) => {
          if(data.type == "slice"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("socioedemographics", "maternalage", item, this.params);
            });
          };
        });
        let tot_maternaledu = (data['maternal_edu']["series"]).reduce(this.gFunc.sum);
        let v2 = data['maternal_edu'];
        let maternaledu = new Chartist.Pie("#maternaledu",
          v2,
          {
            height: "350px",
            donut: true,
            donutWidth: 100,
            donutSolid: true,
            startAngle: 270,
            total: (tot_maternaledu * 2),
            plugins: [CLegend({ position: 'bottom' })],
            labelInterpolationFnc: function (name, key) {
              let value = v2['series'][key];
              if (value['value'] == 0) return "";
              return value['value'] + "   (" + Math.round(value['value'] / tot_maternaledu * 100) + "%)";
            }
          }
        );
        maternaledu.on('draw', (data) => {
          if(data.type == "slice"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("socioedemographics", "maternaledu", item, this.params);
            });
          };
        });
        let tot_maternalmaritalstat = (data['marital_status']["series"]).reduce(this.gFunc.sum);
        let v3 = data['marital_status'];
        let maternalmaritalstat = new Chartist.Pie("#maternalmaritalstat",
          v3,
          {
            height: "350px",
            donut: true,
            donutWidth: 100,
            donutSolid: true,
            startAngle: 270,
            total: (tot_maternalmaritalstat * 2),
            plugins: [CLegend({ position: 'bottom' })],
            labelInterpolationFnc: function (name, key) {
              let value = v3['series'][key];
              if (value['value'] == 0) return "";
              return value['value'] + "   (" + Math.round(value['value'] / tot_maternalmaritalstat * 100) + "%)";
            }
          }
        );
        maternalmaritalstat.on('draw', (data) => {
          if(data.type == "slice"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("socioedemographics", "maritalstatus", item, this.params);
            });
          };
        });
      })
    });
  }

  sum(x, y) {
    return x + y;
  }

}
