import { qModel } from './qModels';

export class ThreeA extends qModel{
    motherDeliver1 : string = null;
    motherDeliver2 : string = null;
    onsetLabour : string = null;
    bishopScore : number = null;
    indications : boolean[] = [];
    indicationsMapped : string[] = [];
    induction : boolean[] = [];
    inductionMapped : string[] = [];
    plannedVBAC : string = null;
    augOxy : string = null;
    armDone : string = null;
    aFHR1 : string = null;
    aCTG : string = null;
    labourAnalgesia : boolean[] = [];
    labourAnalgesiaMapped : string[] = [];
    aFHR2 : string = null;
    mod : boolean[] = [];
    modMapped : string[] = [];
    indicationsCS : boolean[] = [];
    indicationsCSMapped : string[] = [];
    meconium : string = null;
    offensiveLiquor : string = null;
    duration2Stage : string = null;
    dTime : object = {hh: null, mm: null};
    cTime : object = {hh: null, mm: null};
    notes : string = null;
    bloodLoss : number = null;
    utreotonics : boolean[] = [];
    utreotonicsMapped : string[] = [];
    placentaComplete : string = null;
    placentaRemoval : string = null;
    duration3Stage : string = null;
    episiotomy : string = null;
    perinealTear : string = null;
    grade : string = null;
    repaired : string = null;
    siteOfRepair : string = null;
    repairedBy : string = null;
    others_i : any = {
        omi : {value: null, locus: this.list.indications.length - 2},
        ofi : {value: null, locus: this.list.indications.length - 1},
    };
    others_c : any = {
        omi : {value: null, locus: this.list.indicationscs.length - 2},
        ofi : {value: null, locus: this.list.indicationscs.length - 1},
        pmtct: {value: null, locus: this.list.indicationscs.length - 8},
    };
    others_u : string = null;
    others_d : string = null;
    completedby : string = null;

    getClassName() : string {
        return "Section3a";
    }

    mapforDB() : any {   
        this.inductionMapped = this.mapChecksToList(this.list.induction, this.induction, this.others_d).join(",");
        this.indicationsMapped = this.mapChecksToList(this.list.indications, this.indications, this.others_i, true).join(",");
        this.indicationsCSMapped = this.mapChecksToList(this.list.indicationscs, this.indicationsCS, this.others_c, true).join(",");
        this.labourAnalgesiaMapped = this.mapChecksToList(this.list.labour, this.labourAnalgesia).join(",");
        this.utreotonicsMapped = this.mapChecksToList(this.list.utreotonics, this.utreotonics, this.others_u).join(",");
        this.modMapped = this.mapChecksToList(this.list.modeofdelivery, this.mod).join(",");
        //console.log(this.modMapped);
        return this.removeNullsAndReturnAnswers(this.propertyMapping);
    }
    
    mapforForm(data : any){
        this.dataToForm(this.propertyMapping, data);
        let induction = this.mapListToChecks(
            this.list.induction, 
            (typeof data['answer3a3'] === 'undefined' ? null :data['answer3a3'].split(',')), 
            'others_d'
        );
        this.induction = induction.checks;
        for(let i in induction){
            if(i !== "checks"){
                this[i] = induction[i];
            }
        }
        let indications = this.mapListToChecks(
            this.list.indications, 
            (typeof data['answer3a5'] === 'undefined' ? null :data['answer3a5'].split(',')), 
            {'omi' : this.others_i.omi.locus, 'ofi': this.others_i.ofi.locus},
            true
        );
        this.indications = indications.checks;
        for(let i in indications){
            if(i !== "checks"){
                this.others_i[i].value = indications[i];
            }
        }
        let labour = this.mapListToChecks(
            this.list.labour, 
            (typeof data['answer3a10'] === 'undefined' ? null :data['answer3a10'].split(',')), 
        );
        this.labourAnalgesia = labour.checks;
        let indicationscs = this.mapListToChecks(
            this.list.indicationscs, 
            (typeof data['answer3a15'] === 'undefined' ? null :data['answer3a15'].split(',')), 
            {'omi' : this.others_c.omi.locus, 'ofi': this.others_c.ofi.locus, 'pmtct': this.others_c.pmtct.locus},
            true
        );
        this.indicationsCS = indicationscs.checks;
        for(let i in indicationscs){
            if(i !== "checks"){
                this.others_c[i].value = indicationscs[i];
            }
        }
        let utreotonics = this.mapListToChecks(
            this.list.utreotonics, 
            (typeof data['answer3a23'] === 'undefined' ? null :data['answer3a23'].split(',')), 
            'others_u',
        );
        this.utreotonics = utreotonics.checks;
        for(let i in utreotonics){
            if(i !== "checks"){
                this[i] = utreotonics[i];
            }
        }
        let mod = this.mapListToChecks(
            this.list.modeofdelivery, 
            (typeof data['answer3a13'] === 'undefined' ? null :data['answer3a13'].split(',')),
        );
        this.mod = mod.checks;
        if(this.mod.indexOf(true) < 0){
            let mod = this.mapListToChecks(
                this.list['modeofdelivery-alt'], 
                (typeof data['answer3a13'] === 'undefined' ? null :data['answer3a13'].split(',')),
            );
            this.mod = mod.checks;
        }
    }

    get list() : any {
        return {
            "indications" : [
                "Post term / prolonged pregnancy?",
                "PROM at term",
                "Hypertension/Preeclampsia/Eclampsia",
                "Antepartum Haemorrhage",
                "Fetal Growth Restriction",
                "Maternal request / Non-medical indication",
                "Reduced fetal movements",
                "Preterm PROM",
                "Multiple gestation",
                "Gestational Diabetes",
                "Intrauterine Fetal Demise",
                "Other maternal indication",
                "Other fetal indication",
            ],
            "labour" : [
                "None",
                "Injectable analgesia",
                "Entonox Gas",
                "Epidural Analgesia",
            ],
            "indicationscs": [
                "Previous CS",
                "Other Malpresentation",
                "PMTCT – HIV",
                "Failed induction",
                "Non Reassuring Fetal Status",
                "Prolonged second stage",
                "Maternal Request / non-medical indication",
                "Multiple gestation",
                "Placenta Praevia",
                "PMTCT - Other",
                "Poor progress / Arrest in first stage of labour",
                "Cord Prolapse",
                "Maternal Exhaustion",
                "Breech presentation",
                "Antepartum Haemorrhage",
                "Other maternal indication",
                "Other fetal indication",
            ],
            "utreotonics" : [
                "None",
                "IM Oxytocin",
                "IV Oxytocin",
                "Misoprostol",
                "Ergometrine",
                "Other Uterotonic specify",
            ],
            "induction" : [
                "Prostagladin",
                "Oxytocin",
                "ARM",
                "Foley Catheter",
                "Other",
            ],
            "modeofdelivery":[
                "Vaginal - Spontaneous Cephalic",
                "Vaginal - Breech",
                "Vaginal - Vacuum Assisted",
                "Vaginal - Forceps",
                "Caesarean Section - Urgent/Emergency",
                "Caesarean Section - Elective/Planned"
            ],
            "modeofdelivery-alt":[
                "Cephalic",
                "Breech",
                "Vacuum Assisted",
                "Forceps",
                "Urgent/Emergency",
                "Elective/Planned"
            ]
        };
    }

    get propertyMapping(){
        return {
            "motherDeliver1" : {type: "String", apply: true, name: "answer3a1"},
            "onsetLabour" : {type: "String", apply: true, name: "answer3a2"},
            "inductionMapped" : {type: "Array", apply: false, name: "answer3a3"},
            "bishopScore" : {type: "String", apply: true, name: "answer3a4"},
            "indicationsMapped" : {type: "Array", apply: false, name: "answer3a5"},
            "plannedVBAC" : {type: "String", apply: true, name: "answer3a6"},
            "augOxy" : {type: "String", apply: true, name: "answer3a7"},
            "armDone" : {type: "String", apply: true, name: "answer3a8"},
            "aFHR1" : {type: "String", apply: true, name: "answer3a9"},
            // "aCTG" : {type: "String", apply: true, name: "answer3a10"},
            "labourAnalgesiaMapped" : {type: "Array", apply: false, name: "answer3a10"},
            "motherDeliver2" : {type: "String", apply: true, name: "answer3a11"},
            "aFHR2" : {type: "String", apply: true, name: "answer3a12"},
            "modMapped" : {type: "Array", apply: false, name: "answer3a13"},
            // "modC" : {type: "String", apply: true, name: "answer3a14"},
            "indicationsCSMapped" : {type: "Array", apply: false, name: "answer3a15"},
            "meconium" : {type: "String", apply: true, name: "answer3a16"},
            "offensiveLiquor" : {type: "String", apply: true, name: "answer3a17"},
            "duration2Stage" : {type: "String", apply: true, name: "answer3a18"},
            "dTime" : {type: "Time", apply: false, name: "answer3a19", mapTo:['hh', 'mm']},
            "cTime" : {type: "Time", apply: false, name: "answer3a20", mapTo:['hh', 'mm']},
            "notes" : {type: "String", apply: true, name: "answer3a21"},
            "bloodLoss" : {type: "String", apply: true, name: "answer3a22"},
            "utreotonicsMapped" : {type: "Array", apply: false, name: "answer3a23"},
            "placentaComplete" : {type: "String", apply: true, name: "answer3a24"},
            "placentaRemoval" : {type: "String", apply: true, name: "answer3a25"},
            "duration3Stage" : {type: "String", apply: true, name: "answer3a26"},
            "episiotomy" : {type: "String", apply: true, name: "answer3a27"},
            "perinealTear" : {type: "String", apply: true, name: "answer3a28"},
            "grade" : {type: "String", apply: true, name: "answer3a29"},
            "repaired" : {type: "String", apply: true, name: "answer3a30"},
            "siteOfRepair" : {type: "String", apply: true, name: "answer3a31"},
            "repairedBy" : {type: "String", apply: true, name: "answer3a32"},
            "aCTG" : {type: "String", apply: true, name: "answer3a33"},
            "completedby" : {type: "String", apply: true, name: "answer3a34"},
        };
    }

}
