export class NewAccount{
    public name : string;
    public dob : Date;
    public fname : string;
    public fcode : string;
    public cname : string;
    public ccode : string;
    public email: string;
    public password: string;
    public designation : string;
    public completedby : string;
}