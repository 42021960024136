import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { TwoF } from '../models/questions/2f';
import { Validation } from '../validation';

@Component({
  selector: 'app-visit-forms-questions-2f',
  templateUrl: './visit-forms-questions-2f.component.html',
  styleUrls: ['./visit-forms-questions-2f.component.css']
})
export class VisitFormsQuestions2fComponent extends Validation implements OnInit {
  @ViewChild('formTwoF', { read: ElementRef, static: false }) Form;
  twof: TwoF;
  uids: any;
  objectKeys = Object.keys;

  constructor (
    private http: Http,
    private global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.uids = this.global.uidsfq;
    this.twof = new TwoF();
  }

  ngOnInit() {
    if (!this.global.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
    this.uids = this.global.uidsfq;
    let previousSurgery = JSON.parse(sessionStorage.getItem("twof_previousSurgery"));
    let notes = JSON.parse(sessionStorage.getItem("twof_notes"));
    let previousUtrineSurgery = JSON.parse(sessionStorage.getItem("twof_previousUtrineSurgery"));
    console.log(notes == "No Entry" && previousUtrineSurgery == "No" && previousSurgery == "No");

    if ((previousSurgery == null && notes == null
      && previousUtrineSurgery == null) || (notes == "No Entry" && previousUtrineSurgery == "No" && previousSurgery == "No")) {
      for (let i of this.objectKeys(this.twof.list)) {
        console.log(this.twof.conditions[i] = {
          present: false,
          before: null,
          notes: null
        });
        this.twof.conditions[i] = {
          present: false,
          before: null,
          notes: null
        }
      }
      this.http
        .get(BASE_URL + "mother/pregnancies/visit/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.twof.getClassName())
        .subscribe(response => {
          if (Object.keys(response).length > 0) {
            sessionStorage.setItem("twof_mapforForm", JSON.stringify(response['answers']));
            this.twof.mapforForm(response['answers']);
          } else {
            this.twof.completedby = this.global.currentUserName;
          }
        });
      this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
        .subscribe(response => {
          if (response['concluded']) this.Form.form.disable();
        });
      sessionStorage.setItem("twof_mapforDB", JSON.stringify(this.twof.mapforDB()));
      sessionStorage.setItem("twof_getClassName", JSON.stringify(this.twof.getClassName()));
      sessionStorage.setItem("twof_uids_mid", JSON.stringify(this.uids.mid));
      sessionStorage.setItem("twof_uids_pid", JSON.stringify(this.uids.pid));
      sessionStorage.setItem("twof_uids_vid", JSON.stringify(this.uids.vid));
    } else {
      this.twof.mapforDB = JSON.parse(sessionStorage.getItem("twof_mapforDB"));
      this.twof.getClassName = JSON.parse(sessionStorage.getItem("twof_getClassName"));
      this.uids.mid = JSON.parse(sessionStorage.getItem("twof_uids_mid"));
      this.uids.pid = JSON.parse(sessionStorage.getItem("twof_uids_pid"));
      this.uids.vid = JSON.parse(sessionStorage.getItem("twof_uids_vid"));

      this.twof.noconditions = JSON.parse(sessionStorage.getItem("twof_noconditions"));
      this.twof.previousSurgery = JSON.parse(sessionStorage.getItem("twof_previousSurgery"));
      this.twof.previousUtrineSurgery = JSON.parse(sessionStorage.getItem("twof_previousUtrineSurgery"));
      this.twof.notes = JSON.parse(sessionStorage.getItem("twof_notes"));
      this.twof.completedby = JSON.parse(sessionStorage.getItem("twof_completedby"));
      let condition = JSON.parse(sessionStorage.getItem("twof_conditions"))
      for (let i of this.objectKeys(condition)) {
        console.log(condition[i]);
        this.twof.conditions[i] = condition[i]
      }
    }
  }

  clickCB(id: number) {
    this.twof.conditions[id]['present'] = !this.twof.conditions[id]['present'];
  }

  onSubmit() {
    let fd;
    let question;
    if (sessionStorage.getItem("twof_mapforDB") !== null) {
      fd = sessionStorage.getItem("twof_mapforDB")
      question = sessionStorage.getItem("twof_getClassName")
    }
    else {
      fd = this.twof.mapforDB()
      question = this.twof.getClassName()
      console.log("question-c", question, "fd-c", fd);
    }
    // let fd = this.twof.mapforDB();
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      question: question,
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe(response => {
      this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
      this.global.toastToSave();
      sessionStorage.removeItem("twof_mapforDB");
      sessionStorage.removeItem("twof_getClassName");
      sessionStorage.removeItem("twof_uids_mid");
      sessionStorage.removeItem("twof_uids_pid");
      sessionStorage.removeItem("twof_uids_vid");
      sessionStorage.removeItem("twof_noconditions");
      sessionStorage.removeItem("twof_previousSurgery");
      sessionStorage.removeItem("twof_previousUtrineSurgery");
      sessionStorage.removeItem("twof_notes");
      sessionStorage.removeItem("twof_completedby");
      sessionStorage.removeItem("twof_conditions");
    });
  }
  ngDoCheck() {
    try {
      sessionStorage.setItem("twof_noconditions", JSON.stringify(this.twof.noconditions));
      sessionStorage.setItem("twof_conditions", JSON.stringify(this.twof.conditions));
      sessionStorage.setItem("twof_previousSurgery", JSON.stringify(this.twof.previousSurgery));
      sessionStorage.setItem("twof_previousUtrineSurgery", JSON.stringify(this.twof.previousUtrineSurgery));
      sessionStorage.setItem("twof_notes", JSON.stringify(this.twof.notes));
      sessionStorage.setItem("twof_completedby", JSON.stringify(this.twof.completedby));
    } catch (ex) {
      console.log(ex);
    }
  }

  iterator(end: number, start: number = 0, increment = 1): number[] {
    return this.global.iterateNumbers(end, start, increment);
  }

  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation === true){
  //     options.form.controls[options.name].setErrors(null);
  //   }else if(validation === false){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //   }
  // }

}