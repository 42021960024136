import { Component, OnInit } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { FourC } from '../models/questions/4c';
import { Validation } from '../validation';

@Component({
  selector: 'app-visit-forms-question4c',
  templateUrl: './visit-forms-question4c.component.html',
  styleUrls: ['./visit-forms-question4c.component.css']
})
export class VisitFormsQuestion4cComponent extends Validation implements OnInit {

  fourc : FourC;
  uids : any;

  constructor(
    private http: Http,
    private global : Globals,
    private router : Router,
    private aRoute : ActivatedRoute,
  ) { 
    super();
    this.fourc = new FourC();
    this.uids = global.uidsfq
  }

  ngOnInit() {
    if(!this.global.isLoggedIn()){
      this.router.navigate(['/login']);
    }
    for(let i = 0; i < this.fourc.list.length; i++){
      this.fourc.anomalies.push(false);
    }
  }

  clickCB(id : number){
    this.fourc.anomalies[id] = !this.fourc.anomalies[id];
  }
  
  onSubmit(){
    let fd = this.fourc.mapforDB();
    let data = {
      mid : this.uids.mid,
      pid : this.uids.pid,
      vid : this.uids.vid,
      question : this.fourc.getClassName(),
      enteredby: this.global.getCurrentUser(),
      answers : fd,
    };
    console.log(data);
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe( response => {
      this.router.navigate(['/mother/pregnancy/visit/'+id+"/"+this.global.getNextForm(this.router)]);
      this.global.toastToSave();
    });
  }

  iterator(end : number, start : number = 0, increment = 1 ) : number[]{
    return this.global.iterateNumbers(end, start, increment);
  }

  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation === true){
  //     options.form.controls[options.name].setErrors(null);
  //   }else if(validation === false){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //   }
  // }
}
