import { qModel } from './qModels';

export class TwoD extends qModel{
    hb : string = null;
    hbND : boolean = null;
    vdrl : string = null;
    vdrlND : boolean = null;
    hiv : string = null;
    hbsag : string = null;
    bg : string = null;
    bgND : boolean = null;
    rh : string = null;
    ogtt : any = {fasting: null, one: null, two: null};
    ogttND : boolean = null;
    others : string = null;
    completedby : string = null;

    getClassName() : string {
        return "Section2d";
    }

    mapforDB() : any {
        return this.removeNullsAndReturnAnswers(this.propertyMapping);
    }
    
    mapforForm(data : any){
        this.dataToForm(this.propertyMapping, data);
        if(data['answer2d1'] === 'Not Done'){
            this.hbND = true;
        }
        if(data['answer2d2'] === 'Not Done'){
            this.vdrlND = true;
        }
        if(data['answer2d5'] === 'Not Done'){
            this.bgND = true;
        }
        if(data['answer2d7'] === 'Not Done'){
            this.ogttND = true;
        }
    }

    get list() : any {
        return [];
    }

    get propertyMapping(){
        return {
            "hb" : {type: "String", apply: true, name: "answer2d1"},
            "hbND" : {type: "Mapped", apply: true, name: null, mapTo: ["hb"], value: 'Not Done'},
            "vdrl" : {type: "String", apply: true, name: "answer2d2"},
            "vdrlND" : {type: "Mapped", apply: false, name: null, mapTo: ["vdrl"], value: 'Not Done'},
            "hiv" : {type: "String", apply: true, name: "answer2d3"},
            "hbsag" : {type: "String", apply: true, name: "answer2d4"},
            "bg" : {type: "String", apply: true, name: "answer2d5"},
            "bgND" : {type: "Mapped", apply: true, name: null, mapTo: ["bg"], value: 'Not Done'},
            "rh" : {type: "String", apply: true, name: "answer2d6"},
            "ogtt" : {type: "Object", apply: false, name: "answer2d7", mapTo:["fasting", "one", "two"]},
            "ogttND" : {type: "Mapped", apply: true, name: null, mapTo: ["ogtt"], value: 'Not Done'},
            "others" : {type: "String", apply: true, name: "answer2d10"},
            "completedby": {type: "String", apply: true, name: "answer2d11"},
        };
    }
}