import { Component, OnInit } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { MotherPregnancy } from '../models/mother-pregnancy';
import { MatSnackBar } from '@angular/material';
import * as moment from "moment";

@Component({
  selector: 'app-new-pregnancy',
  templateUrl: './new-pregnancy.component.html',
  styleUrls: ['./new-pregnancy.component.css']
})
export class NewPregnancyComponent implements OnInit {

  public mid : number;
  public pregnancy : MotherPregnancy;
  public maxDate : moment.Moment = moment();

  constructor(
    private http: Http,
    private global : Globals,
    private router : Router,
    private aRoute : ActivatedRoute,
    private snackBar : MatSnackBar
  ) { 
    this.pregnancy = new MotherPregnancy();
  }

  ngOnInit() {
    if(!this.global.isLoggedIn()){
      this.router.navigate(['/login']);
    }
    this.aRoute.params.subscribe(params => {
      this.mid = params.id;
      this.http.get(BASE_URL+"mother/pregnancies/add-req/"+this.mid)
      .subscribe( response => {
        let data = response;
        let motherDetails = data['mother'];
        let fdetails = this.global.getFacilityDetails();
        this.pregnancy.mother = this.mid;
        this.pregnancy.fcode = fdetails.facilitycode;
        this.pregnancy.fname = fdetails.facilityname;
        this.pregnancy.hospitalid = motherDetails.hospitalid;
        this.pregnancy.visitdate = moment().local().format("YYYY-MM-DD");
        this.pregnancy.status = true;
      });
      this.pregnancy.enteredby = this.global.getCurrentUser();
    });
  }

  onSubmit(){
    let url = BASE_URL+"mother/pregnancies/add/"+this.mid;
    this.pregnancy.visitdate = this.pregnancy.visitdateM.local().format("YYYY-MM-DD");
    this.http.post(url, this.pregnancy).subscribe( response => {
      if(response['success']){
        this.snackBar.open("New pregnancy/visit added.", "", {
          duration: 2000,
        });
        this.router.navigate(['/mother', this.mid]);
      }else{
        this.snackBar.open("Pregnancy NOT Saved. ERROR: "+response['message'], "", {
          duration: 4000,
        });
      }
    });
  }

}
