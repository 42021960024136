import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpHeadered as Http } from 'app/services/httpHeadered';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Globals, BASE_URL } from 'app/globals';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  private endYear = 2017;
  public logged_in = false;
  public selected : any = {
    facility: null,
    sdate: {month: null, year: null},
    edate: {month: null, year: null},
  };
  public report = null;

  public dateselects = {
    months: [],
    years: [],
  }

  public facilities: any = [];

  constructor(
    private router : Router,
    private http : Http,
    private dialog : MatDialog,
    private global : Globals,
  ) { 
    this.dateselects.months = moment.months();
    let years = [];
    for(let i = Number.parseInt(moment().format("YYYY")); i >= this.endYear; i-- ){
      this.dateselects.years.push(i);
    }
  }

  ngOnInit() {
    if(!this.global.isLoggedIn()){
      this.router.navigate(['/report-login']);
    }else{
      this.logged_in = true;
      let url = [
        BASE_URL ,
        "report-v2",
        "list",
        "facilities"
      ].join("/");
      // let url = [
      //   BASE_URL === "/" ? "" : BASE_URL,
      //   "report-v2",
      //   "list",
      //   "facilities"
      // ].join("/");
      this.http.get(url).subscribe( response => {
        let data = response;
        this.facilities = data;
      });
    }
  }

  nav( url : string, form : any){
    if(!form.form.valid || form.form.pristine){
      return;
    }
    let url_gen = [
      "report",
      url,
      this.selected.facility,
      this.selected.sdate.year,
      this.selected.sdate.month,
      this.selected.edate.year,
      this.selected.edate.month,
    ].join("/");
    this.router.navigateByUrl(url_gen);
  }

  logout(){
    this.global.clearLogin();
    this.http.get(BASE_URL+"logout").subscribe();
    this.router.navigate(['/report-login']);
  }
}
