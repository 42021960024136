import { Component, OnInit } from '@angular/core';
import * as Chartist from "chartist";
import * as CLegend from "assets/chartist/chartist.legend";
import * as CPoints from "assets/chartist/chartist.pointlabels";
import * as CFDoughnut from 'assets/chartist/chartist.fill-donut';
import * as CTooltip from 'assets/chartist/chartist-plugin-tooltip';
import * as CAxisTitle from 'assets/chartist/chartist-plugin-axistitle';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { HttpHeadered as Http } from 'app/services/httpHeadered';
import { baseUrl } from '../global';

@Component({
  selector: 'app-obstetric-characteristics',
  templateUrl: './obstetric-characteristics.component.html',
  styleUrls: ['./obstetric-characteristics.component.css']
})
export class ObstetricCharacteristicsComponent implements OnInit {

  obstetric = {parity : null};

  constructor(
    private aRoute : ActivatedRoute,
    private http : Http
  ) { }

  ngOnInit() {
    this.aRoute.params.subscribe(params => {
      let smonth = moment(params.startm, "MMMM").format('MM');
      let emonth = moment(params.endm, "MMMM").format('MM');
      let url = [
        baseUrl,
        "report-v2",
        "obstetriccharacteristics",
        params.facility,
        params.starty,
        smonth,
        params.endy,
        emonth,
      ].join('/');
      this.http.get(url).subscribe(response => {
        let data = response;
        this.obstetric.parity = data['primaparity'];
        let v2 = data['gravidity'];
        let gravidity = new Chartist.Bar("#gravidity", 
          {labels: v2.labels, series: [v2.series]},
          {
            height: "350px",
            plugins: [CPoints({
              labelOffset: {
                x: 0,
                y: -10
              },
            }), CAxisTitle({
              axisY:{
                axisTitle: "Percent (%)",
                textAnchor: 'middle',
                flipTitle: true,
                offset: {
                  x: 0,
                  y: 15,
                }
              },
            })],
          }
        );
        let v3 = data['prevcs'];
        let prevcs = new Chartist.Bar("#prevcs", 
          {labels: v3.labels, series: [v3.series]},
          {
            height: "350px",
            plugins: [CPoints({
              labelOffset: {
                x: 0,
                y: -10
              },
            }), CAxisTitle({
              axisY:{
                axisTitle: "Percent (%)",
                textAnchor: 'middle',
                flipTitle: true,
                offset: {
                  x: 0,
                  y: 15,
                }
              },
            })],
          }
        );
      });
    })
  }

  sum(x, y){
    return x+y;
  }


}
