import { qModel } from './qModels';

export class TwoC extends qModel{
    ancVisits : string = null;
    ancDate : Date = null;
    tt : string = null;
    mp : string = null;
    dw : string = null;
    bt : string = null;
    supplements : boolean[] = [];
    supplementsMapped : string[] = [];
    medications : boolean[] = [];
    medicationsMapped : string[] = [];
    drugs : boolean[] = [];
    drugsMapped : string[] = [];
    other : any = { supplements : null, drugs: null, medications : null };
    completedby : string = null;

    getClassName() : string {
        return "Section2c";
    }

    mapforDB() : any {
        this.supplementsMapped = this.mapChecksToList(this.list.supplements, this.supplements, this.other.supplements).join(",");
        this.medicationsMapped = this.mapChecksToList(this.list.medications, this.medications, this.other.medications).join(",");
        this.drugsMapped = this.mapChecksToList(this.list.drugs, this.drugs, this.other.drugs).join(",");
        return this.removeNullsAndReturnAnswers(this.propertyMapping);
    }
    
    mapforForm(data : any){
        this.dataToForm(this.propertyMapping, data);
        let supl = this.mapListToChecks(this.list.supplements, (typeof data['answer2c7'] === 'undefined' ? null : data['answer2c7'].split(',')), 'supplements');
        let meds = this.mapListToChecks(this.list.medications, (typeof data['answer2c8'] === 'undefined' ? null :data['answer2c8'].split(',')), 'medications');
        let drug = this.mapListToChecks(this.list.drugs, (typeof data['answer2c9'] === 'undefined' ? null : data['answer2c9'].split(',')), 'drugs');
        this.supplements = supl.checks;
        this.medications = meds.checks;
        this.drugs = drug.checks;
        for(let i in supl){
            if(i !== "checks"){
                this.other[i] = supl[i];
            }
        }
        for(let i in meds){
            if(i !== "checks"){
                this.other[i] = meds[i];
            }
        }
        for(let i in drug){
            if(i !== "checks"){
                this.other[i] = drug[i];
            }
        }
    }

    get list() : any {
        return {
            supplements: [
                "None",
                "Iron",
                "Folic acid",
                "Calcium",
                "Vitamin D",
                "Other",
            ],
            medications: [
                "None",
                "Aspirin",
                "Antenatal Corticosteroids",
                "Insulin",
                "Metformin",
                "ART",
                "Antibiotics",
                "Anti-hypertensives",
                "Anti-D",
                "LMWH",
                "Warfarin",
                "Other",
            ],
            drugs: [
                "None",
                "Alcohol",
                "Cigarette smoking",
                "Tobacco chewing",
                // "Metformin",
                "Pica",
                "Other",
            ]
        };
    }

    get propertyMapping(){
        return {
            "ancVisits" : {type: "String", apply: true, name: "answer2c1"},
            "ancDate" : {type: "Date", apply: true, name: "answer2c2"},
            "tt" : {type: "String", apply: true, name: "answer2c3"},
            "mp" : {type: "String", apply: true, name: "answer2c4"},
            "dw" : {type: "String", apply: true, name: "answer2c5"},
            "bt" : {type: "String", apply: true, name: "answer2c6"},
            "supplementsMapped": {type: "Array", apply: false, name: "answer2c7"},
            "medicationsMapped": {type: "Array", apply: false, name: "answer2c8"},
            "drugsMapped": {type: "Array", apply: false, name: "answer2c9"},
            "completedby": {type: "String", apply: true, name: "answer2c10"},
        };
    }
}