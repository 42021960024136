import { qModel } from './qModels';
import * as moment from 'moment';

export class SixB extends qModel{
    babyNumber : number = null;
    name : string = null;
    dateOfDischarge : moment.Moment = null;
    babyId : string = null;
    babyDiagnosis : string = null;
    finalOutcome : string = null;
    facility : string = null;
    reason : string = null;
    completedby : string = null;

    getClassName() : string {
        return "Section6b";
    }

    mapforDB() : any {
        return this.removeNullsAndReturnAnswers(this.propertyMapping);
    }
    
    mapforForm(data : any){
        this.dataToForm(this.propertyMapping, data);
    }

    get list() : any {
        return [];
    }

    get propertyMapping(){
        return {
            babyNumber : {type: "String", apply: true, name: "answer6b1"},
            name : {type: "String", apply: true, name: "answer6b2"},
            dateOfDischarge : {type: "Date", apply: true, name: "answer6b3"},
            babyId : {type: "String", apply: true, name: "answer6b4"},
            babyDiagnosis : {type: "String", apply: true, name: "answer6b5"},
            finalOutcome : {type: "String", apply: true, name: "answer6b6"},
            facility : {type: "String", apply: true, name: "answer6b7"},
            reason : {type: "String", apply: true, name: "answer6b8"},
            completedby : {type: "String", apply: true, name: "answer6a9"},
        };
    }
}