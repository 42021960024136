import { qModel } from './qModels';

export class FiveB extends qModel{
    maternalInterventions : boolean[] = [];
    maternalInterventionsMapped : string[] = [];
    maternalInterventionsAnswer : any = [];
    other : string = null;
    completedby : string = null;

    getClassName() : string {
        return "Section5b";
    }

    mapforDB() : any {
        let details = {};
        let i, j;
        for(i = 0, j = 1; i < this.list.length; i++, j++){
            let temp = ['answer'];
            let name1 = 'answer5b'+j+'state';
            let name2 = 'answer5b'+j+'ans';
            details[name1] = this.maternalInterventions[i] ? "Present" : "No Entry";
            if(this.maternalInterventionsAnswer[i].before === true){
                temp.push("Before Delivery");
            }
            if(this.maternalInterventionsAnswer[i].after === true){
                temp.push("After Delivery");
            }
            details[name2] = temp.join(',');
        }
        details['answer5b'+j] = this.other === null ? "No Entry" : this.other;
        return details;
    }
    
    mapforForm(data : any){
        for(let key in data){
            let akey = key.slice("answer5b".length);
            if(isNaN(+akey)){
                if((akey.split('state')).length > 1 && (akey.split('ans')).length < 2){
                    let num : number = parseInt(akey.split('state')[0])-1;
                    this.maternalInterventions[num] = data[key] === 'Present' ? true : false;
                }else if((akey.split('state')).length < 2 && (akey.split('ans')).length > 1){
                    let num : number = parseInt(akey.split('ans')[0])-1;
                    if(data[key] !== "No Entry"){
                        let split = data[key].split(',');
                        split.splice(0, 1);
                        if(split.indexOf("Before Delivery") >= 0){
                            this.maternalInterventionsAnswer[num].before = true;
                        }else{
                            this.maternalInterventionsAnswer[num].before = false;
                        }
                        if(split.indexOf("After Delivery") >= 0){
                            this.maternalInterventionsAnswer[num].after = true;
                        }else{
                            this.maternalInterventionsAnswer[num].after = false;
                        }
                    }
                }
            }else{
                switch(akey){
                    case '10':
                        this.other = data[key];
                    break;
                }
            }
        }
    }

    get list() : any {
        return [
            "Corticosteroids",
            "Tocolytics",
            "Magnesium Sulphate",
            "Oral Antihypertensives",
            "Parenteral Antihypertensives",
            "Antibiotics",
            "Antiretroviral Therapy",
            "Blood and/or Blood Products Transfusion",
            "Other drugs or interventions",
        ];
    }

    get propertyMapping(){
        return {
            "complicationsMapped" : {type: "Array", apply: false, name: "5a1"},
            "completedby": {type: "String", apply: true, name: "answer5b2"},
        };
    }
}