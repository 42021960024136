import { qModel } from './qModels';

export class ThreeB extends qModel{
    complications : boolean[] = [];
    complicationsMapped : string[] = [];
    other : any = {
        complications: null,
        companion : null,
    };
    partogram : string = null;
    hadCompanion : string = null;
    companion : boolean[] = [];
    companionMapped : string[] = [];
    othernotes : string = null;
    attendedby: boolean[] = [];
    attendedbyMapped: string[] = [];
    designation : string = null;

    getClassName() : string {
        return "Section3b";
    }

    mapforDB() : any {
        this.complicationsMapped = this.mapChecksToList(this.list.complications, this.complications, this.other.complications).join(',');
        this.companionMapped = this.mapChecksToList(this.list.companion, this.companion, this.other.companion).join(',');
        this.attendedbyMapped = this.mapChecksToList(this.list.attendedby, this.attendedby).join(',');
        return this.removeNullsAndReturnAnswers(this.propertyMapping);
    }
    
    mapforForm(data : any){
        this.dataToForm(this.propertyMapping, data);
        let complications = this.mapListToChecks(
            this.list.complications, 
            (typeof data['answer3b1'] === 'undefined' ? null :data['answer3b1'].split(',')), 
            'complications'
        );
        this.complications = complications.checks;
        for(let i in complications){
            if(i !== "checks"){
                this.other[i] = complications[i];
            }
        }
        let companion = this.mapListToChecks(
            this.list.companion, 
            (typeof data['answer3b4'] === 'undefined' ? null :data['answer3b4'].split(',')), 
            'companion'
        );
        this.companion = companion.checks;
        for(let i in companion){
            if(i !== "checks"){
                this.other[i] = companion[i];
            }
        }
        let attendedby = this.mapListToChecks(
            this.list.attendedby, 
            (typeof data['answer3b6'] === 'undefined' ? null :data['answer3b6'].split(','))
        );
        this.attendedby = attendedby.checks;
    }

    get list() : any {
        return {
            'complications' : [
                "None",
                "Prolonged ROM > 18 hours",
                "APH - Placenta praevia",
                "Shoulder dystocia",
                "PPH",
                "Ruptured uterus",
                "APH - Placental abruption",
                "APH - unknown aetiology",
                "Cord prolapse",
                "Maternal fever/ pyrexia in labour",
                "Uterine Scar dehiscence",
                "Other",
            ],
            'companion' : [
                "Spouse/Partner",
                "Other male family member",
                "Other female family member",
                "Friend",
                "Doula (trained companion)",
                "Other",
            ],
            'attendedby': [
                "Nurse/ Midwife",
                "Doctor/SHO/Resident (Obs)",
                "Consultant Obstetrician (Obs)",
                "Doctor/SHO/Resident (Paed)",
                "Consultant Paeditrician (Paed)",
            ]
        };
    }

    get propertyMapping(){
        return {
            "complicationsMapped" : {type: "Array", apply: false, name: "answer3b1"},
            "partogram" : {type: "String", apply: true, name: "answer3b2"},
            "hadCompanion" : {type: "String", apply: true, name: "answer3b3"},
            "companionMapped" : {type: "Array", apply: false, name: "answer3b4"},
            "othernotes" : {type: "String", apply: true, name: "answer3b5"},
            "attendedbyMapped" : {type: "Array", apply: false, name: "answer3b6"},
            "designation" : {type: "String", apply: true, name: "answer3b7"},
        };
    }
}