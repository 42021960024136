import { qModel } from './qModels';
import * as moment from 'moment';

export class SixA extends qModel{
    dateOfDischarge : moment.Moment = null;
    delivery : string = null;
    maternalDiag : string = null;
    finalOutcome : string = null;
    facility : string = null;
    reason : string = null;
    completedby : string = null;

    getClassName() : string {
        return "Section6a";
    }

    mapforDB() : any {
        return this.removeNullsAndReturnAnswers(this.propertyMapping);
    }
    
    mapforForm(data : any){
        this.dataToForm(this.propertyMapping, data);
    }

    get list() : any {
        return [];
    }

    get propertyMapping(){
        return {
            dateOfDischarge : {type: "Date", apply: true, name: "answer6a1"},
            delivery : {type: "String", apply: true, name: "answer6a2"},
            maternalDiag : {type: "String", apply: true, name: "answer6a3"},
            finalOutcome : {type: "String", apply: true, name: "answer6a4"},
            facility : {type: "String", apply: true, name: "answer6a5"},
            reason : {type: "String", apply: true, name: "answer6a6"},
            completedby : {type: "String", apply: true, name: "answer6a7"},
        };
    }
}