import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { TwoC } from '../models/questions/2c';
import { Validation } from '../validation';
import * as moment from 'moment';

@Component({
  templateUrl: './visit-forms-questions-2c.component.html',
  styleUrls: ['./visit-forms-questions-2c.component.css']
})
export class VisitFormsQuestions2cComponent extends Validation implements OnInit {
  @ViewChild('formTwoC', { read: ElementRef, static: false }) Form;
  public errors: any = {};
  twoc: TwoC;
  uids: any;
  // ancDate : any = {d : null, m: null, y: null};
  year = new Date().getFullYear();
  maxDate = moment().local();

  constructor (
    private http: Http,
    public global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.twoc = new TwoC();
    this.twoc.supplements = new Array();
    this.twoc.medications = new Array();
    this.twoc.drugs = new Array();
  }
  
  ngOnInit() {
    if (!this.global.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
    this.uids = this.global.uidsfq;
    if (JSON.parse(sessionStorage.getItem("twoc_ancVisits")) === null || JSON.parse(sessionStorage.getItem("twoc_ancVisits")) === undefined) {
      sessionStorage.setItem("uids_mid", JSON.stringify(this.uids.mid));
      sessionStorage.setItem("uids_pid", JSON.stringify(this.uids.pid));
      sessionStorage.setItem("uids_vid", JSON.stringify(this.uids.vid));
      for (let i = 0; i < this.twoc.list.supplements.length; i++) {
        console.log(this.twoc.list.supplements[i]);

        this.twoc.supplements.push(false);
      }
      for (let i = 0; i < this.twoc.list.medications.length; i++) {
        console.log(this.twoc.list.medications[i]);
        this.twoc.medications.push(false);
      }
      for (let i = 0; i < this.twoc.list.drugs.length; i++) {
        console.log(this.twoc.list.drugs[i]);
        this.twoc.drugs.push(false);
      }
      this.http
        .get(BASE_URL + "mother/pregnancies/visit/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.twoc.getClassName())
        .subscribe(response => {
          if (Object.keys(response).length > 0) {
            this.twoc.mapforForm(response['answers']);
          } else {
            this.twoc.completedby = this.global.currentUserName;
          }
        });
      this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
        .subscribe(response => {
          if (response['concluded']) this.Form.form.disable();
        });
    } else {
      this.twoc.completedby = JSON.parse(sessionStorage.getItem("twoc_completedby"));
      this.twoc.ancDate = JSON.parse(sessionStorage.getItem("twoc_ancDate"));
      this.twoc.tt = JSON.parse(sessionStorage.getItem("twoc_tt"));
      this.twoc.ancVisits = JSON.parse(sessionStorage.getItem("twoc_ancVisits"));

      this.twoc.bt = JSON.parse(sessionStorage.getItem("twoc_bt"))
      this.twoc.dw = JSON.parse(sessionStorage.getItem("twoc_dw"))
      this.twoc.mp = JSON.parse(sessionStorage.getItem("twoc_mp"))

      this.uids.mid=JSON.parse(sessionStorage.getItem("twoc_uids_mid"));
      this.uids.pid=JSON.parse(sessionStorage.getItem("twoc_uids_pid"));
      this.uids.vid=JSON.parse(sessionStorage.getItem("twoc_uids_vid"));


      this.twoc.supplements = JSON.parse(sessionStorage.getItem("twoc_supplements"));
      for (let i = 0; i < this.twoc.list.supplements.length; i++) {
        console.log(this.twoc.list.supplements[i]);
        this.twoc.supplements.push(this.twoc.list.supplements[i]);
      }

      this.twoc.medications = JSON.parse(sessionStorage.getItem("twoc_medications"));
      for (let i = 0; i < this.twoc.list.medications.length; i++) {
        console.log(this.twoc.list.medications[i]);
        this.twoc.medications.push(false);
      }
      this.twoc.drugs = JSON.parse(sessionStorage.getItem("twoc_drugs"));
      for (let i = 0; i < this.twoc.list.drugs.length; i++) {
        console.log(this.twoc.list.drugs[i]);
        this.twoc.drugs.push(false);
      }
    }
  }
  ngDoCheck() {
    try {
      sessionStorage.setItem("twoc_supplements", JSON.stringify(this.twoc.supplements));
      sessionStorage.setItem("twoc_medications", JSON.stringify(this.twoc.medications));
      sessionStorage.setItem("twoc_drugs", JSON.stringify(this.twoc.drugs));
      sessionStorage.setItem("twoc_completedby", JSON.stringify(this.twoc.completedby));
      sessionStorage.setItem("twoc_ancDate", JSON.stringify(this.twoc.ancDate));
      sessionStorage.setItem("twoc_tt", JSON.stringify(this.twoc.tt));
      sessionStorage.setItem("twoc_ancVisits", JSON.stringify(this.twoc.ancVisits));

      sessionStorage.setItem("twoc_bt", JSON.stringify(this.twoc.bt));
      sessionStorage.setItem("twoc_dw", JSON.stringify(this.twoc.dw));
      sessionStorage.setItem("twoc_mp", JSON.stringify(this.twoc.mp));


      sessionStorage.setItem("twoc_uids_mid", JSON.stringify(this.uids.mid));
      sessionStorage.setItem("twoc_uids_pid", JSON.stringify(this.uids.pid));
      sessionStorage.setItem("twoc_uids_vid", JSON.stringify(this.uids.vid));
    } catch (ex) {
      console.log(ex);
    }
  }

  onSubmit() {
    let question = this.twoc.getClassName()
    let fd = this.twoc.mapforDB();
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      question: question,
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe(response => {
      this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
      this.global.toastToSave();
      sessionStorage.removeItem("twoc_mapforDB");
      sessionStorage.removeItem("twoc_getClassName");
      sessionStorage.removeItem("twoc_mapforDB");
      sessionStorage.removeItem("twoc_getClassName");
      sessionStorage.removeItem("twoc_completedby");
      sessionStorage.removeItem("twoc_ancDate");
      sessionStorage.removeItem("twoc_tt");
      sessionStorage.removeItem("twoc_ancVisits");
      sessionStorage.removeItem("twoc_bt");
      sessionStorage.removeItem("twoc_dw");
      sessionStorage.removeItem("twoc_mp");
      sessionStorage.removeItem("twoc_uids_mid");
      sessionStorage.removeItem("twoc_uids_pid");
      sessionStorage.removeItem("twoc_uids_vid");
    });
  }

  clickCB(id: number, type: string) {
    console.log(id, type);
    this.twoc[type][id] = !this.twoc[type][id];
  }

  iterator(end: number, start: number = 0, increment = 1): number[] {
    console.log(end, start, increment);
    return this.global.iterateNumbers(end, start, increment);
  }
  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation.incorrect === false){
  //     options.form.controls[options.name].setErrors(null);
  //     this.errors[options.name] = null;
  //   }else if(validation.incorrect === true){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //     this.errors[options.name] = validation.message;
  //   }
  // }
}
