import { qModel } from './qModels';

export class TwoG extends qModel{
    bp : object = {systolic : null, diastolic : null};
    bpRepeat : object = {systolic : null, diastolic : null};
    pulse : number = null;
    resp : number = null;
    temp : number = null;
    gcs : number = null;
    pain : number = null;
    weight : number = null;
    height : number = null;
    genotes : string = null;
    abdScar : string = null;
    utrineTender : string = null;
    palpable : string = null;
    sfh : string = null;
    lie : string = null;
    fhr1 : number = null;
    fhr2 : number = null;
    fhr3 : number = null;
    fhr4 : number = null;
    pelvicExam : boolean[] = [];
    pelvicExamMapped : string[] = [];
    vagBleeding : string = null;
    liquor : string = null;
    cervicalDialation : number = null;
    otherExamination : string = null;
    admitted : string = null;
    completedBy : string = null;

    getClassName() : string {
        return "Section2g";
    }

    mapforDB() : any {
        this.pelvicExamMapped = this.mapChecksToList(this.list, this.pelvicExam).join(",");
        return this.removeNullsAndReturnAnswers(this.propertyMapping);
    }
    
    mapforForm(data : any){
        this.dataToForm(this.propertyMapping, data);
        let pelv = this.mapListToChecks(this.list, (typeof data['answer2g19'] === 'undefined' ? null :data['answer2g19'].split(',')), null);
        this.pelvicExam = pelv.checks;
    }

    get list() : any {
        return [
            "Not done",
            "Digital vaginal exam done",
            "Speculum exam done",
        ];
    }

    get propertyMapping(){
        return {
            "bp" : {type: "Object", glue : "/", apply: false, name: "answer2g1", mapTo: ['systolic', 'diastolic']},
            "bpRepeat" : {type: "Object", glue : "/", apply: false, name: "answer2g2", mapTo: ['systolic', 'diastolic']},
            "pulse" : {type: "String", apply: true, name: "answer2g3"},
            "resp" : {type: "String", apply: true, name: "answer2g4"},
            "temp" : {type: "String", apply: true, name: "answer2g5"},
            "gcs" : {type: "String", apply: true, name: "answer2g6"},
            "pain" : {type: "String", apply: true, name: "answer2g7"},
            "weight" : {type: "String", apply: true, name: "answer2g8"},
            "height" : {type: "String", apply: true, name: "answer2g9"},
            "bmi" : {type: "String", apply: true, name: "answer2g10"},
            "genotes" : {type: "String", apply: true, name: "answer2g11"},
            "abdScar" : {type: "String", apply: true, name: "answer2g12"},
            "utrineTender" : {type: "String", apply: true, name: "answer2g13"},
            "palpable" :  {type: "String", apply: true, name: "answer2g14"},
            "sfh" : {type: "String", apply: true, name: "answer2g15"},
            "lie" : {type: "String", apply: true, name: "answer2g16"},
            "fhr1" : {type: "String", apply: true, name: "answer2g17"},
            "fhr2" : {type: "String", apply: true, name: "answer2g18"},
            "fhr3" : {type: "String", apply: true, name: "answer2g26"},
            "fhr4" : {type: "String", apply: true, name: "answer2g27"},
            "pelvicExamMapped" : {type: "Array", apply: false, name: "answer2g19"},
            "vagBleeding" : {type: "String", apply: true, name: "answer2g20"},
            "liquor" : {type: "String", apply: true, name: "answer2g21"},
            "cervicalDialation" : {type: "String", apply: true, name: "answer2g22"},
            "otherExamination" : {type: "String", apply: true, name: "answer2g23"},
            "admitted" : {type: "String", apply: true, name: "answer2g24"},
            "completedBy" : {type: "String", apply: true, name: "answer2g25"},
        };
    }

    get bmi(){
        if(this.height === null || this.weight === null){
            return null;
        }
        return (this.weight/(Math.pow(this.height/100, 2))).toFixed(1);
    }
}