import { qModel } from './qModels';

export class TwoB extends qModel{
    firstPregnancy : string = null;
    numberChildrenAlive : string = null;
    totalPregnancy : string = null;
    pregGT28W : string = null;
    pregLT28W : string = null;
    pregCSec : string = null;
    complications : boolean[] = [];
    mappedComplications : string[] = [];
    bornalive : string = null;
    neonatal : string = null;
    infantdeath : string = null;
    stillborn : string = null;
    miscarriage : string = null;
    ectopic : string = null;
    termination : string = null;
    other : string = null;
    notes : string = null;
    completedby : string = null;

    getClassName() : string {
        return "Section2b";
    }

    mapforDB() : any {
        this.mappedComplications = this.mapChecksToList(this.list, this.complications, this.other).join(',');
        return this.removeNullsAndReturnAnswers(this.propertyMapping);
    }
    
    mapforForm(data : any){
        let checks;
        this.dataToForm(this.propertyMapping, data);
        for(let item in this.propertyMapping){
            if(this.propertyMapping[item].type === 'Array'){
                checks = this.propertyMapping[item].name;
            }
        }
        let returns = this.mapListToChecks(this.list, data[checks].split(','), 'other');
        this.complications = returns.checks;
        for(let i in returns){
            if(i !== "checks"){
                this[i] = returns[i];
            }
        }
    }

    get list() : any {
        return [
            "None",
            "Hypertension in Pregnancy",
            "Eclampsia",
            "Placental abruption",
            "Antepartum haemorrhage unknown cause",
            "Preterm prelabour rupture of membranes (pPROM)",
            "Prelabour rupture of membranes (PROM) at term",
            "Fetal malpresentation",
            "Arrest in labour",
            "Ruptured uterus",
            "Anaemia",
            "Postpartum Infection",
            "Mental health problems",
            "Preeclampsia",
            "Multiple gestation",
            "Placenta praevia?",
            "Preterm delivery (less than 37 weeks)",
            "Cord prolapse",
            "Shoulder dystocia",
            "Diabetes in pregnancy",
            "Postpartum haemorrhage",
            "Previous baby more than 4.5kg at birth",
            "Other",
        ];
        
    }

    get propertyMapping(){
        return {
            "firstPregnancy": {type: "String", apply: true, name: "answer2b1"},
            "numberChildrenAlive": {type: "String", apply: true, name: "answer2b2"},
            "totalPregnancy": {type: "String", apply: true, name: "answer2b3"},
            "pregGT28W": {type: "String", apply: true, name: "answer2b4"},
            "bornalive" : {type : 'String', apply: true, name: 'answer2b5'},
            "neonatal" : {type : 'String', apply: true, name: 'answer2b6'},
            "infantdeath" : {type : 'String', apply: true, name: 'answer2b7'},
            "stillborn" : {type : 'String', apply: true, name: 'answer2b8'},
            "pregLT28W": {type: "String", apply: true, name: "answer2b9"},
            "miscarriage" : {type : 'String', apply: true, name: 'answer2b10'},
            "termination" : {type : 'String', apply: true, name: 'answer2b11'},
            "ectopic" : {type : 'String', apply: true, name: 'answer2b12'},
            "pregCSec": {type: "String", apply: true, name: "answer2b13"},
            "mappedComplications": {type: "Array", apply: false, name: "answer2b14"},
            "notes": {type: "String", apply: true, name: "answer2b15"},
            "completedby": {type: "String", apply: true, name: "answer2b16"},
        };
    }
}