import { Component, OnInit } from '@angular/core';
import * as Chartist from "chartist";
import * as CLegend from "assets/chartist/chartist.legend"
import * as CPoints from "assets/chartist/chartist.pointlabels";
import * as CFDoughnut from 'assets/chartist/chartist.fill-donut';
import * as CTooltip from 'assets/chartist/chartist-plugin-tooltip';
import * as CAxisTitle from 'assets/chartist/chartist-plugin-axistitle';
import * as moment from 'moment';
import * as jQ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { HttpHeadered as Http } from 'app/services/httpHeadered';
import { baseUrl, GlobalFunctions } from '../global';

@Component({
  selector: 'app-delivery-outcome',
  templateUrl: './delivery-outcome.component.html',
  styleUrls: ['./delivery-outcome.component.css']
})
export class DeliveryOutcomeComponent implements OnInit {
  
  deliveryoutcome = {nnd : null, nni: []};
  private params = {};

  constructor(
    private aRoute : ActivatedRoute,
    private http : Http,
    private gFunc : GlobalFunctions,
  ) { }

  ngOnInit() {
    this.aRoute.params.subscribe(params => {
      let smonth = moment(params.startm, "MMMM").format('MM');
      let emonth = moment(params.endm, "MMMM").format('MM');
      this.params = {
        facility: params.facility,
        start: {
          year: params.starty,
          month: smonth,
        },
        end: {
          year: params.endy,
          month: emonth,
        }
      };
      let url = [
        baseUrl,
        "report-v2",
        "deliveryoutcome",
        params.facility,
        params.starty,
        smonth,
        params.endy,
        emonth,
      ].join('/');
      this.http.get(url).subscribe(response => {
        let data = response;
        this.deliveryoutcome.nnd = data['nnd'];
        let v1 = data['gad'];
        let gad = new Chartist.Bar("#gad", 
          {
            labels: v1['labels'],
            series: [v1['series']],
          },
          {
            height: "350px",
            axisY: {
              onlyInteger: true
            },
            seriesBarDistance: 20,
            plugins: [
              CPoints({
                labelOffset: {
                  x: 0,
                  y: -10
                },
              }),
              CAxisTitle({
                axisY:{
                  axisTitle: "Percentage (%)",
                  textAnchor: 'middle',
                  flipTitle: true,
                  offset: {
                    x: 0,
                    y: 15,
                  }
                },
              })
            ],
          }
        );
        gad.on('draw', (data) => {
          if(data.type == "bar"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("deliveryoutcome", "gad", item, this.params);
            });
          };
        });
        let t = data['nbo_1'];
        let u = data['nbo_2'];
        let nbolive = new Chartist.Bar("#nbolive", 
          {
            labels:[
              "Live Births and Stillbirths",
              "Sex",
            ],
            series:[
              {
                name: t.labels[0],
                data:[
                  t.series[0],null
                ]
              },
              {
                name: t.labels[1],
                data:[
                  t.series[1], null
                ]
              },
              {
                name: u.labels[0],
                data:[
                  null,u.series[0]
                ]
              },
              {
                name: u.labels[1],
                data:[
                  null,u.series[1]
                ]
              }
            ]
          },
          {
            height: "350px",
            axisY: {
              onlyInteger: true
            },
            stackBars: true,
            seriesBarDistance: 20,
            plugins: [
              CLegend({position: 'bottom'}),
              CPoints({
                labelOffset: {
                  x: 0,
                  y: -10
                },
              }),
              CAxisTitle({
                axisY:{
                  axisTitle: "Percentage (%)",
                  textAnchor: 'middle',
                  flipTitle: true,
                  offset: {
                    x: 0,
                    y: 15,
                  }
                },
              })
            ],
          }
        );
        nbolive.on('draw', (data) => {
          if(data.type == "bar"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("deliveryoutcome", "nbolive", item, this.params);
            });
          };
        });
        let v3 = data['weight'];
        let tot_bw = (v3['series']).reduce(this.gFunc.sum);
        let birthweight = new Chartist.Pie("#birthweight", 
          v3,
          {
            height: "350px",
            plugins: [CLegend({position: 'bottom'})],
            labelInterpolationFnc: function(name, key) {
              let value = v3['series'][key];
              if(value['value'] == 0) return "";
              return value['value']+"   (" + Math.round(value['value'] / tot_bw * 100) + "%)";
            }
          }
        );
        birthweight.on('draw', (data) => {
          if(data.type == "slice"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("deliveryoutcome", "birthweight", item, this.params);
            });
          };
        });
        let tot_apgar = (data['apgar']["series"]).reduce(this.gFunc.sum);
        let v4 = data['apgar'];
        let apgar5mscores = new Chartist.Pie("#apgar5mscores", 
          v4,
          {
            height: "350px",
            plugins: [CLegend({position: 'bottom'})],
            labelInterpolationFnc: function(name, key) {
              let value = v4['series'][key];
              if(value['value'] == 0) return "";
              return value['value']+"   (" + Math.round(value['value'] / tot_apgar * 100) + "%)";
            }
          }
        );
        apgar5mscores.on('draw', (data) => {
          if(data.type == "slice"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("deliveryoutcome", "apgar5", item, this.params);
            });
          };
        });
        this.deliveryoutcome.nni = data['nni'];
      })
    });
  }

  fillRandData(){
    for(let x in this.deliveryoutcome){
      if(this.deliveryoutcome[x] === null){
        this.deliveryoutcome[x] = Math.ceil((Math.random() * (1500 - 500)) + 500);
      }else{
        for (let y in this.deliveryoutcome[x]){
          this.deliveryoutcome[x][y] = Math.ceil((Math.random() * (1500 - 500)) + 500);
        }
      }
    }
  }

  tableCellClick(data, row, col){
    let rw = (data[row].name).replace(/[^\w]/ig, '_').toLowerCase();
    this.gFunc.chartNavigate("deliveryoutcome", "interventions", rw, this.params);
  }
}
