import { Component, OnInit } from '@angular/core';
import * as Chartist from "chartist";
import * as CLegend from "assets/chartist/chartist.legend"
import * as CPoints from "assets/chartist/chartist.pointlabels";
import * as CFDoughnut from 'assets/chartist/chartist.fill-donut';
import * as CTooltip from 'assets/chartist/chartist-plugin-tooltip';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { HttpHeadered as Http } from 'app/services/httpHeadered';
import * as jQ from 'jquery';
import { baseUrl, GlobalFunctions } from "../global";
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-admissions-report',
  templateUrl: './admissions-report.component.html',
  styleUrls: ['./admissions-report.component.css']
})
export class AdmissionsReportComponent implements OnInit {

  admissions : any;
  tableSource : any;
  private params : any;

  constructor(
    private aRoute : ActivatedRoute,
    private http : Http,
    private gFunc : GlobalFunctions,
  ) {
    this.admissions = {};
    this.tableSource = {};
    this.tableSource['duration'] = {};
    this.tableSource['finaloutcome'] = {};
  }

  ngOnInit() {
    this.aRoute.params.subscribe( params => {
      let smonth = moment(params.startm, "MMMM").format('MM');
      let emonth = moment(params.endm, "MMMM").format('MM');
      this.params = {
        facility: params.facility,
        start: {
          year: params.starty,
          month: smonth,
        },
        end: {
          year: params.endy,
          month: emonth,
        }
      };
      let url = [
        baseUrl,
        "report-v2",
        "admissions",
        params.facility,
        params.starty,
        smonth,
        params.endy,
        emonth,
      ].join('/');
      this.http.get(url).subscribe(response => {
        let data = response;
        /* SINGLES */
        this.admissions['total_admissions'] = data['total_admissions'];
        this.admissions['total_admissions_w_delivery'] = data['total_admissions_w_delivery'];
        this.admissions['referrals'] = data['referrals'];
        this.admissions['emergency_referrals'] = data['emergency_referrals'];
        this.admissions['repeat_admissions'] = data['repeat_admissions'];
        this.tableSource['duration']= data['stay_duration'];
        this.tableSource['finaloutcome']= data['mother_outcome'];
        /* TRAVEL TIME */
        let tot_traveltime = (data['travel_time']["series"]).reduce(this.gFunc.sum);
        let v0 = data['travel_time'];
        let traveltime = new Chartist.Pie("#traveltime", 
          v0,
          {
            height: "350px",
            donut: true,
            donutWidth: 100,
            donutSolid: true,
            startAngle: 0,
            plugins: [CLegend({position: 'bottom'})],
            labelInterpolationFnc: function(name, key) {
              let value = v0['series'][key];
              if(value['value'] == 0) return "";
              return value['value']+"   (" + Math.round(value['value'] / tot_traveltime * 100) + "%)";
            }
          }
        );
        traveltime.on('draw', (data) => {
          if(data.type == "slice"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("admissions", "traveltime", item, this.params);
            });
          };
        });
        /* MOTHER ADMISSION wrt LABOR */
        let tot_motheradm = (data['wrt_labour']["series"]).reduce(this.gFunc.sum);
        let v1 = data['wrt_labour'];
        let motheradm = new Chartist.Pie("#statuswrtlabour", 
          v1,
          {
            height: "350px",
            donut: true,
            donutWidth: 100,
            donutSolid: true,
            startAngle: 0,
            plugins: [CLegend({position: 'bottom'})],
            labelInterpolationFnc: function(name, key) {
              let value = v1['series'][key];
              if(value['value'] == 0) return "";
              return value['value']+"   (" + Math.round(value['value'] / tot_motheradm * 100) + "%)";
            }
          }
        );
        motheradm.on('draw', (data) => {
          if(data.type == "slice"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("admissions", "wrtlabour", item, this.params);
            });
          };
        });
        let tot_finaloutcomebaby = (data['baby_outcome']["series"]).reduce(this.gFunc.sum);
        let v2 = data['baby_outcome'];
        let finaloutcomebaby = new Chartist.Pie("#finaloutcomebaby", 
          v2,
          {
            height: "350px",
            donut: true,
            donutWidth: 100,
            donutSolid: true,
            startAngle: 0,
            plugins: [CLegend({position: 'bottom'})],
            labelInterpolationFnc: function(name, key) {
              let value = v2['series'][key];
              if(value['value'] == 0) return "";
              return value['value']+"   (" + Math.round(value['value'] / tot_finaloutcomebaby * 100) + "%)";
            }
          }
        );
        finaloutcomebaby.on('draw', (data) => {
          if(data.type == "slice"){
            jQ(data.element._node).on("click", ($event)=>{
              let item = <string>data.meta;
              this.gFunc.chartNavigate("admissions", "babyoutcome", item, this.params);
            });
          };
        });
      });
    });
  }

  openDialog(item){
    this.gFunc.chartNavigate("admissions", 'numeric', item, this.params);
  }

  tableCellClick(type, data, row, col){
    this.gFunc.tableCellClick('admissions', type, data, row, col, this.params);
  }
}
