import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { TwoB } from '../models/questions/2b';
import { Validation } from '../validation';

@Component({
  templateUrl: './visit-forms-questions-2b.component.html',
  styleUrls: ['./visit-forms-questions-2b.component.css']
})
export class VisitFormsQuestions2bComponent extends Validation implements OnInit {
  @ViewChild('formTwoB', { read: ElementRef, static: false }) Form;
  public errors: any = {};
  public twob: TwoB;
  public list: string[];
  public pc: boolean[];
  private uids: any;

  constructor (
    private http: Http,
    private global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
  }
  ngOnInit() {
    this.uids = this.global.uidsfq;
    try {
      this.twob = new TwoB();
      this.twob.complications = [];
      if (JSON.parse(sessionStorage.getItem("twob_firstPregnancy")) === null) {
        if (!this.global.isLoggedIn()) {
          this.router.navigate(['/login']);
        }
        for (let i = 0; i < this.twob.list.length; i++) {
          this.twob.complications.push(false);
        }
        this.http
          .get(BASE_URL + "mother/pregnancies/visit/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.twob.getClassName())
          .subscribe(response => {
            if (Object.keys(response).length > 0) {
              this.twob.mapforForm(response['answers']);
            } else {
              this.twob.completedby = this.global.currentUserName;
            }
          });
        this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
          .subscribe(response => {
            if (response['concluded']) this.Form.form.disable();
          });
      } else {
        this.twob.firstPregnancy = JSON.parse(sessionStorage.getItem("twob_firstPregnancy"));
        this.twob.numberChildrenAlive = JSON.parse(sessionStorage.getItem("twob_numberChildrenAlive"));
        this.twob.totalPregnancy = JSON.parse(sessionStorage.getItem("twob_totalPregnancy"));
        this.twob.pregGT28W = JSON.parse(sessionStorage.getItem("twob_pregGT28W"));
        this.twob.bornalive = JSON.parse(sessionStorage.getItem("twob_bornalive"));
        this.twob.neonatal = JSON.parse(sessionStorage.getItem("twob_neonatal"));
        this.twob.infantdeath = JSON.parse(sessionStorage.getItem("twob_infantdeath"));
        this.twob.stillborn = JSON.parse(sessionStorage.getItem("twob_stillborn"));
        this.twob.pregLT28W = JSON.parse(sessionStorage.getItem("twob_pregLT28W"));
        this.twob.miscarriage = JSON.parse(sessionStorage.getItem("twob_miscarriage"));
        this.twob.termination = JSON.parse(sessionStorage.getItem("twob_termination"));
        this.twob.ectopic = JSON.parse(sessionStorage.getItem("twob_ectopic"));
        this.twob.pregCSec = JSON.parse(sessionStorage.getItem("twob_pregCSec"));
        this.twob.other = JSON.parse(sessionStorage.getItem("twob_other"));
        this.twob.notes = JSON.parse(sessionStorage.getItem("twob_notes"));
        this.twob.completedby = JSON.parse(sessionStorage.getItem("twob_completedby"));
        this.twob.complications = JSON.parse(sessionStorage.getItem("twob_complications"));
      }
    } catch (ex) {
      console.log(ex);
    }

  }

  ngDoCheck() {
    try {
      sessionStorage.setItem("twob_firstPregnancy", JSON.stringify(this.twob.firstPregnancy))
      sessionStorage.setItem("twob_numberChildrenAlive", JSON.stringify(this.twob.numberChildrenAlive))
      sessionStorage.setItem("twob_totalPregnancy", JSON.stringify(this.twob.totalPregnancy))
      sessionStorage.setItem("twob_pregGT28W", JSON.stringify(this.twob.pregGT28W))
      sessionStorage.setItem("twob_bornalive", JSON.stringify(this.twob.bornalive))
      sessionStorage.setItem("twob_neonatal", JSON.stringify(this.twob.neonatal))
      sessionStorage.setItem("twob_infantdeath", JSON.stringify(this.twob.infantdeath))
      sessionStorage.setItem("twob_stillborn", JSON.stringify(this.twob.stillborn))
      sessionStorage.setItem("twob_pregLT28W", JSON.stringify(this.twob.pregLT28W))
      sessionStorage.setItem("twob_miscarriage", JSON.stringify(this.twob.miscarriage))
      sessionStorage.setItem("twob_termination", JSON.stringify(this.twob.termination))
      sessionStorage.setItem("twob_ectopic", JSON.stringify(this.twob.ectopic))
      sessionStorage.setItem("twob_pregCSec", JSON.stringify(this.twob.pregCSec))
      sessionStorage.setItem("twob_complications", JSON.stringify(this.twob.complications))
      sessionStorage.setItem("twob_other", JSON.stringify(this.twob.other))
      sessionStorage.setItem("twob_notes", JSON.stringify(this.twob.notes))
      sessionStorage.setItem("twob_completedby", JSON.stringify(this.twob.completedby))
    } catch (ex) {
      console.log(ex);
    }
  }

  onSubmit() {
    let question;
      question = this.twob.getClassName()
    let fd = this.twob.mapforDB();
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      question: question,
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe(response => {
      this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
      this.global.toastToSave();
      sessionStorage.setItem("twob_firstPregnancy",null);
      sessionStorage.removeItem("twob_numberChildrenAlive");
      sessionStorage.removeItem("twob_totalPregnancy");
      sessionStorage.removeItem("twob_pregGT28W");
      sessionStorage.removeItem("twob_bornalive");
      sessionStorage.removeItem("twob_neonatal");
      sessionStorage.removeItem("twob_infantdeath");
      sessionStorage.removeItem("twob_stillborn");
      sessionStorage.removeItem("twob_pregLT28W");
      sessionStorage.removeItem("twob_miscarriage");
      sessionStorage.removeItem("twob_termination");
      sessionStorage.removeItem("twob_ectopic");
      sessionStorage.removeItem("twob_pregCSec");
      sessionStorage.removeItem("twob_complications");
      sessionStorage.removeItem("twob_other");
      sessionStorage.removeItem("twob_notes");
      sessionStorage.removeItem("twob_completedby");
    });
  }

  clickCB(id: number) {
    this.twob.complications[id] = !this.twob.complications[id];
  }

  iterator(end: number, start: number = 0, increment = 1): number[] {
    return this.global.iterateNumbers(end, start, increment);
  }
}
