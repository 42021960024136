import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { ThreeB } from '../models/questions/3b';
import { Validation } from '../validation';

@Component({
  selector: 'app-visit-forms-questions-3b',
  templateUrl: './visit-forms-questions-3b.component.html',
  styleUrls: ['./visit-forms-questions-3b.component.css']
})
export class VisitFormsQuestions3bComponent extends Validation implements OnInit {
  @ViewChild('formThreeB', { read: ElementRef, static: false }) Form;
  threeb: ThreeB;
  uids: any;

  constructor (
    private http: Http,
    private global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.threeb = new ThreeB();
  }

  ngOnInit() {
    try {
      this.uids = this.global.uidsfq;
      sessionStorage.setItem("threeb_uids_mid", JSON.stringify(this.uids.mid));
      sessionStorage.setItem("threeb_uids_pid", JSON.stringify(this.uids.pid));
      sessionStorage.setItem("threeb_uids_vid", JSON.stringify(this.uids.vid));
      if (!this.global.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
      console.log();
      console.log();
      if (
        (JSON.parse(sessionStorage.getItem("threeb_othernotes")) == 'No Entry' &&
          JSON.parse(sessionStorage.getItem("threeb_partogram")) == "Yes" &&
          JSON.parse(sessionStorage.getItem("threeb_hadCompanion")) == "No" &&
          JSON.parse(sessionStorage.getItem("threeb_attendedby"))[0] == true &&
          JSON.parse(sessionStorage.getItem("threeb_attendedby"))[2] == true) ||
        ((JSON.parse(sessionStorage.getItem("threeb_othernotes")) == null &&
          JSON.parse(sessionStorage.getItem("threeb_partogram")) == null &&
          JSON.parse(sessionStorage.getItem("threeb_hadCompanion")) == null))
      ) {

        for (let i = 0; i < this.threeb.list.companion.length; i++) {
          this.threeb.companion.push(false);
        }
        for (let i = 0; i < this.threeb.list.complications.length; i++) {
          this.threeb.complications.push(false);
        }
        this.http
          .get(BASE_URL + "mother/pregnancies/visit/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.threeb.getClassName())
          .subscribe(response => {
            if (Object.keys(response).length > 0) {
              this.threeb.mapforForm(response['answers']);
            } else {
              this.threeb.designation = this.global.currentUserName;
            }
          });
        this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
          .subscribe(response => {
            if (response['concluded']) this.Form.form.disable();
          });
        sessionStorage.setItem("threeb_uids_mid", JSON.stringify(this.uids.mid));
        sessionStorage.setItem("threeb_uids_pid", JSON.stringify(this.uids.pid));
        sessionStorage.setItem("threeb_uids_vid", JSON.stringify(this.uids.vid));
      } else {
        this.uids.mid = JSON.parse(sessionStorage.getItem("threeb_uids_mid"))
        this.uids.pid = JSON.parse(sessionStorage.getItem("threeb_uids_pid"))
        this.uids.vid = JSON.parse(sessionStorage.getItem("threeb_uids_vid"))
        this.threeb.complications = JSON.parse(sessionStorage.getItem("threeb_complications"))
        this.threeb.other.complications = JSON.parse(sessionStorage.getItem("threeb_other_complications"))
        this.threeb.partogram = JSON.parse(sessionStorage.getItem("threeb_partogram"))
        this.threeb.hadCompanion = JSON.parse(sessionStorage.getItem("threeb_hadCompanion"))
        this.threeb.companion = JSON.parse(sessionStorage.getItem("threeb_companion"))
        this.threeb.other.companion = JSON.parse(sessionStorage.getItem("threeb_other_companion"))
        this.threeb.othernotes = JSON.parse(sessionStorage.getItem("threeb_othernotes"))
        this.threeb.attendedby = JSON.parse(sessionStorage.getItem("threeb_attendedby"))
        this.threeb.designation = JSON.parse(sessionStorage.getItem("threeb_designation"))
      }
    } catch (ex) {
      console.log(ex);
    }

  }

  clickCB(id: number, type: string) {
    this.threeb[type][id] = !this.threeb[type][id];
  }



  ngDoCheck() {
    try {
      sessionStorage.setItem("threeb_complications", JSON.stringify(this.threeb.complications))
      sessionStorage.setItem("threeb_other_complications", JSON.stringify(this.threeb.other.complications))
      sessionStorage.setItem("threeb_partogram", JSON.stringify(this.threeb.partogram))
      sessionStorage.setItem("threeb_hadCompanion", JSON.stringify(this.threeb.hadCompanion))
      sessionStorage.setItem("threeb_companion", JSON.stringify(this.threeb.companion))
      sessionStorage.setItem("threeb_other_companion", JSON.stringify(this.threeb.other.companion))
      sessionStorage.setItem("threeb_othernotes", JSON.stringify(this.threeb.othernotes))
      sessionStorage.setItem("threeb_attendedby", JSON.stringify(this.threeb.attendedby))
      sessionStorage.setItem("threeb_designation", JSON.stringify(this.threeb.designation))
    } catch (ex) {
      console.log(ex);
    }
  }

  onSubmit() {
    let fd = this.threeb.mapforDB();
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      question: this.threeb.getClassName(),
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe(response => {
      this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
      this.global.toastToSave();
      sessionStorage.removeItem("threeb_complications");
      sessionStorage.removeItem("threeb_other_complications");
      sessionStorage.removeItem("threeb_partogram");
      sessionStorage.removeItem("threeb_hadCompanion");
      sessionStorage.removeItem("threeb_companion");
      sessionStorage.removeItem("threeb_other_companion");
      sessionStorage.removeItem("threeb_othernotes");
      sessionStorage.removeItem("threeb_attendedby");
      sessionStorage.removeItem("threeb_designation");
    });
  }

  iterator(end: number, start: number = 0, increment = 1): number[] {
    return this.global.iterateNumbers(end, start, increment);
  }

  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation === true){
  //     options.form.controls[options.name].setErrors(null);
  //   }else if(validation === false){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //   }
  // }
}
