import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { FourA } from 'app/models/questions/4a';
import { FourC } from 'app/models/questions/4c';
import { Validation } from 'app/validation';
import * as moment from 'moment';

@Component({
  selector: 'app-visit-forms-questions-4a',
  templateUrl: './visit-forms-questions-4a.component.html',
  styleUrls: ['./visit-forms-questions-4a.component.css']
})
export class VisitFormsQuestions4aComponent extends Validation implements OnInit {
  @ViewChild('formFourA', { read: ElementRef, static: false }) Form;
  foura: FourA;
  uids: any;
  fourc: FourC;
  bid: number = null;
  year = new Date().getFullYear();
  maxDate = moment().local();

  constructor (
    private http: Http,
    public global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.foura = new FourA();
    this.fourc = new FourC();
    this.aRoute.params.subscribe(params => {
      this.bid = (typeof params.id === "undefined" ? null : params.id);
    });
  }

  ngOnInit() {
    try {
      this.uids = this.global.uidsfq;
      sessionStorage.setItem("foura_uids_mid", JSON.stringify(this.uids.mid));
      sessionStorage.setItem("foura_uids_pid", JSON.stringify(this.uids.pid));
      sessionStorage.setItem("foura_uids_vid", JSON.stringify(this.uids.vid));
      if (!this.global.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
      if ((typeof JSON.parse(sessionStorage.getItem("foura_completedBy")) == 'string' &&
        JSON.parse(sessionStorage.getItem("foura_nni")) == "No" && JSON.parse(sessionStorage.getItem("foura_bornAlive")) == null && JSON.parse(sessionStorage.getItem("foura_sex")) == null) || (JSON.parse(sessionStorage.getItem("foura_completedBy")) == null &&
          JSON.parse(sessionStorage.getItem("foura_nni")) == null)) {
        for (let i = 0; i < this.foura.list.length; i++) {
          this.foura.resuscitation.push(false);
        }
        for (let i = 0; i < this.fourc.list.length; i++) {
          this.fourc.anomalies.push(false);
        }
        this.http
          .get(BASE_URL + "baby/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.bid + "/" + this.foura.getClassName())
          .subscribe(response => {
            if (Object.keys(response).length > 0) {
              this.foura.mapforForm(response['answers']);
            } else {
              this.foura.completedBy = this.global.currentUserName;
            }
          });
        this.http
          .get(BASE_URL + "baby/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.bid + "/" + this.fourc.getClassName())
          .subscribe(response => {
            if (Object.keys(response).length > 0) {
              this.fourc.mapforForm(response['answers']);
            }
          });
        this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
          .subscribe(response => {
            if (response['concluded']) this.Form.form.disable();
          });
        sessionStorage.setItem("foura_uids_mid", JSON.stringify(this.uids.mid));
        sessionStorage.setItem("foura_uids_pid", JSON.stringify(this.uids.pid));
        sessionStorage.setItem("foura_uids_vid", JSON.stringify(this.uids.vid));
      } else {
        this.uids.mid = JSON.parse(sessionStorage.getItem("foura_uids_mid"))
        this.uids.pid = JSON.parse(sessionStorage.getItem("foura_uids_pid"))
        this.uids.vid = JSON.parse(sessionStorage.getItem("foura_uids_vid"))


        this.foura.dateOfBirth = JSON.parse(sessionStorage.getItem("foura_dateOfBirth"))
        this.foura.timeOfBirth["mm"] = JSON.parse(sessionStorage.getItem("foura_timeOfBirth_mm"))
        this.foura.timeOfBirth["hh"] = JSON.parse(sessionStorage.getItem("foura_timeOfBirth_hh"))
        this.foura.bornAlive = JSON.parse(sessionStorage.getItem("foura_bornAlive"))
        this.foura.hospitalId = JSON.parse(sessionStorage.getItem("foura_hospitalId"))
        this.foura.sex = JSON.parse(sessionStorage.getItem("foura_sex"))
        this.foura.apgar1 = JSON.parse(sessionStorage.getItem("foura_apgar1"))
        this.foura.apgar5 = JSON.parse(sessionStorage.getItem("foura_apgar5"))
        this.foura.apgar10 = JSON.parse(sessionStorage.getItem("foura_apgar10"))
        this.foura.weight = JSON.parse(sessionStorage.getItem("foura_weight"))
        this.foura.length = JSON.parse(sessionStorage.getItem("foura_length"))
        this.foura.headC = JSON.parse(sessionStorage.getItem("foura_headC"))
        this.foura.nni = JSON.parse(sessionStorage.getItem("foura_nni"))
        this.foura.resuscitation = JSON.parse(sessionStorage.getItem("foura_resuscitation"))
        this.foura.s2scontact = JSON.parse(sessionStorage.getItem("foura_s2scontact"))
        this.foura.delayedCC = JSON.parse(sessionStorage.getItem("foura_delayedCC"))
        this.foura.breastfed = JSON.parse(sessionStorage.getItem("foura_breastfed"))
        this.foura.eyecare = JSON.parse(sessionStorage.getItem("foura_eyecare"))
        this.foura.vitK = JSON.parse(sessionStorage.getItem("foura_vitK"))
        this.foura.other = JSON.parse(sessionStorage.getItem("foura_other"))
        this.foura.death = JSON.parse(sessionStorage.getItem("foura_death"))
        this.foura.transferred = JSON.parse(sessionStorage.getItem("foura_transferred"))
        this.foura.interventions = JSON.parse(sessionStorage.getItem("foura_interventions"))
        this.foura.referral = JSON.parse(sessionStorage.getItem("foura_referral"))
        this.foura.otherNotes = JSON.parse(sessionStorage.getItem("foura_otherNotes"))
        this.foura.designation = JSON.parse(sessionStorage.getItem("foura_designation"))
        this.foura.completedBy = JSON.parse(sessionStorage.getItem("foura_completedBy"))
        this.fourc.birthId = JSON.parse(sessionStorage.getItem("fourc_birthId"))
        this.fourc.skinBroken = JSON.parse(sessionStorage.getItem("fourc_skinBroken"))
        this.fourc.anyAnomalies = JSON.parse(sessionStorage.getItem("fourc_anyAnomalies"))
        this.fourc.anomalies = JSON.parse(sessionStorage.getItem("fourc_anomalies"))
        this.fourc.notes = JSON.parse(sessionStorage.getItem("fourc_notes"))

      }
    } catch (ex) {
      console.log(ex);

    }


  }

  ngDoCheck() {
    try {
      sessionStorage.setItem("foura_dateOfBirth", JSON.stringify(this.foura.dateOfBirth))
      sessionStorage.setItem("foura_timeOfBirth_mm", JSON.stringify(this.foura.timeOfBirth["mm"]))
      sessionStorage.setItem("foura_timeOfBirth_hh", JSON.stringify(this.foura.timeOfBirth["hh"]))
      sessionStorage.setItem("foura_bornAlive", JSON.stringify(this.foura.bornAlive))
      sessionStorage.setItem("foura_hospitalId", JSON.stringify(this.foura.hospitalId))
      sessionStorage.setItem("foura_sex", JSON.stringify(this.foura.sex))
      sessionStorage.setItem("foura_apgar1", JSON.stringify(this.foura.apgar1))
      sessionStorage.setItem("foura_apgar5", JSON.stringify(this.foura.apgar5))
      sessionStorage.setItem("foura_apgar10", JSON.stringify(this.foura.apgar10))
      sessionStorage.setItem("foura_weight", JSON.stringify(this.foura.weight))
      sessionStorage.setItem("foura_length", JSON.stringify(this.foura.length))
      sessionStorage.setItem("foura_headC", JSON.stringify(this.foura.headC))
      sessionStorage.setItem("foura_nni", JSON.stringify(this.foura.nni))
      sessionStorage.setItem("foura_resuscitation", JSON.stringify(this.foura.resuscitation))
      sessionStorage.setItem("foura_s2scontact", JSON.stringify(this.foura.s2scontact))
      sessionStorage.setItem("foura_delayedCC", JSON.stringify(this.foura.delayedCC))
      sessionStorage.setItem("foura_breastfed", JSON.stringify(this.foura.breastfed))
      sessionStorage.setItem("foura_eyecare", JSON.stringify(this.foura.eyecare))
      sessionStorage.setItem("foura_vitK", JSON.stringify(this.foura.vitK))
      sessionStorage.setItem("foura_other", JSON.stringify(this.foura.other))
      sessionStorage.setItem("foura_death", JSON.stringify(this.foura.death))
      sessionStorage.setItem("foura_transferred", JSON.stringify(this.foura.transferred))
      sessionStorage.setItem("foura_interventions", JSON.stringify(this.foura.interventions))
      sessionStorage.setItem("foura_referral", JSON.stringify(this.foura.referral))
      sessionStorage.setItem("foura_otherNotes", JSON.stringify(this.foura.otherNotes))
      sessionStorage.setItem("foura_designation", JSON.stringify(this.foura.designation))
      sessionStorage.setItem("foura_completedBy", JSON.stringify(this.foura.completedBy))
      sessionStorage.setItem("fourc_birthId", JSON.stringify(this.fourc.birthId))
      sessionStorage.setItem("fourc_skinBroken", JSON.stringify(this.fourc.skinBroken))
      sessionStorage.setItem("fourc_anyAnomalies", JSON.stringify(this.fourc.anyAnomalies))
      sessionStorage.setItem("fourc_anomalies", JSON.stringify(this.fourc.anomalies))
      sessionStorage.setItem("fourc_notes", JSON.stringify(this.fourc.notes))
      sessionStorage.setItem("foura_uids_mid", JSON.stringify(this.uids.mid));
      sessionStorage.setItem("foura_uids_pid", JSON.stringify(this.uids.pid));
      sessionStorage.setItem("foura_uids_vid", JSON.stringify(this.uids.vid));
    } catch (ex) {
      console.log(ex);
    }
  }

  clickCB(id: number) {
    this.foura.resuscitation[id] = !this.foura.resuscitation[id];
  }

  clickCBA(id: number) {
    this.fourc.anomalies[id] = !this.fourc.anomalies[id];
  }

  onSubmit() {
    let fd = this.foura.mapforDB();
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      question:  this.foura.getClassName(),
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    if (this.bid !== null) {
      data['bid'] = this.bid;
    }
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    if (this.bid === null) {
      this.http.post(BASE_URL + "baby/add", data).subscribe(response => {
        let baby = response['id'];
        let fd = this.fourc.mapforDB();
        let data = {
          mid: this.uids.mid,
          pid: this.uids.pid,
          vid: this.uids.vid,
          bid: baby,
          question: this.fourc.getClassName(),
          enteredby: this.global.getCurrentUser(),
          answers: fd,
        };
        this.http.post(BASE_URL + "baby/form/add", data).subscribe(response => {
          this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
          this.global.toastToSave();
        });
      });
    } else {
      data['bid'] = this.bid;
      this.http.post(BASE_URL + "baby/update", data).subscribe(response => {
        let fd = this.fourc.mapforDB();
        let data = {
          mid: this.uids.mid,
          pid: this.uids.pid,
          vid: this.uids.vid,
          bid: this.bid,
          question: this.fourc.getClassName(),
          enteredby: this.global.getCurrentUser(),
          answers: fd,
        };
        this.http.post(BASE_URL + "baby/update", data).subscribe(response => {
          this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
          this.global.toastToSave();
          sessionStorage.removeItem("foura_dateOfBirth");
          sessionStorage.removeItem("foura_timeOfBirth_mm");
          sessionStorage.removeItem("foura_timeOfBirth_hh");
          sessionStorage.removeItem("foura_bornAlive");
          sessionStorage.removeItem("foura_hospitalId");
          sessionStorage.removeItem("foura_sex");
          sessionStorage.removeItem("foura_apgar1");
          sessionStorage.removeItem("foura_apgar5");
          sessionStorage.removeItem("foura_apgar10");
          sessionStorage.removeItem("foura_weight");
          sessionStorage.removeItem("foura_length");
          sessionStorage.removeItem("foura_headC");
          sessionStorage.removeItem("foura_nni");
          sessionStorage.removeItem("foura_resuscitation");
          sessionStorage.removeItem("foura_s2scontact");
          sessionStorage.removeItem("foura_delayedCC");
          sessionStorage.removeItem("foura_breastfed");
          sessionStorage.removeItem("foura_eyecare");
          sessionStorage.removeItem("foura_vitK");
          sessionStorage.removeItem("foura_other");
          sessionStorage.removeItem("foura_death");
          sessionStorage.removeItem("foura_transferred");
          sessionStorage.removeItem("foura_interventions");
          sessionStorage.removeItem("foura_referral");
          sessionStorage.removeItem("foura_otherNotes");
          sessionStorage.removeItem("foura_designation");
          sessionStorage.removeItem("foura_completedBy");
          sessionStorage.removeItem("fourc_birthId");
          sessionStorage.removeItem("fourc_skinBroken");
          sessionStorage.removeItem("fourc_anyAnomalies");
          sessionStorage.removeItem("fourc_anomalies");
          sessionStorage.removeItem("fourc_notes");
        });
      });
    }
  }

  iterator(end: number, start: number = 0, increment = 1): number[] {
    return this.global.iterateNumbers(end, start, increment);
  }

  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation === true){
  //     options.form.controls[options.name].setErrors(null);
  //   }else if(validation === false){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //   }
  // }
}
