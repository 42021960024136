import { qModel } from './qModels';

export class TwoF extends qModel{
    medicalConditions : string = null;
    conditions : object = {};
    previousSurgery : string = null;
    previousUtrineSurgery : string = null;
    notes : string = null;
    private none : string = null;
    completedby : string = null;

    getClassName() : string {
        return "Section2f";
    }

    mapforDB() : any {
        let details = {};
        details['answer2f1'] = this.medicalConditions === null ? "No Entry" : this.medicalConditions;
        for(let id in this.list){
            let num = id.trim();
            let name = "answer2f"+(num);
            details[name+"state"] = this.conditions[id]['present'] ? "Present" : "No Entry";
            details[name+"ans"] = 
            this.conditions[id]['before'] === null ? 
                "No Option Selected" : 
                this.conditions[id]['before'] + (
                    this.conditions[id]['notes'] !== null && (typeof this.conditions[id]['notes'] !== 'undefined')? ","+this.conditions[id]['notes'] : ""
                );
        }
        details['answer2f15'] = this.previousSurgery === null ? "No Entry" : this.previousSurgery;
        details['answer2f16'] = this.previousUtrineSurgery === null ? "No Entry" : this.previousUtrineSurgery;
        details['answer2f17'] = this.notes === null ? "No Entry" : this.notes;
        details['answer2f18'] = this.none === null ? "No Entry" : this.none;
        details['answer2f19'] = this.completedby === null ? "No Entry" : this.completedby;
        return details;
    }
    
    mapforForm(data : any){
        for(let key in data){
            let akey = key.slice("answer2f".length);
            if(isNaN(+akey)){
                let num : string = parseInt(akey.split('state')[0])+" ";
                if((akey.split('state')).length > 1 && (akey.split('ans')).length < 2){
                    this.conditions[num]['present'] = data[key] === 'Present' ? true : false;
                }else if((akey.split('state')).length < 2 && (akey.split('ans')).length > 1){
                    if(data[key] !== "No Option Selected"){
                        let split = data[key].split(',');
                        this.conditions[num]['before'] = split[0];
                        this.conditions[num]['notes'] = split[1];
                    }
                }
            }else{
                switch(akey){
                    case '1':
                        this.medicalConditions = data[key];
                    break;
                    case '15':
                        this.previousSurgery = data[key];
                    break;
                    case '16':
                        this.previousUtrineSurgery = data[key];
                    break;
                    case '17':
                        this.notes = data[key];
                    break;
                    case '18':
                        this.none = data[key];
                    break;
                    case '19':
                        this.completedby = data[key];
                    break;
                }
            }
        }
    }

    set noconditions(ans : boolean){
        this.none = ans ? "None": "No Entry";
    }

    get noconditions(){
        return this.none === "None";
    }

    get list() : any {
        return {
            "2 " : "Cardiac Disease",
            "3 " : "Hypertension",
            "4 " : "Renal disease",
            "5 " : "Respiratory disease",
            "6 " : "Convulsive disorder",
            "7 " : "Diabetes",
            "8 " : "Tuberculosis",
            "9 " : "Cancer",
            "10 " : "Mental health disorder",
            "11 " : "Thyroid Disorder",
            "12 " : "Haemoglobinopathy",
            "13 " : "Venous Thromboembolism",
            "20 " : "HIV/AIDs",
            "14 " : "Other",
        };
    }
}