import { Component, OnInit } from '@angular/core';
import { Globals, BASE_URL } from 'app/globals';

@Component({
  templateUrl: './visit-forms-questions.component.html',
  styleUrls: ['./visit-forms-questions.component.css']
})
export class VisitFormsQuestionsComponent implements OnInit {
  public uids;
  constructor(
    global : Globals
  ) {
    this.uids = global.uidsfq;
  }

  ngOnInit() {
  }

}
