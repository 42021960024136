import { Component, OnInit } from '@angular/core';
import { HttpClient as Http } from '@angular/common/http';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private http: Http,
    private global : Globals,
    private router : Router,
    private aRoute : ActivatedRoute,
  ) { 
    this.global.clearLogin();
  }

  ngOnInit() {
    this.http.get(BASE_URL+"logout").subscribe();
    this.router.navigate(['/login']);
  }

}
