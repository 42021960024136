import { qModel } from './qModels';
import * as moment from 'moment';

export class TwoE extends qModel{
    US : string = null;
    earliestDateUS : moment.Moment = null;
    eddByUS : moment.Moment = null;
    numFetus : string = null;
    chronocity : boolean[] = [];
    chronocityMapped : string[] = [];
    amnionicty : boolean[] = [];
    amnionictyMapped : string[] = [];
    pp : string = null;
    devAnomaly : string = null;
    anomaly : string = null;
    conditions : boolean[] = [];
    conditionsMapped : string[] = [];
    others : any = { chronocity : null, amnionicty : null, conditions : null};
    completedby : string = null;

    getClassName() : string {
        return "Section2e";
    }

    mapforDB() : any {
        this.chronocityMapped = this.mapChecksToList(this.list.chronocity, this.chronocity, this.others.chronocity).join(",");
        this.amnionictyMapped = this.mapChecksToList(this.list.amnionicty, this.amnionicty, this.others.amnionicty).join(",");
        this.conditionsMapped = this.mapChecksToList(this.list.conditions, this.conditions, this.others.conditions).join(",");
        return this.removeNullsAndReturnAnswers(this.propertyMapping);
    }
    
    mapforForm(data : any){
        this.dataToForm(this.propertyMapping, data);
        let chronocity = this.mapListToChecks(this.list.chronocity, (typeof data['answer2e5'] === 'undefined' ? null  : data['answer2e5'].split(',')), 'chronocity');
        let amnionicty = this.mapListToChecks(this.list.amnionicty, (typeof data['answer2e6'] === 'undefined' ? null : data['answer2e6'].split(',')),'amnionicty');
        let conditions = this.mapListToChecks(this.list.conditions, (typeof data['answer2e10'] === 'undefined' ? null  : data['answer2e10'].split(',')), 'conditions');
        console.log(conditions, chronocity, amnionicty);
        this.conditions = conditions.checks;
        this.chronocity = chronocity.checks;
        this.amnionicty = amnionicty.checks;
        for(let i in conditions){
            if(i !== "checks"){
                this.others[i] = conditions[i];
            }
        }
        for(let i in chronocity){
            if(i !== "checks"){
                this.others[i] = chronocity[i];
            }
        }
        for(let i in amnionicty){
            if(i !== "checks"){
                this.others[i] = amnionicty[i];
            }
        }
    }

    get list() : any {
        return {
            "chronocity" :[
                "Unknown",
                "Monochorionic",
                "Dichorionic",
                "Other",
            ],
            "amnionicty" :[
                "Unknown",
                "Monoamniotic",
                "Diamniotic",
                "Other",
            ],
            "conditions" : [
                "None",
                "LGA",
                "Fetal hydrops",
                "IUGR",
                "Oligohydramnios",
                "Abnormal dopplers",
                "SGA",
                "Polyhydramnios",
                "IUFD",
                "Other",
            ],
        };
    }

    get propertyMapping(){
        return {
            "US" : {type: "String", apply: true, name: "answer2e1"},
            "earliestDateUS" : {type: "Date", apply: true, name: "answer2e2"},
            "eddByUS" : {type: "Date", apply: true, name: "answer2e3"},
            "numFetus" : {type: "String", apply: true, name: "answer2e4"},
            "chronocityMapped": {type: "Array", apply: false, name: "answer2e5"},
            "amnionictyMapped": {type: "Array", apply: false, name: "answer2e6"},
            "pp" : {type: "String", apply: true, name: "answer2e7"},
            "devAnomaly" : {type: "String", apply: true, name: "answer2e8"},
            "anomaly" : {type: "String", apply: true, name: "answer2e9"},
            "conditionsMapped" : {type: "Array", apply: false, name: "answer2e10"},
            "completedby": {type: "String", apply: true, name: "answer2e11"},
        };
    }
}