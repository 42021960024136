import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpClient as Http } from '@angular/common/http';
import { Router } from '@angular/router';
import { Globals, BASE_URL } from 'app/globals';

@Component({
  selector: 'app-create-account-login',
  templateUrl: './create-account-login.component.html',
  styleUrls: ['./create-account-login.component.css']
})
export class CreateAccountLoginComponent implements OnInit {
  username : string;
  password : string;
  constructor(
    public dialogRef: MatDialogRef<CreateAccountLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: Http,
    private global : Globals,
    private router : Router
  ) { }

  ngOnInit() {}

  onSubmit() {
    this.http.post(BASE_URL+"admin/login", {username: this.username, password: this.password}).subscribe( 
      (response) => {
        this.data.success = response['success'];
        this.dialogRef.close(this.data);
      });
  }
}
