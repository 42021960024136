import { Moment } from "moment";

export class NewMother{
    public id : number = null;
    public name : string = null;
    public dobM : Moment = null;
    public dob : string = null;
    public age : number = null;
    public fcode : string = null;
    public fname : string = null;
    public email: string = null;
    public phone: string = null;
    public yoe : string = null;
    public maritalstatus : string = null;
    public hospitalid : string = null;
    public filledby : string = null;
}