import { qModel } from './qModels';

export class TwoH extends qModel{
    noInvestigations : boolean = null;
    urineDipstick : boolean = null;
    udProtein : string = null;
    udGlucose : string = null;
    udLeukocytes : string = null;
    udKetones : string = null;
    udNitrites : string = null;
    bs : string = null;
    bs2: string = null;
    bsND : boolean = null;
    hb : number = null;
    hbND : boolean = null;
    others : string = null;
    completedBy : string = null;

    getClassName() : string {
        return "Section2h";
    }

    mapforDB() : any {
        let details = {};
        let name = "answer2h";
        details['answer2h1'] = this.noInvestigations === null || this.noInvestigations === false ? "No Entry" : "No Investigations Done";
        if(this.urineDipstick === null || this.urineDipstick === false){
            details[name+'2'] = this.udProtein === null ? "Choose Option" : this.udProtein;
            details[name+'3'] = this.udGlucose === null ? "Choose Option" : this.udGlucose;
            details[name+'4'] = this.udLeukocytes === null ? "Choose Option" : this.udLeukocytes;
            details[name+'5'] = this.udKetones === null ? "Choose Option" : this.udKetones;
            details[name+'6'] = this.udNitrites === null ? "Choose Option" : this.udNitrites;
        }else{
            details[name+'2'] = "Not Done";
            details[name+'3'] = "Not Done";
            details[name+'4'] = "Not Done";
            details[name+'5'] = "Not Done";
            details[name+'6'] = "Not Done";
        }
        if(this.bsND === false || this.bsND === null){
            details[name+'7'] = this.bs === null ? "No Entry" : this.bs;
            details[name+'10'] = this.bs2 === null ? "No Entry" : this.bs2;
        }else{
            details[name+'7'] = "Not Done";
            details[name+'10'] = "Not Done";
        }
        if(this.hbND === false || this.hbND === null){
            details[name+'8'] = this.hb === null ? "No Entry" : this.hb;
        }else{
            details[name+'8'] = "Not Done";
        }
        details[name+'11'] = this.others === null ? "No Entry" : this.others;
        details[name+'9'] = this.completedBy === null ? "No Entry" : this.completedBy;
        return details;
    }
    
    mapforForm(data : any){
        this.noInvestigations = data.answer2h1 === 'No Investigations Done';
        this.urineDipstick = data.answer2h2 === 'Not Done';
        this.udProtein = data.answer2h2;
        this.udGlucose = data.answer2h3;
        this.udLeukocytes = data.answer2h4;
        this.udKetones = data.answer2h5;
        this.udNitrites = data.answer2h6;
        this.bsND = data.answer2h7 === 'Not Done' || data.answer2h10 === 'Not Done';
        this.bs = data.answer2h7;
        this.bs2 = data.answer2h10;
        this.hbND = data.answer2h8 === 'Not Done';
        this.hb = data.answer2h8;
        this.others = data.answer2h9;
        this.others = data.answer2h11;
        this.completedBy = data.answer2h9;
    }

    get list() : any {
        return [];
    }
}