import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { TwoE } from '../models/questions/2e';
import { Validation } from '../validation';
import * as moment from 'moment';

@Component({
  selector: 'app-visit-forms-questions-2e',
  templateUrl: './visit-forms-questions-2e.component.html',
  styleUrls: ['./visit-forms-questions-2e.component.css']
})
export class VisitFormsQuestions2eComponent extends Validation implements OnInit {
  @ViewChild('formTwoE', { read: ElementRef, static: false }) Form;
  twoe: TwoE;
  uids: any;
  year = new Date().getFullYear();
  maxDate = moment().local();

  constructor (
    private http: Http,
    private global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.twoe = new TwoE();
    this.twoe.conditions = [];
    this.twoe.chronocity = [];
    this.twoe.amnionicty = [];
  }

  ngOnInit() {
    if (!this.global.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
    this.uids = this.global.uidsfq;
    sessionStorage.setItem("twoe_uids_mid", JSON.stringify(this.uids.mid));
      sessionStorage.setItem("twoe_uids_pid", JSON.stringify(this.uids.pid));
      sessionStorage.setItem("twoe_uids_vid", JSON.stringify(this.uids.vid));
    let twoe_conditions = JSON.parse(sessionStorage.getItem("twoe_conditions"))
    if (twoe_conditions[0] === "None") {
      for (let i = 0; i < this.twoe.list.conditions.length; i++) {
        this.twoe.conditions.push(false);
      }
      for (let i = 0; i < this.twoe.list.chronocity.length; i++) {
        this.twoe.chronocity.push(false);
      }
      for (let i = 0; i < this.twoe.list.amnionicty.length; i++) {
        this.twoe.amnionicty.push(false);
      }
      this.http
        .get(BASE_URL + "mother/pregnancies/visit/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.twoe.getClassName())
        .subscribe(response => {
          if (Object.keys(response).length > 0) {
            sessionStorage.setItem("twoe_mapforForm", JSON.stringify(response['answers']));
            this.twoe.mapforForm(response['answers']);
          } else {
            this.twoe.completedby = this.global.currentUserName;
          }
        });
      this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
        .subscribe(response => {
          if (response['concluded']) this.Form.form.disable();
        });
    }
    else {
      this.http
        .get(BASE_URL + "mother/pregnancies/visit/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.twoe.getClassName())
        .subscribe(response => {
          if (Object.keys(response).length > 0) {
            this.twoe.mapforForm(response['answers']);
          } else {
            this.twoe.completedby = this.global.currentUserName;
          }
        });
      this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
        .subscribe(response => {
          if (response['concluded']) this.Form.form.disable();
        });
      this.uids.mid = JSON.parse(sessionStorage.getItem("twoe_uids_mid"));
      this.uids.pid = JSON.parse(sessionStorage.getItem("twoe_uids_pid"));
      this.uids.vid = JSON.parse(sessionStorage.getItem("twoe_uids_vid"));
      this.twoe.US = sessionStorage.getItem("twoe_US");
      this.twoe.earliestDateUS = JSON.parse(sessionStorage.getItem("twoe_earliestDateUS"));
      this.twoe.eddByUS = JSON.parse(sessionStorage.getItem("twoe_eddByUS"));
      this.twoe.chronocity = JSON.parse(sessionStorage.getItem("twoe_chronocity"));
      this.twoe.amnionicty = JSON.parse(sessionStorage.getItem("twoe_amnionicty"));
      if (this.twoe.list != null) {
        this.twoe.list.conditions = JSON.parse(sessionStorage.getItem("twoe_list_condition"));
        this.twoe.list.chronocity = JSON.parse(sessionStorage.getItem("twoe_list_chronocit"));
        this.twoe.list.amnionicty = JSON.parse(sessionStorage.getItem("twoe_list_amnionict"));
      }
      if (this.twoe.others != null) {

        this.twoe.others.chronocity = JSON.parse(sessionStorage.getItem("twoe_others_chronocity"));
        this.twoe.others.amnionicty = JSON.parse(sessionStorage.getItem("twoe_others_amnionicty"));
        this.twoe.others.conditions = JSON.parse(sessionStorage.getItem("twoe_others_conditions"));
      }
      this.twoe.pp = JSON.parse(sessionStorage.getItem("twoe_pp"));
      this.twoe.devAnomaly = JSON.parse(sessionStorage.getItem("twoe_devAnomaly"));
      this.twoe.anomaly = JSON.parse(sessionStorage.getItem("twoe_anomaly"));
      this.twoe.conditions = JSON.parse(sessionStorage.getItem("twoe_conditions"));
      this.twoe.numFetus = JSON.parse(sessionStorage.getItem("twoe_numFetus"));
      this.twoe.completedby = JSON.parse(sessionStorage.getItem("twoe_completedby"));


      this.twoe.list.conditions = JSON.parse(sessionStorage.getItem("twoe_list_conditions"));
      for (let i = 0; i < this.twoe.list.conditions.length; i++) {
        this.twoe.conditions.push(false);
      }
      this.twoe.list.chronocity = JSON.parse(sessionStorage.getItem("twoe_list_chronocity"));
      for (let i = 0; i < this.twoe.list.chronocity.length; i++) {
        this.twoe.chronocity.push(false);
      }
      this.twoe.list.amnionicty = JSON.parse(sessionStorage.getItem("twoe_list_amnionicty"));
      for (let i = 0; i < this.twoe.list.amnionicty.length; i++) {
        this.twoe.amnionicty.push(false);
      }
    }
  }

  clickCB(id: number, type: string) {
    this.twoe[type][id] = !this.twoe[type][id];
  }

  onSubmit() {
    let fd = this.twoe.mapforDB();
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      question: this.twoe.getClassName(),
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe(response => {
      this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
      this.global.toastToSave();
      sessionStorage.removeItem("twoe_uids_mid");
      sessionStorage.removeItem("twoe_uids_pid");
      sessionStorage.removeItem("twoe_uids_vid");
      sessionStorage.removeItem("twoe_list_conditions");
      sessionStorage.removeItem("twoe_list_chronocity");
      sessionStorage.removeItem("twoe_list_amnionicty");
      sessionStorage.removeItem("twoe_US");
      sessionStorage.removeItem("twoe_earliestDateUS");
      sessionStorage.removeItem("twoe_eddByUS");
      sessionStorage.removeItem("twoe_chronocity");
      sessionStorage.removeItem("twoe_others_chronocity");
      sessionStorage.removeItem("twoe_others_amnionicty");
      sessionStorage.removeItem("twoe_others_conditions");
      sessionStorage.removeItem("twoe_amnionicty");
      sessionStorage.removeItem("twoe_pp");
      sessionStorage.removeItem("twoe_devAnomaly");
      sessionStorage.removeItem("twoe_anomaly");
      sessionStorage.removeItem("twoe_conditions");
      sessionStorage.removeItem("twoe_numFetus");
      sessionStorage.removeItem("twoe_completedby");
    });
  }

  iterator(end: number, start: number = 0, increment = 1): number[] {
    return this.global.iterateNumbers(end, start, increment);
  }


  ngDoCheck() {
    try {
      sessionStorage.setItem("twoe_US", this.twoe.US);
      sessionStorage.setItem("twoe_earliestDateUS", JSON.stringify(this.twoe.earliestDateUS));
      sessionStorage.setItem("twoe_eddByUS", JSON.stringify(this.twoe.eddByUS));
      sessionStorage.setItem("twoe_numFetus", JSON.stringify(this.twoe.numFetus));
      sessionStorage.setItem("twoe_list_chronocity", JSON.stringify(this.twoe.list.chronocity));
      sessionStorage.setItem("twoe_list_amnionicty", JSON.stringify(this.twoe.list.amnionicty));
      sessionStorage.setItem("twoe_pp", JSON.stringify(this.twoe.pp));
      sessionStorage.setItem("twoe_devAnomaly", JSON.stringify(this.twoe.devAnomaly));
      sessionStorage.setItem("twoe_anomaly", JSON.stringify(this.twoe.anomaly));
      sessionStorage.setItem("twoe_conditions", JSON.stringify(this.twoe.conditions));
      sessionStorage.setItem("twoe_completedby", JSON.stringify(this.twoe.completedby));
      sessionStorage.setItem("twoe_list_conditions", JSON.stringify(this.twoe.list.conditions));
      sessionStorage.setItem("twoe_amnionicty", JSON.stringify(this.twoe.amnionicty));
      sessionStorage.setItem("twoe_chronocity", JSON.stringify(this.twoe.chronocity));
      sessionStorage.setItem("twoe_others", JSON.stringify(this.twoe.others));


      if (this.twoe.others != null) {
        sessionStorage.setItem("twoe_others_chronocity", JSON.stringify(this.twoe.others.chronocity));
        sessionStorage.setItem("twoe_others_amnionicty", JSON.stringify(this.twoe.others.amnionicty))
        sessionStorage.setItem("twoe_others_conditions", JSON.stringify(this.twoe.others.conditions));
      };
    } catch (ex) {
      console.log(ex);
    }
  }

  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation === true){
  //     options.form.controls[options.name].setErrors(null);
  //   }else if(validation === false){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //   }
  // }
}
