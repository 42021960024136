import * as moment from 'moment';
import { Globals } from 'app/globals';

export abstract class qModel{

    protected removeNullsAndReturnAnswers(propertyMapping : any) : any {
        let properties = Object.keys(this);
        let mappings = propertyMapping;
        let ret = {};
        let skip = [];
        for (let i in properties) {
            let property = properties[i];
            if (Object.keys(mappings).indexOf(property) < 0 || skip.indexOf(property) >= 0){
                continue;
            }
            if(mappings[property].apply && mappings[property].name !== null && mappings[property].type !== "Date"){
                if(this[property] === null){
                    ret[mappings[property].name] = "No Entry";
                }else{
                    ret[mappings[property].name] = this[property];
                }
            }else if(mappings[property].apply && mappings[property].name === null && mappings[property].type === "Mapped"){
                if(this[property] === true){
                    for(let z of mappings[property].mapTo){
                        let tmap = z;
                        ret[mappings[tmap].name] = mappings[property].value;
                        skip.push(tmap);
                    }
                }
            }else if(!mappings[property].apply && mappings[property].type === "Object"){
                let temp1 = "";
                if (typeof mappings[property]['glue'] === undefined){
                    temp1 = this.joinObject(this[property]);
                }else{
                    temp1 = this.joinObject(this[property], mappings[property]['glue']);
                }
                ret[mappings[property].name] = temp1.length > 0 ? temp1 : "";
            }else if(!mappings[property].apply && mappings[property].type === "Time"){
                if((this[property]["hh"] === null || this[property]["hh"].length < 1) && this[property]["mm"] !== null) this[property]["hh"] = "00";
                let temp2 = this.joinObject(this[property], ":");
                ret[mappings[property].name] = temp2.length > 0 ? temp2 : "No Entry";
            }else if(!mappings[property].apply && mappings[property].type === "Array"){
                ret[mappings[property].name] = this[property];
            }else if(mappings[property].apply && mappings[property].type === "Date"){
                if(this[property] === null){
                    ret[mappings[property].name] = "No Entry";
                }else{
                    ret[mappings[property].name] = moment(this[property]).local().format("YYYY-MM-DD");
                }
            }
        }
        return ret;
    }

    protected dataToForm(propertyMapping : any, data : any) {
        let properties = Object.keys(this);
        let mappings = propertyMapping;
        for(let maps in mappings){
            switch(mappings[maps].type){
                case 'String':
                    if(typeof data[mappings[maps].name] !== 'undefined'){
                        this[maps] = data[mappings[maps].name];
                    }
                break;
                case 'Date':
                    if(typeof data[mappings[maps].name] !== "undefined" && moment(data[mappings[maps].name], "YYYY-MM-DD").isValid()){
                        this[maps] = moment(data[mappings[maps].name], "YYYY-MM-DD");
                    }else{
                        this[maps] = null;
                    }
                break;
                case 'Time':
                    if(data[mappings[maps].name].length > 0 && data[mappings[maps].name] !== "No Entry"){
                        let ex = data[mappings[maps].name].split(":");
                        let i = 0;
                        for(let mapped of mappings[maps].mapTo){
                            this[maps][mapped] = ex[i++];
                        }
                    }else{
                        for(let mapped of mappings[maps].mapTo){
                            this[maps][mapped] = "No Entry";
                        }
                    }
                break;
                case 'Object':
                    if(data[mappings[maps].name].length > 0 && data[mappings[maps].name] !== "No Entry"){
                        let divider = ",";
                        if(data[mappings[maps].name].indexOf(',') >= 0){
                            divider = ','
                        }else if(data[mappings[maps].name].indexOf('/') >= 0){
                            divider = '/';
                        }
                        let ex = data[mappings[maps].name].split(divider);
                        let i = 0;
                        for(let mapped of mappings[maps].mapTo){
                            this[maps][mapped] = ex[i++];
                        }
                    }else{
                        for(let mapped of mappings[maps].mapTo){
                            this[maps][mapped] = "No Entry";
                        }
                    }
                break;
            }
        }
    }
    
    protected mapChecksToList(list : any, checklist : boolean[], others : any = null, hasLocus : boolean = false) : any{
        let values = [];
        let loci = [];
        if(hasLocus){ 
            for(let key in others){
                loci.push(others[key].locus);
            }
        }
        for(let i = 0; i < checklist.length; i++){
            if(checklist[i]){
                values.push(list[i]);
            }
            if(checklist[i] && i === (checklist.length-1) && others !== null && !hasLocus){
                values.push(others);
            }else if(checklist[i] && (loci.indexOf(i) >= 0) && others !== null && hasLocus){
                console.log(list[i]);
                for(let key in others){
                    if(others[key].locus === i){
                        values.push(others[key].value);
                    }
                }
            }
        }
        return values;
    }

    protected mapListToChecks(list : any, data : any, others : any = null, hasLocus : boolean = false) : any{
        let ret = {checks : []};
        let i = 0;
        for(i = 0; i < list.length; i++){
            ret.checks[i] = false;
        }
        if(data === null){
            ret[others] = null;
            return ret;
        }
        i = 0;
        for(let item of list){
            ret.checks[i++] = data.indexOf(item) >= 0;
        }
        if(!hasLocus){
            if(others !== null && !!ret.checks[list.length-1]){
                ret[others] = data[data.length-1];
            }
            return ret;
        }else{
            for(let item in others){
                let loc = others[item];
                if(ret.checks[loc]){
                    let loci = data.indexOf(list[loc]);
                    ret[item] = data[loci+1];
                }
            }
            return ret;
        }
    }

    public joinObject(object : any, glue : string = ","){
        let ret = [];
        for (var item in object) {
            if(object[item] === null) continue;
            ret.push(object[item]);
        }
        return ret.join(glue)
    }

    public explodeString(imploded : string){
        let ret = [];
        if(imploded.indexOf(',') > -1){
            return imploded.split(",");
        }else if(imploded.indexOf(':') > -1){
            return imploded.split(":");
        }
    }
    
    setOtherFalse(property : string, current : number, exclude : number){
        if(this[property][exclude] === false) return;
        if(current !== exclude) return;
        for(let i in this[property]){
            if(parseInt(i) === exclude) continue;
            this[property][i] = false;
        }
    }
}