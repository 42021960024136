import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient as Http } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals, BASE_URL } from 'app/globals';
import * as Chartist from "chartist";
import * as CLegend from "assets/chartist/chartist.legend.js";
import * as CPoints from "assets/chartist/chartist.pointlabels.js";
import * as CFDoughnut from 'assets/chartist/chartist.fill-donut.js';
import * as CTooltip from 'assets/chartist/chartist-plugin-tooltip.min.js';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.css']
})
export class ReportViewComponent implements OnInit {

  public from = {
    y : null,
    m : null,
  };
  public to = {
    y : null,
    m : null,
  };
  public facility = null;
  public type = null;

  public mother_registered = [];
  public pregnancies = [];
  public deliveries = [];

  public total_admissions = null;
  public total_active_pregnancies = null;
  public total_deliveries = null;

  public total_mod = null;
  public total_moc = null;
  public total_boc = null;

  constructor(
    private http : Http,
    public global : Globals,
    private aRoute : ActivatedRoute,
  ) {}

  ngOnInit() {
    this.aRoute.params.subscribe(params => {
      this.from.y = params.from_y;
      this.from.m = params.from_m;
      this.to.y = params.to_y;
      this.to.m = params.to_m;
      this.facility = params.facility;
      this.type = params.type;
      let url = BASE_URL+"report";
      this.http.get([url, 'line', this.from.y, this.from.m, this.to.y, this.to.m, this.facility].join('/'))
      .subscribe(response => {
        let data = response;
        this.total_admissions = data['data'][0]['data'].reduce(function(x, y){
          return x+y;
        });
        this.total_active_pregnancies = data['data'][1]['data'].reduce(function(x, y){
          return x+y;
        });
        this.total_deliveries = data['data'][2]['data'].reduce(function(x, y){
          return x+y;
        });
        if(this.type === "Bar"){
          let chartist = new Chartist.Bar('.line', 
            {
              labels: data['label'], 
              series: data['data'],
            },
            {
              height: "300px",
              axisY: {
                onlyInteger: true
              },
              seriesBarDistance: 22,
              plugins: [CLegend(), CTooltip({anchorToPoint: true})]
            }
          );
          document.getElementsByClassName('ct-label');
        }else if(this.type === "Line"){
          let chartist = new Chartist.Line('.line', 
            {
              labels: data['label'], 
              series: data['data'],
            },
            {
              chartPadding: {
                top: 30
              },
              axisY: {
                onlyInteger: true
              },
              plugins: [CLegend(), CTooltip({anchorToPoint: true})]
            }
          );
        }
      });
      this.http.get([url, 'pie', this.from.y, this.from.m, this.to.y, this.to.m, this.facility].join('/'))
      .subscribe(response => {
        let sum = this.sum;
        let data = response;
        let mod_data = {
          labels: data['mod']['labels'],
          series: data['mod']['values'],
        };
        let total_mod = mod_data.series.reduce(sum);
        this.total_mod = total_mod;
        let chartist_mod = new Chartist.Pie('.mod', mod_data, {
          height: "300px",
          donut: true,
          donutWidth: 50,
          donutSolid: true,
          startAngle: 0,
          //showLabel: true,
          plugins: [CLegend({position: 'bottom'}), CFDoughnut(
            {
              items: [{
                class : '',
                id: '',
                content : '<h4 class="center">'+total_mod+'</h4><h6 class="center">Total</h6>',
                position: 'center', //bottom, top, left, right
                offsetY: -100, //top, bottom in px
                offsetX: 0 //left, right in px
              }]
            }, {}
          ), CTooltip({anchorToPoint: false})],
          labelInterpolationFnc: function(name, key) {
            let value = mod_data['series'][key];
            return value['value']+"   (" + Math.round(value['value'] / total_mod * 100) + "%)";
          }
        });
        let moc_data = {
          labels: data['moc']['labels'],
          series: data['moc']['values'],
        };
        let total_moc = moc_data.series.reduce(sum);
        this.total_moc = total_moc;
        let chartist_moc = new Chartist.Pie('.moc', moc_data, {
          height: "300px",
          donut: true,
          donutWidth: 50,
          donutSolid: true,
          startAngle: 0,
          showLabel: true,
          plugins: [CLegend({position: 'bottom'}), CFDoughnut(
            {
              items: [{
                class : '',
                id: '',
                content : '<h4 class="center">'+total_moc+'</h4><h6 class="center">Total</h6>',
                position: 'center', //bottom, top, left, right
                offsetY: -100, //top, bottom in px
                offsetX: 0 //left, right in px
              }]
            }, {}
          )],
          labelInterpolationFnc: function(name, key) {
            let value = moc_data['series'][key];
            return value['value']+"   (" + Math.round(value['value'] / total_moc * 100) + "%)";
          }
        });
        let boc_data = {
          labels: data['boc']['labels'],
          series: data['boc']['values'],
        };
        let total_boc = boc_data.series.reduce(sum);
        this.total_boc = total_boc;
        let chartist_boc = new Chartist.Pie('.boc', boc_data, {
          height: "300px",
          donut: true,
          donutWidth: 50,
          donutSolid: true,
          startAngle: 0,
          showLabel: true,
          plugins: [CLegend({position: 'bottom'}), CFDoughnut(
            {
              items: [{
                class : '',
                id: '',
                content : '<h4 class="center">'+total_boc+'</h4><h6 class="center">Total</h6>',
                position: 'center', //bottom, top, left, right
                offsetY: -100, //top, bottom in px
                offsetX: 0 //left, right in px
              }]
            }, {}
          )],
          labelInterpolationFnc: function(name, key) {
            let value = boc_data['series'][key];
            return value['value']+"   (" + Math.round(value['value'] / total_boc * 100) + "%)";
          }
        });
      })
    });
  }

  private sum(x, y){
    return x+y;
  }
}