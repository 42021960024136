import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { FiveA } from '../models/questions/5a';
import { Validation } from '../validation';

@Component({
  selector: 'app-visit-forms-questions5a',
  templateUrl: './visit-forms-questions5a.component.html',
  styleUrls: ['./visit-forms-questions5a.component.css']
})
export class VisitFormsQuestions5aComponent extends Validation implements OnInit {
  @ViewChild('formFiveA', { read: ElementRef, static: false }) Form;
  fivea: FiveA;
  uids: any;

  constructor (
    private http: Http,
    private global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.fivea = new FiveA();
  }

  ngOnInit() {
    try {
      if (!this.global.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
      this.uids = this.global.uidsfq;
      sessionStorage.setItem("fivea_uids_mid", JSON.stringify(this.uids.mid));
      sessionStorage.setItem("fivea_uids_pid", JSON.stringify(this.uids.pid));
      sessionStorage.setItem("fivea_uids_vid", JSON.stringify(this.uids.vid));
      if (JSON.parse(sessionStorage.getItem("fivea_deliver"))==null &&
       JSON.parse(sessionStorage.getItem("fivea_complications"))[0]==true ) {
        for (let i = 0; i < this.fivea.list.length; i++) {
          this.fivea.complications.push(false);
        }
        this.http
          .get(BASE_URL + "mother/pregnancies/visit/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.fivea.getClassName())
          .subscribe(response => {
            if (Object.keys(response).length > 0) {
              this.fivea.mapforForm(response['answers']);
            } else {
              this.fivea.completedby = this.global.currentUserName;
            }
          });
        this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
          .subscribe(response => {
            if (response['concluded']) this.Form.form.disable();
          });
        sessionStorage.setItem("fivea_uids_mid", JSON.stringify(this.uids.mid));
        sessionStorage.setItem("fivea_uids_pid", JSON.stringify(this.uids.pid));
        sessionStorage.setItem("fivea_uids_vid", JSON.stringify(this.uids.vid));
      } else {
        this.uids.mid = JSON.parse(sessionStorage.getItem("fivea_uids_mid"))
        this.uids.pid = JSON.parse(sessionStorage.getItem("fivea_uids_pid"))
        this.uids.vid = JSON.parse(sessionStorage.getItem("fivea_uids_vid"))


        this.fivea.deliver = JSON.parse(sessionStorage.getItem("fivea_deliver"))
        this.fivea.complications = JSON.parse(sessionStorage.getItem("fivea_complications"))
        this.fivea.other = JSON.parse(sessionStorage.getItem("fivea_other"))
        this.fivea.completedby = JSON.parse(sessionStorage.getItem("fivea_completedby"))
      }
    } catch (ex) {

    }


  }

  ngDoCheck() {
    try {
      sessionStorage.setItem("fivea_deliver", JSON.stringify(this.fivea.deliver))
      sessionStorage.setItem("fivea_complications", JSON.stringify(this.fivea.complications))
      sessionStorage.setItem("fivea_other", JSON.stringify(this.fivea.other))
      sessionStorage.setItem("fivea_completedby", JSON.stringify(this.fivea.completedby))
    } catch (ex) {
      console.log(ex);
    }
  }

  clickCB(id: number) {
    this.fivea.complications[id] = !this.fivea.complications[id];
  }

  onSubmit() {
    let fd = this.fivea.mapforDB();
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      question: this.fivea.getClassName(),
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe(response => {
      this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
      this.global.toastToSave();
      sessionStorage.removeItem("fivea_deliver");
      sessionStorage.removeItem("fivea_complications");
      sessionStorage.removeItem("fivea_other");
      sessionStorage.removeItem("fivea_completedby");
    });
  }

  iterator(end: number, start: number = 0, increment = 1): number[] {
    return this.global.iterateNumbers(end, start, increment);
  }

  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation === true){
  //     options.form.controls[options.name].setErrors(null);
  //   }else if(validation === false){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //   }
  // }
}
