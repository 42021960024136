import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient as Http } from '@angular/common/http';
import { Router } from '@angular/router';
import { Globals, BASE_URL } from 'app/globals';
import { NewAccount } from '../models/new-account';
import { MatSnackBar } from '@angular/material';
import { EmailValidator, NgForm } from '@angular/forms';

@Component({
  selector: 'app-create-account-main',
  templateUrl: './create-account-main.component.html',
  styleUrls: ['./create-account-main.component.css']
})
export class CreateAccountMainComponent implements OnInit {
  hasConnection : boolean;
  facilityList : any = [];
  user : NewAccount;
  dialog : any;

  constructor(
    private http: Http,
    private global : Globals,
    private router : Router,
    public snackBar: MatSnackBar
  ) {
    this.hasConnection = false;
    this.user = new NewAccount();
  }

  ngOnInit() {
    /* console.log(this.Form);
    this.Form.form.disable();  */
    if(!this.global.getAdminLogin()){
      this.router.navigate(['/login']);
    }else{
      this.http.get(BASE_URL+"list/facilities")
      .subscribe( result => {
        this.facilityList = result;
        /* setTimeout(x => {
          this.Form.form.enable();
        }, 500); */
      });
    }
  }

  set selectedFacility(param : string){
    for(let x of this.facilityList){
      if(x.facilityname === param){
        this.user.fcode = x.facilitycode;
        this.user.fname = x.facilityname;
        this.user.cname = x.countryname;
        this.user.ccode = x.countrycode;
      }
    }
  }

  btnSave() {
    this.http.post(BASE_URL+"admin/add-user", this.user)
    .subscribe( result => {
      if(result['success']){
        this.snackBar.open("User Saved", "", {
          duration: 5000,
        });
        this.router.navigate(['/']);
      }else{
        this.snackBar.open("User Not Saved, there was an error. " + result['message'], "", {
          duration: 5000,
        });
      }
    });
  }

}
