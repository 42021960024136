import { Component, OnInit } from '@angular/core';
import { Globals, BASE_URL } from 'app/globals';
import { HttpHeadered as Http } from '../services/httpHeadered';
/* import { HttpClient as Http } from '@angular/common/http'; */
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-my-task-list',
  templateUrl: './my-task-list.component.html',
  styleUrls: ['./my-task-list.component.css']
})
export class MyTaskListComponent implements OnInit {

  notifications = [];
  private _start = 0;
  private _end = 10;
  query = true;

  constructor(
    private http: Http, 
    private global : Globals,
    private router : Router
  ) { }

  ngOnInit() {
    this.http.post(BASE_URL+"notifications", {uid: this.global.getCurrentUser()}).subscribe( response => {
      this.query = false;
      this.notifications = <any>response;
    });
  }

  openNotication(id, tragetId){
    let z = tragetId;
    this.http.post(BASE_URL+"notifications-read", {uid: this.global.getCurrentUser(), nid: id}).subscribe();
    this.router.navigateByUrl('/mother/pregnancy/visit/'+z.mid+":"+z.pid+":"+z.vid+"/"+z.section);
  }

  loadMore(){
    this.query = true;
    this._start = this._end;
    this._end += 10;
    this.http.post(BASE_URL+"notifications/"+this._start+"/"+this._end, {uid: this.global.getCurrentUser()}).subscribe( response => {
      let data = response;
      this.query = false;
      for(let x in data){
        this.notifications.push(data[x]);
      }
    });
  }
}
