import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { TwoD } from '../models/questions/2d';
import { Validation } from '../validation';

@Component({
  templateUrl: './visit-forms-questions-2d.component.html',
  styleUrls: ['./visit-forms-questions-2d.component.css']
})
export class VisitFormsQuestions2dComponent extends Validation implements OnInit {
  @ViewChild('formTwoD', { read: ElementRef, static: false }) Form;
  twod: TwoD;
  uids: any;

  constructor (
    private http: Http,
    private global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.twod = new TwoD();
  }
  
  ngOnInit() {
    if (!this.global.isLoggedIn()) {
      this.router.navigate(['/login']);
    }
    this.uids = this.global.uidsfq;
    sessionStorage.setItem("twod_uids_mid", JSON.stringify(this.uids.mid));
    sessionStorage.setItem("twod_uids_pid", JSON.stringify(this.uids.pid));
    sessionStorage.setItem("twod_uids_vid", JSON.stringify(this.uids.vid));
    if (JSON.parse(sessionStorage.getItem("twod_hbND")) == 12.3) {

      this.http
        .get(BASE_URL + "mother/pregnancies/visit/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.twod.getClassName())
        .subscribe(response => {
          if (Object.keys(response).length > 0) {
            sessionStorage.setItem("twod_mapforForm", JSON.stringify(response['answers']));
            this.twod.mapforForm(response['answers']);
          } else {
            this.twod.completedby = this.global.currentUserName;
          }
        });
      this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
        .subscribe(response => {
          if (response['concluded']) this.Form.form.disable();
        });
    } else {
      this.uids.mid = JSON.parse(sessionStorage.getItem("twod_uids_mid"));
      this.uids.pid = JSON.parse(sessionStorage.getItem("twod_uids_pid"));
      this.uids.vid = JSON.parse(sessionStorage.getItem("twod_uids_vid"));


      this.twod.hb = JSON.parse(sessionStorage.getItem("twod_hb"));
      this.twod.hbND = JSON.parse(sessionStorage.getItem("twod_hbND"));
      this.twod.vdrl = JSON.parse(sessionStorage.getItem("twod_vdrl"));
      this.twod.hiv = JSON.parse(sessionStorage.getItem("twod_hiv"));
      this.twod.hbsag = JSON.parse(sessionStorage.getItem("twod_hbsag"));
      this.twod.bgND = JSON.parse(sessionStorage.getItem("twod_bgND"));
      this.twod.bg = JSON.parse(sessionStorage.getItem("twod_bg"));
      this.twod.rh = JSON.parse(sessionStorage.getItem("twod_rh"));
      this.twod.ogtt.fasting = JSON.parse(sessionStorage.getItem("twod_ogtt_fasting"));
      this.twod.ogtt.one = JSON.parse(sessionStorage.getItem("twod_ogtt_one"));
      this.twod.ogtt.two = JSON.parse(sessionStorage.getItem("twod_ogtt_two"));
      this.twod.ogttND = JSON.parse(sessionStorage.getItem("twod_ogttND"));
      this.twod.others = JSON.parse(sessionStorage.getItem("twod_others"));
      this.twod.completedby = JSON.parse(sessionStorage.getItem("twod_completedby"));
    }
  }

  ngDoCheck() {
    try {
      sessionStorage.setItem("twod_hb", JSON.stringify(this.twod.hb));
      sessionStorage.setItem("twod_hbND", JSON.stringify(this.twod.hbND));
      sessionStorage.setItem("twod_vdrl", JSON.stringify(this.twod.vdrl));
      sessionStorage.setItem("twod_hiv", JSON.stringify(this.twod.hiv));
      sessionStorage.setItem("twod_hbsag", JSON.stringify(this.twod.hbsag));
      sessionStorage.setItem("twod_bgND", JSON.stringify(this.twod.bgND));
      sessionStorage.setItem("twod_bg", JSON.stringify(this.twod.bg));
      sessionStorage.setItem("twod_rh", JSON.stringify(this.twod.rh));
      sessionStorage.setItem("twod_ogtt_fasting", JSON.stringify(this.twod.ogtt.fasting));
      sessionStorage.setItem("twod_ogtt_one", JSON.stringify(this.twod.ogtt.one));
      sessionStorage.setItem("twod_ogtt_two", JSON.stringify(this.twod.ogtt.two));
      sessionStorage.setItem("twod_ogttND", JSON.stringify(this.twod.ogttND));
      sessionStorage.setItem("twod_others", JSON.stringify(this.twod.others));
      sessionStorage.setItem("twod_completedby", JSON.stringify(this.twod.completedby));
    } catch (ex) {
      console.log(ex);
    }
  }

  onSubmit() {
    let fd = this.twod.mapforDB();
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      question: this.twod.getClassName(),
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe(response => {
      this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
      this.global.toastToSave();
      sessionStorage.removeItem("twod_hb");
      sessionStorage.removeItem("twod_hbND");
      sessionStorage.removeItem("twod_vdrl");
      sessionStorage.removeItem("twod_hiv");
      sessionStorage.removeItem("twod_hbsag");
      sessionStorage.removeItem("twod_bgND");
      sessionStorage.removeItem("twod_bg");
      sessionStorage.removeItem("twod_rh");
      sessionStorage.removeItem("twod_ogtt_fasting");
      sessionStorage.removeItem("twod_ogtt_one");
      sessionStorage.removeItem("twod_ogtt_two");
      sessionStorage.removeItem("twod_ogttND");
      sessionStorage.removeItem("twod_others");
      sessionStorage.removeItem("twod_completedby");
    });
  }

  iterator(end: number, start: number = 0, increment = 1): number[] {
    return this.global.iterateNumbers(end, start, increment);
  }

  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation === true){
  //     options.form.controls[options.name].setErrors(null);
  //   }else if(validation === false){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //   }
  // }
}
