import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { TwoG } from '../models/questions/2g';
import { Validation } from '../validation';

@Component({
  selector: 'app-visit-forms-questions-2g',
  templateUrl: './visit-forms-questions-2g.component.html',
  styleUrls: ['./visit-forms-questions-2g.component.css']
})
export class VisitFormsQuestions2gComponent extends Validation implements OnInit {
  @ViewChild('formTwoG', { read: ElementRef, static: false }) Form;
  twog: TwoG;
  uids: any;

  constructor (
    private http: Http,
    private global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.twog = new TwoG();
  }

  ngOnInit() {
    this.uids = this.global.uidsfq;
    sessionStorage.setItem("twog_uids_mid", JSON.stringify(this.uids.mid));
    sessionStorage.setItem("twog_uids_pid", JSON.stringify(this.uids.pid));
    sessionStorage.setItem("twog_uids_vid", JSON.stringify(this.uids.vid));
    try {
      if (!this.global.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
      if (JSON.parse(sessionStorage.getItem("twog_completedBy"))==null) {
        sessionStorage.setItem("twog_mapforDB", JSON.stringify(this.twog.mapforDB()));
        sessionStorage.setItem("twog_getClassName", JSON.stringify(this.twog.getClassName()));
        sessionStorage.setItem("twog_uids_mid", JSON.stringify(this.uids.mid));
        sessionStorage.setItem("twog_uids_pid", JSON.stringify(this.uids.pid));
        sessionStorage.setItem("twog_uids_vid", JSON.stringify(this.uids.vid));
        sessionStorage.setItem("twog_list", JSON.stringify(this.twog.list));
        for (let i = 0; i < this.twog.list.length; i++) {
          console.log(this.twog.list[i]);
          this.twog.pelvicExam.push(false);
        }
        this.http
          .get(BASE_URL + "mother/pregnancies/visit/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.twog.getClassName())
          .subscribe(response => {
            if (Object.keys(response).length > 0) {
              sessionStorage.setItem("twog_mapforForm", JSON.stringify(response['answers']));
              this.twog.mapforForm(response['answers']);
            } else {
              this.twog.completedBy = this.global.currentUserName;
            }
          });
        this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
          .subscribe(response => {
            if (response['concluded']) this.Form.form.disable();
          });

      }
      else {
        this.twog.mapforDB = JSON.parse(sessionStorage.getItem("twog_mapforDB"));
        this.twog.getClassName = JSON.parse(sessionStorage.getItem("twog_getClassName"));
        this.uids.mid = JSON.parse(sessionStorage.getItem("twog_uids_mid"));
        this.uids.pid = JSON.parse(sessionStorage.getItem("twog_uids_pid"));
        this.uids.vid = JSON.parse(sessionStorage.getItem("twog_uids_vid"));


        this.twog.bp['diastolic'] = JSON.parse(sessionStorage.getItem("twog_bp['diastolic']"));
        this.twog.bp['systolic'] = JSON.parse(sessionStorage.getItem("twog_bp['systolic']"));
        this.twog.bpRepeat['systolic'] = JSON.parse(sessionStorage.getItem("twog_bpRepeat['systolic']"));
        this.twog.bpRepeat['diastolic'] = JSON.parse(sessionStorage.getItem("twog_bpRepeat['diastolic']"));
        this.twog.pelvicExam = JSON.parse(sessionStorage.getItem("twog_pelvicExam"));
        this.twog.pulse = JSON.parse(sessionStorage.getItem("twog_pulse"));
        this.twog.resp = JSON.parse(sessionStorage.getItem("twog_resp"));
        this.twog.temp = JSON.parse(sessionStorage.getItem("twog_temp"));
        this.twog.gcs = JSON.parse(sessionStorage.getItem("twog_gcs"));
        this.twog.pain = JSON.parse(sessionStorage.getItem("twog_pain"));
        this.twog.weight = JSON.parse(sessionStorage.getItem("twog_weight"));
        this.twog.height = JSON.parse(sessionStorage.getItem("twog_height"));
        this.twog.fhr1 = JSON.parse(sessionStorage.getItem("twog_fhr1"));
        this.twog.fhr2 = JSON.parse(sessionStorage.getItem("twog_fhr2"));
        this.twog.fhr3 = JSON.parse(sessionStorage.getItem("twog_fhr3"));
        this.twog.fhr4 = JSON.parse(sessionStorage.getItem("twog_fhr4"));
        this.twog.cervicalDialation = JSON.parse(sessionStorage.getItem("twog_cervicalDialation"));
        this.twog.genotes = JSON.parse(sessionStorage.getItem("twog_genotes"));
        this.twog.abdScar = JSON.parse(sessionStorage.getItem("twog_abdScar"));
        this.twog.utrineTender = JSON.parse(sessionStorage.getItem("twog_utrineTender"));
        this.twog.palpable = JSON.parse(sessionStorage.getItem("twog_palpable"));
        this.twog.sfh = JSON.parse(sessionStorage.getItem("twog_sfh"));
        this.twog.lie = JSON.parse(sessionStorage.getItem("twog_lie"));
        this.twog.vagBleeding = JSON.parse(sessionStorage.getItem("twog_vagBleeding"));
        this.twog.liquor = JSON.parse(sessionStorage.getItem("twog_liquor"));
        this.twog.otherExamination = JSON.parse(sessionStorage.getItem("twog_otherExamination"));
        this.twog.admitted = JSON.parse(sessionStorage.getItem("twog_admitted"));
        this.twog.completedBy = JSON.parse(sessionStorage.getItem("twog_completedBy"));



        // let condition = JSON.parse(sessionStorage.getItem("twog_mapforForm"));
        // if (Object.keys(condition).length > 0) {
        //   this.twog.mapforForm(condition['answers']);
        // } else {
        //   this.twog.completedBy = this.global.currentUserName;
        // }
        let list = JSON.parse(sessionStorage.getItem("twog_list"))
        for (let i = 0; i < list.length; i++) {
          this.twog.pelvicExam.push(false);
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  clickCB(id: number) {
    console.log(id);
    this.twog.pelvicExam[id] = !this.twog.pelvicExam[id];
  }

  ngDoCheck() {
    try {
      sessionStorage.setItem("twog_bp['diastolic']", JSON.stringify(this.twog.bp['diastolic']));
      sessionStorage.setItem("twog_bp['systolic']", JSON.stringify(this.twog.bp['systolic']));
      sessionStorage.setItem("twog_bpRepeat['systolic']", JSON.stringify(this.twog.bpRepeat['systolic']));
      sessionStorage.setItem("twog_bpRepeat['diastolic']", JSON.stringify(this.twog.bpRepeat['diastolic']));
      sessionStorage.setItem("twog_pelvicExam", JSON.stringify(this.twog.pelvicExam));
      sessionStorage.setItem("twog_pulse", JSON.stringify(this.twog.pulse));
      sessionStorage.setItem("twog_resp", JSON.stringify(this.twog.resp));
      sessionStorage.setItem("twog_temp", JSON.stringify(this.twog.temp));
      sessionStorage.setItem("twog_gcs", JSON.stringify(this.twog.gcs));
      sessionStorage.setItem("twog_pain", JSON.stringify(this.twog.pain));
      sessionStorage.setItem("twog_weight", JSON.stringify(this.twog.weight));
      sessionStorage.setItem("twog_height", JSON.stringify(this.twog.height));
      sessionStorage.setItem("twog_fhr1", JSON.stringify(this.twog.fhr1));
      sessionStorage.setItem("twog_fhr2", JSON.stringify(this.twog.fhr2));
      sessionStorage.setItem("twog_fhr3", JSON.stringify(this.twog.fhr3));
      sessionStorage.setItem("twog_fhr4", JSON.stringify(this.twog.fhr4));
      sessionStorage.setItem("twog_cervicalDialation", JSON.stringify(this.twog.cervicalDialation));
      sessionStorage.setItem("twog_genotes", JSON.stringify(this.twog.genotes));
      sessionStorage.setItem("twog_abdScar", JSON.stringify(this.twog.abdScar));
      sessionStorage.setItem("twog_utrineTender", JSON.stringify(this.twog.utrineTender));
      sessionStorage.setItem("twog_palpable", JSON.stringify(this.twog.palpable));
      sessionStorage.setItem("twog_sfh", JSON.stringify(this.twog.sfh));
      sessionStorage.setItem("twog_lie", JSON.stringify(this.twog.lie));
      sessionStorage.setItem("twog_vagBleeding", JSON.stringify(this.twog.vagBleeding));
      sessionStorage.setItem("twog_liquor", JSON.stringify(this.twog.liquor));
      sessionStorage.setItem("twog_otherExamination", JSON.stringify(this.twog.otherExamination));
      sessionStorage.setItem("twog_admitted", JSON.stringify(this.twog.admitted));
      sessionStorage.setItem("twog_completedBy", JSON.stringify(this.twog.completedBy));
    } catch (ex) {
      console.log(ex);
    }
  }


  onSubmit() {
    let fd;
    let question;
    if (sessionStorage.getItem("twog_mapforDB") !== null) {
      fd = sessionStorage.getItem("twog_mapforDB")
      question = sessionStorage.getItem("twog_getClassName")
    }
    else {
      fd = this.twog.mapforDB()
      question = this.twog.getClassName()
      console.log("question-c", question, "fd-c", fd);
    }
    // let fd = this.twog.mapforDB();
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      question: question,
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    this.http.post(BASE_URL + "mother/pregnancies/visit/form", data).subscribe(response => {
      this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
      this.global.toastToSave();

      sessionStorage.removeItem("twog_bp['diastolic']");
      sessionStorage.removeItem("twog_bp['systolic']");
      sessionStorage.removeItem("twog_bpRepeat['systolic']");
      sessionStorage.removeItem("twog_bpRepeat['diastolic']");
      sessionStorage.removeItem("twog_pelvicExam");
      sessionStorage.removeItem("twog_pulse");
      sessionStorage.removeItem("twog_resp");
      sessionStorage.removeItem("twog_temp");
      sessionStorage.removeItem("twog_gcs");
      sessionStorage.removeItem("twog_pain");
      sessionStorage.removeItem("twog_weight");
      sessionStorage.removeItem("twog_height");
      sessionStorage.removeItem("twog_fhr1");
      sessionStorage.removeItem("twog_fhr2");
      sessionStorage.removeItem("twog_fhr3");
      sessionStorage.removeItem("twog_fhr4");
      sessionStorage.removeItem("twog_cervicalDialation");
      sessionStorage.removeItem("twog_genotes",);
      sessionStorage.removeItem("twog_abdScar",);
      sessionStorage.removeItem("twog_utrineTender",);
      sessionStorage.removeItem("twog_palpable",);
      sessionStorage.removeItem("twog_sfh");
      sessionStorage.removeItem("twog_lie");
      sessionStorage.removeItem("twog_vagBleeding");
      sessionStorage.removeItem("twog_liquor");
      sessionStorage.removeItem("twog_otherExamination");
      sessionStorage.removeItem("twog_admitted");
      sessionStorage.removeItem("twog_completedBy");
    });
  }

  iterator(end: number, start: number = 0, increment = 1): number[] {
    return this.global.iterateNumbers(end, start, increment);
  }

  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation === true){
  //     options.form.controls[options.name].setErrors(null);
  //   }else if(validation === false){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //   }
  // }
}
