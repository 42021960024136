import { Component, OnInit } from '@angular/core';
import { HttpClient as Http } from '@angular/common/http';
import { Router } from '@angular/router';
import { Globals, BASE_URL } from 'app/globals';

@Component({
  selector: 'app-report-from',
  templateUrl: './report-from.component.html',
  styleUrls: ['./report-from.component.css']
})
export class ReportFromComponent implements OnInit {

  public from = { m: null, y: null};
  public to = { m: null, y: null};
  public type = null;
  constructor(
    private http : Http,
    public global : Globals,
  ) { }

  ngOnInit() {
  }

  iterator(end : number, start : number = 0, increment = 1 ) : number[]{
    return this.global.iterateNumbers(end, start, increment);
  }

}
