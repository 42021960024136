import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpHeadered as Http } from '../services/httpHeadered';
import { Globals, BASE_URL } from 'app/globals';
import { Router, ActivatedRoute } from '@angular/router';
import { SixB } from '../models/questions/6b';
import { Validation } from '../validation';
import * as moment from 'moment';

@Component({
  selector: 'app-visit-forms-questions-6b',
  templateUrl: './visit-forms-questions-6b.component.html',
  styleUrls: ['./visit-forms-questions-6b.component.css']
})
export class VisitFormsQuestions6bComponent extends Validation implements OnInit {
  @ViewChild('formSixB', { read: ElementRef, static: false }) Form;
  sixb: SixB;
  uids: any;
  bid: number;
  update: boolean = false;
  year = new Date().getFullYear();
  maxDate = moment().local();

  constructor (
    private http: Http,
    public global: Globals,
    private router: Router,
    private aRoute: ActivatedRoute,
  ) {
    super();
    this.sixb = new SixB();
    this.aRoute.params.subscribe(params => {
      this.sixb.babyNumber = params.id;
      this.bid = params.id;
    })
    this.uids = global.uidsfq
  }

  ngOnInit() {
    try {
      if (!this.global.isLoggedIn()) {
        this.router.navigate(['/login']);
      }
      if (null) {
        this.http
          .get(BASE_URL + "baby/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.bid + "/Section4a")
          .subscribe(response => {
            this.sixb.babyId = response['answers']['answer4a4'];
          });
        this.http
          .get(BASE_URL + "baby/form/" + this.uids.mid + "/" + this.uids.pid + "/" + this.uids.vid + "/" + this.bid + "/" + this.sixb.getClassName())
          .subscribe(response => {
            if (Object.keys(response).length > 0) {
              this.update = true;
              this.sixb.mapforForm(response['answers']);
            } else {
              this.sixb.completedby = this.global.currentUserName;
            }
          });
        this.global.visitConcluded(this.uids.mid, this.uids.pid, this.uids.vid)
          .subscribe(response => {
            if (response['concluded']) this.Form.form.disable();
          });
        sessionStorage.setItem("sixb_mapforDB", JSON.stringify(this.sixb.mapforDB));
        sessionStorage.setItem("sixb_getClassName", JSON.stringify(this.sixb.getClassName));
        sessionStorage.setItem("sixb_uids_mid", JSON.stringify(this.uids.mid));
        sessionStorage.setItem("sixb_uids_pid", JSON.stringify(this.uids.pid));
        sessionStorage.setItem("sixb_uids_vid", JSON.stringify(this.uids.vid));
      } else {
        this.sixb.mapforDB = JSON.parse(sessionStorage.getItem("sixb_mapforDB"))
        this.sixb.getClassName = JSON.parse(sessionStorage.getItem("sixb_getClassName"))
        this.uids.mid = JSON.parse(sessionStorage.getItem("sixb_uids_mid"))
        this.uids.pid = JSON.parse(sessionStorage.getItem("sixb_uids_pid"))
        this.uids.vid = JSON.parse(sessionStorage.getItem("sixb_uids_vid"))

        this.sixb.babyNumber = JSON.parse(sessionStorage.getItem("sixb_babyNumber"))
        this.sixb.name = JSON.parse(sessionStorage.getItem("sixb_name"))
        this.sixb.dateOfDischarge = JSON.parse(sessionStorage.getItem("sixb_dateOfDischarge"))
        this.sixb.babyId = JSON.parse(sessionStorage.getItem("sixb_babyId"))
        this.sixb.babyDiagnosis = JSON.parse(sessionStorage.getItem("sixb_babyDiagnosis"))
        this.sixb.finalOutcome = JSON.parse(sessionStorage.getItem("sixb_finalOutcome"))
        this.sixb.facility = JSON.parse(sessionStorage.getItem("sixb_facility"))
        this.sixb.reason = JSON.parse(sessionStorage.getItem("sixb_reason"))
        this.sixb.completedby = JSON.parse(sessionStorage.getItem("sixb_completedby"))
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  ngDoCheck() {
    try {
      sessionStorage.setItem("sixb_babyNumber", JSON.stringify(this.sixb.babyNumber))
      sessionStorage.setItem("sixb_name", JSON.stringify(this.sixb.name))
      sessionStorage.setItem("sixb_dateOfDischarge", JSON.stringify(this.sixb.dateOfDischarge))
      sessionStorage.setItem("sixb_babyId", JSON.stringify(this.sixb.babyId))
      sessionStorage.setItem("sixb_babyDiagnosis", JSON.stringify(this.sixb.babyDiagnosis))
      sessionStorage.setItem("sixb_finalOutcome", JSON.stringify(this.sixb.finalOutcome))
      sessionStorage.setItem("sixb_facility", JSON.stringify(this.sixb.facility))
      sessionStorage.setItem("sixb_reason", JSON.stringify(this.sixb.reason))
      sessionStorage.setItem("sixb_completedby", JSON.stringify(this.sixb.completedby))
    } catch (ex) {
      console.log(ex);
    }
  }

  onSubmit() {
    let fd;
    let question;
    if (sessionStorage.getItem("sixb_mapforDB") !== null) {
      fd = sessionStorage.getItem("sixb_mapforDB")
      question = sessionStorage.getItem("sixb_getClassName")
    }
    else {
      fd = this.sixb.mapforDB()
      question = this.sixb.getClassName()
      console.log("question-c", question, "fd-c", fd);
    }
    // let fd = this.sixb.mapforDB();
    let data = {
      mid: this.uids.mid,
      pid: this.uids.pid,
      vid: this.uids.vid,
      bid: this.bid,
      question: question,
      enteredby: this.global.getCurrentUser(),
      answers: fd,
    };
    let id = this.uids.mid + ":" + this.uids.pid + ":" + this.uids.vid;
    if (this.update) {
      this.http.post(BASE_URL + "baby/update", data).subscribe(response => {
        this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
        this.global.toastToSave();
      });
    } else {
      this.http.post(BASE_URL + "baby/form/add", data).subscribe(response => {
        this.router.navigate(['/mother/pregnancy/visit/' + id + "/" + this.global.getNextForm(this.router)]);
        this.global.toastToSave();
      });
    }
  }

  iterator(end: number, start: number = 0, increment = 1): number[] {
    return this.global.iterateNumbers(end, start, increment);
  }

  // validate(model : any, options : any = {}, type : string, event : any, allowDecimal = false){
  //   if(model === null || model.length < 1){
  //     options.form.controls[options.name].setErrors(null);
  //     return;
  //   }
  //   let validation = this.global.validationRule(model, options, type, event, allowDecimal);
  //   if(validation === true){
  //     options.form.controls[options.name].setErrors(null);
  //   }else if(validation === false){
  //     options.form.controls[options.name].setErrors({'incorrect' : true});
  //   }
  // }
}
